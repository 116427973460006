const LogoIcon = ({ height, width }: { height: number; width: number }) => {
  return (
    <div
      style={{ height, width }}
      className="bg-primary flex items-center content-center rounded-full border border-white shadow-lg"
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon" filter="url(#filter0_d_5689_55494)">
          <path
            id="icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.5538 28.3634C42.1809 28.3634 37.0147 33.5729 37.0147 39.9992C37.0147 46.4254 42.1809 51.6349 48.5538 51.6349C54.9267 51.6349 60.093 46.4254 60.093 39.9992C60.093 33.5729 54.9267 28.3634 48.5538 28.3634ZM32.6875 39.9992C32.6875 31.1631 39.7911 24 48.5538 24C57.3166 24 64.4202 31.1631 64.4202 39.9992C64.4202 48.8353 57.3166 55.9983 48.5538 55.9983C39.7911 55.9983 32.6875 48.8353 32.6875 39.9992Z"
            fill="white"
          />
          <path
            id="Dash"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.4199 51.6368C67.6707 48.3257 68.9875 44.3184 68.9875 40.001C68.9875 35.6836 67.6707 31.6763 65.4199 28.3652H70.4672C72.2854 31.8398 73.3147 35.7992 73.3147 40.001C73.3147 44.2028 72.2854 48.1622 70.4672 51.6368H65.4199Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_5689_55494"
            x="0.6875"
            y="0"
            width="104.627"
            height="95.998"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="8" />
            <feGaussianBlur stdDeviation="16" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0352941 0 0 0 0 0.12549 0 0 0 0 0.384314 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5689_55494"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5689_55494"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export { LogoIcon };
