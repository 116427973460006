import { createColumnHelper } from "@tanstack/react-table";
import { surface_core__ask__entitites__askentities__MoveOut as MoveOut } from "api-client";
import {
  TableDef,
  optionalCell,
  optionalDateCell,
  personCell,
  unitCell,
} from "./utils";

const moveOutColumnHelps = createColumnHelper<MoveOut>();

const moveOutTableDefs: TableDef = {
  columns: [
    moveOutColumnHelps.accessor("unit_number", {
      id: "unit_number",
      header: "Unit",
      cell: unitCell,
    }),
    moveOutColumnHelps.accessor("move_out_date", {
      id: "move_out_date",
      header: "Move-Out Date",
      cell: optionalDateCell,
    }),
    moveOutColumnHelps.accessor("move_out_reason", {
      id: "move_out_reason",
      header: "Move-Out Reason",
      cell: optionalCell,
    }),
    moveOutColumnHelps.accessor("lease_start_date", {
      id: "lease_start_date",
      header: "Lease Start Date",
      cell: optionalDateCell,
    }),
    moveOutColumnHelps.accessor("lease_end_date", {
      id: "lease_end_date",
      header: "Lease End Date",
      cell: optionalDateCell,
    }),
    moveOutColumnHelps.accessor("resident_full_names", {
      id: "resident_full_names",
      header: "Residents",
      cell: personCell,
    }),
    moveOutColumnHelps.accessor("property_name", {
      id: "property_name",
      header: "Property",
      cell: optionalCell,
    }),
  ],
  staticColumns: ["unit_number", "move_out_date", "resident_full_names"],
  rowId: "unit_number",
};

export { moveOutTableDefs };
