import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { Pet, Resident360, ResidentVehicle } from "api-client";
import { TableDef, addressCell, optionalCell, optionalDateCell, unitCell } from "./utils";
const residentColumnHelps = createColumnHelper<Resident360>();

const residentTableDefs: TableDef = {
  columns: [
    residentColumnHelps.accessor("full_name", {
      id: "full_name",
      header: "Name",
      cell: optionalCell,
    }),
    residentColumnHelps.accessor("email_address", {
      id: "email_address",
      header: "Email",
      cell: optionalCell,
    }),
    residentColumnHelps.accessor("phone_number", {
      id: "phone_number",
      header: "Phone",
      cell: optionalCell,
    }),
    residentColumnHelps.accessor("birth_month_day", {
      id: "birth_month_day",
      header: "Birthday",
      cell: optionalDateCell,
    }),
    residentColumnHelps.accessor("address", {
      id: "address",
      header: "Address",
      cell: addressCell,
    }),
    residentColumnHelps.accessor("forwarding_address", {
      id: "forwarding_address",
      header: "Forwarding Address",
      cell: addressCell,
    }),
    residentColumnHelps.accessor("pets", {
      id: "pets",
      header: "Pets",
      cell: (cell: CellContext<Resident360, Pet[] | null>) => {
        const pets = cell.getValue();
        if (!pets || !pets.length) {
          return "-";
        }
        return pets.map((pet) => pet.name).join(", ");
      },
    }),
    residentColumnHelps.accessor("vehicles", {
      id: "vehicles",
      header: "Vehicles",
      cell: (cell: CellContext<Resident360, ResidentVehicle[] | null>) => {
        const vehicles = cell.getValue();
        if (!vehicles || !vehicles.length) {
          return "-";
        }
        return vehicles
          .map(
            (vehicle) =>
              `${vehicle.make} ${vehicle.model} ( ${vehicle.license_plate_state} ${vehicle.license_plate_number})`
          )
          .join(", ");
      },
    }),
    residentColumnHelps.accessor("co_occupant_full_names", {
      id: "co_occupant_full_names",
      header: "Co-Occupants",
      cell: (cell) => {
        const coOccupants = cell.getValue();
        if (!coOccupants || !coOccupants.length) {
          return "-";
        }
        return coOccupants.join(", ");
      },
    }),
    residentColumnHelps.accessor("unit_number", {
      id: "unit_number",
      header: "Unit",
      cell: unitCell,
    }),
    residentColumnHelps.accessor("building_name", {
      id: "building_name",
      header: "Building",
      cell: optionalCell,
    }),
    residentColumnHelps.accessor("property_name", {
      id: "property_name",
      header: "Property",
      cell: optionalCell,
    }),
  ],
  staticColumns: ["full_name", "unit_number"],
  rowId: "id",
};

export { residentTableDefs };
