/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ScreeningDecision;
(function (ScreeningDecision) {
    ScreeningDecision["APPROVED"] = "Approved";
    ScreeningDecision["APPROVE_WITH_CONDITIONS"] = "Approve With Conditions";
    ScreeningDecision["DENIED"] = "Denied";
    ScreeningDecision["PENDING"] = "Pending";
})(ScreeningDecision || (ScreeningDecision = {}));
