/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ApplicationStatus;
(function (ApplicationStatus) {
    ApplicationStatus["STARTED"] = "Started";
    ApplicationStatus["PARTIALLY_COMPLETED"] = "Partially Completed";
    ApplicationStatus["COMPLETED"] = "Completed";
    ApplicationStatus["CANCELLED"] = "Cancelled";
    ApplicationStatus["REOPENED"] = "Reopened";
    ApplicationStatus["APPROVED"] = "Approved";
    ApplicationStatus["DECLINED"] = "Declined";
    ApplicationStatus["ARCHIVED"] = "Archived";
})(ApplicationStatus || (ApplicationStatus = {}));
