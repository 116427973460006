export const LeaseAuditsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill}
      d="M2 4C2 3.86739 2.05268 3.74021 2.14645 3.64645C2.24021 3.55268 2.36739 3.5 2.5 3.5H13.5C13.6326 3.5 13.7598 3.55268 13.8536 3.64645C13.9473 3.74021 14 3.86739 14 4C14 4.13261 13.9473 4.25979 13.8536 4.35355C13.7598 4.44732 13.6326 4.5 13.5 4.5H2.5C2.36739 4.5 2.24021 4.44732 2.14645 4.35355C2.05268 4.25979 2 4.13261 2 4ZM2.5 8.5H13.5C13.6326 8.5 13.7598 8.44732 13.8536 8.35355C13.9473 8.25979 14 8.13261 14 8C14 7.86739 13.9473 7.74021 13.8536 7.64645C13.7598 7.55268 13.6326 7.5 13.5 7.5H2.5C2.36739 7.5 2.24021 7.55268 2.14645 7.64645C2.05268 7.74021 2 7.86739 2 8C2 8.13261 2.05268 8.25979 2.14645 8.35355C2.24021 8.44732 2.36739 8.5 2.5 8.5ZM9 11.5H2.5C2.36739 11.5 2.24021 11.5527 2.14645 11.6464C2.05268 11.7402 2 11.8674 2 12C2 12.1326 2.05268 12.2598 2.14645 12.3536C2.24021 12.4473 2.36739 12.5 2.5 12.5H9C9.13261 12.5 9.25979 12.4473 9.35355 12.3536C9.44732 12.2598 9.5 12.1326 9.5 12C9.5 11.8674 9.44732 11.7402 9.35355 11.6464C9.25979 11.5527 9.13261 11.5 9 11.5ZM14.5 11.5H13.5V10.5C13.5 10.3674 13.4473 10.2402 13.3536 10.1464C13.2598 10.0527 13.1326 10 13 10C12.8674 10 12.7402 10.0527 12.6464 10.1464C12.5527 10.2402 12.5 10.3674 12.5 10.5V11.5H11.5C11.3674 11.5 11.2402 11.5527 11.1464 11.6464C11.0527 11.7402 11 11.8674 11 12C11 12.1326 11.0527 12.2598 11.1464 12.3536C11.2402 12.4473 11.3674 12.5 11.5 12.5H12.5V13.5C12.5 13.6326 12.5527 13.7598 12.6464 13.8536C12.7402 13.9473 12.8674 14 13 14C13.1326 14 13.2598 13.9473 13.3536 13.8536C13.4473 13.7598 13.5 13.6326 13.5 13.5V12.5H14.5C14.6326 12.5 14.7598 12.4473 14.8536 12.3536C14.9473 12.2598 15 12.1326 15 12C15 11.8674 14.9473 11.7402 14.8536 11.6464C14.7598 11.5527 14.6326 11.5 14.5 11.5Z"
    />
  </svg>
);
