import { useCallback, useState } from "react";

// Custom hook to get a ref and a callback to set it. Used for scroll action.
export const useRefCallback = (): [
  HTMLDivElement | null,
  (node: HTMLDivElement | null) => void,
] => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const callback = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setRef(node);
    }
  }, []);

  return [ref, callback];
};
