import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { Lease, ResidentVehicle } from "api-client";
import {
  TableDef,
  optionalCell,
  optionalDateCell,
  personCell,
  unitCell,
} from "./utils";

const leaseColumnHelps = createColumnHelper<Lease>();

const leaseTableDefs: TableDef = {
  columns: [
    leaseColumnHelps.accessor("unit_number", {
      id: "unit_number",
      header: "Unit",
      cell: unitCell,
    }),
    leaseColumnHelps.accessor("lease_start_date", {
      id: "lease_start_date",
      header: "Start Date",
      cell: optionalDateCell,
    }),
    leaseColumnHelps.accessor("lease_end_date", {
      id: "lease_end_date",
      header: "End Date",
      cell: optionalDateCell,
    }),
    leaseColumnHelps.accessor("departure_intent", {
      id: "departure_intent",
      header: "Departure Intent",
      cell: optionalCell,
    }),
    leaseColumnHelps.accessor("commitment_type", {
      id: "commitment_type",
      header: "Commitment Type",
      cell: optionalCell,
    }),
    leaseColumnHelps.accessor("approved_date", {
      id: "approved_date",
      header: "Approved Date",
      cell: optionalDateCell,
    }),
    leaseColumnHelps.accessor("signed_date", {
      id: "signed_date",
      header: "Signed Date",
      cell: optionalDateCell,
    }),
    leaseColumnHelps.accessor("counter_signed_date", {
      id: "counter_signed_date",
      header: "Counter-signed Date",
      cell: optionalDateCell,
    }),
    leaseColumnHelps.accessor("move_in_date", {
      id: "move_in_date",
      header: "Move In Date",
      cell: optionalDateCell,
    }),
    leaseColumnHelps.accessor("move_out_date", {
      id: "move_out_date",
      header: "Move Out Date",
      cell: optionalDateCell,
    }),
    {
      id: "residents",
      header: "Residents",
      accessorFn: (row: Lease) => {
        const primaryApplicantName = row.primary_resident?.full_name;
        if (!primaryApplicantName) {
          return "-";
        }
        const additionalApplicants =
          (row.co_residents?.length || 0) + (row.other_residents?.length || 0);
        return (
          primaryApplicantName +
          (additionalApplicants ? ` + ${additionalApplicants}` : "")
        );
      },
      cell: personCell,
    },
    leaseColumnHelps.accessor("vehicles", {
      id: "vehicles",
      header: "Vehicles",
      cell: (cell: CellContext<Lease, ResidentVehicle[] | null>) => {
        const vehicles = cell.getValue();
        if (!vehicles || !vehicles.length) {
          return "-";
        }
        return vehicles
          .map(
            (vehicle) =>
              `${vehicle.make} ${vehicle.model} ( ${vehicle.license_plate_state} ${vehicle.license_plate_number})`,
          )
          .join(", ");
      },
    }),
  ],
  staticColumns: [
    "unit_number",
    "lease_start_date",
    "lease_end_date",
    "commitment_type",
    "approved_date",
    "move_in_date",
    "residents",
  ],
  rowId: "id",
};

export { leaseTableDefs };
