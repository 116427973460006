import { createColumnHelper } from "@tanstack/react-table";
import { surface_core__ask__entitites__askentities__Application as Application } from "api-client";
import {
  TableDef,
  applicationStatusCell,
  optionalCell,
  optionalDateCell,
  personCell,
  unitCell,
} from "./utils";

const appColumnHelps = createColumnHelper<Application>();

const applicationTableDefs: TableDef = {
  columns: [
    appColumnHelps.accessor("application_status", {
      id: "application_status",
      header: "Status",
      cell: applicationStatusCell,
    }),
    appColumnHelps.accessor("unit_number", {
      id: "unit_number",
      header: "Unit",
      cell: unitCell,
    }),
    {
      id: "residents",
      header: "Residents",
      accessorFn: (row: Application) => {
        const primaryApplicantName = row.applicant_full_name;
        if (!primaryApplicantName) {
          return "-";
        }
        const additionalApplicants =
          (row.co_applicants?.length || 0) + (row.other_occupants?.length || 0);
        return (
          primaryApplicantName +
          (additionalApplicants ? ` + ${additionalApplicants}` : "")
        );
      },
      cell: personCell,
    },
    appColumnHelps.accessor("property_name", {
      id: "property_name",
      header: "Property Name",
    }),
    appColumnHelps.accessor("floor_plan_name", {
      id: "floor_plan_name",
      header: "Floor Plan",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("number_of_bedrooms", {
      id: "number_of_bedrooms",
      header: "Bedrooms",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("number_of_bathrooms", {
      id: "number_of_bathrooms",
      header: "Bathrooms",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("square_footage", {
      id: "square_footage",
      header: "Square Footage",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("guest_card_created_date", {
      id: "guest_card_created_date",
      header: "Guest Card Created Date",
      cell: optionalDateCell,
    }),
    appColumnHelps.accessor("application_started_date", {
      id: "application_started_date",
      header: "Application Started Date",
      cell: optionalDateCell,
    }),
    appColumnHelps.accessor("application_completed_date", {
      id: "application_completed_date",
      header: "Application Completed Date",
      cell: optionalDateCell,
    }),
    appColumnHelps.accessor("lease_start_date", {
      id: "lease_start_date",
      header: "Lease Start Date",
      cell: optionalDateCell,
    }),
    appColumnHelps.accessor("target_move_in_date", {
      id: "target_move_in_date",
      header: "Move In Date",
      cell: optionalDateCell,
    }),
    appColumnHelps.accessor("offered_rent", {
      id: "offered_rent",
      header: "Offered Rent",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("leasing_agent_name", {
      id: "leasing_agent_name",
      header: "Leasing Agent",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("lease_commitment_type", {
      id: "lease_commitment_type",
      header: "Lease Commitment Type",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("lead_source_name", {
      id: "lead_source_name",
      header: "Lead Source",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("unit_make_ready_status", {
      id: "unit_make_ready_status",
      header: "Make Ready Status",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("unit_make_ready_date", {
      id: "unit_make_ready_date",
      header: "Make Ready Date",
      cell: optionalCell,
    }),
    appColumnHelps.accessor("application_id", {
      id: "application_id",
      header: "Application ID",
    }),
    appColumnHelps.accessor("email_address", {
      id: "email_address",
      header: "Email",
    }),
    appColumnHelps.accessor("phone_number", {
      id: "phone_number",
      header: "Phone",
    }),
    appColumnHelps.accessor("application_type", {
      id: "application_type",
      header: "Application Type",
    }),
  ],
  staticColumns: ["application_status", "unit_number", "residents"],
  rowId: "application_id",
};

export { applicationTableDefs };
