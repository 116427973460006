import React from "react";
import { Icon, Card, Skeleton } from "surface-components";
import { Task } from "api-client";
import { formatDateMonthDay } from "@/lib/utils";

interface DrawerWorkItemsProps {
  task?: Partial<Task>;
  isLoading?: boolean;
}

export const DrawerWorkItems: React.FC<DrawerWorkItemsProps> = ({
  task,
  isLoading,
}) => {
  const workItems = task?.work_items || [];
  const showPrioritySummary =
    task?.work_item_progress_summary && task?.completion_gap_summary;

  if (isLoading) {
    return (
      <div>
        <div className="flex items-center mb-6 space-x-2">
          <Icon icon="priority-high-skeleton" />
          <Skeleton className="h-1 w-12 bg-gray-100" />
          <Skeleton className="h-1 w-8 bg-gray-100" />
          <Skeleton className="h-1 w-8 bg-gray-100" />
          <Skeleton className="h-1 w-8 bg-gray-100" />
          <Skeleton className="h-1 w-8 bg-gray-100" />
        </div>
        <Card className="bg-white shadow-md p-2">
          <ul className="flex flex-col gap-2">
            {Array(6)
              .fill(null)
              .map((_, i) => (
                <li key={i} className="flex items-center gap-2 h-6">
                  <Icon icon="subtask-incomplete" title="incomplete" />
                  <Skeleton className={`h-1 w-${((i % 3) + 4) * 2}`} />
                  <Skeleton className={`h-1 w-${(((i + 1) % 3) + 4) * 2}`} />
                  {i % 2 === 0 && <Skeleton className="h-1 w-8" />}
                  <Skeleton className="h-1 w-8 ml-auto" />
                </li>
              ))}
          </ul>
        </Card>
      </div>
    );
  }

  return (
    <div>
      {showPrioritySummary && (
        <div className="flex items-center mb-6">
          <Icon icon="priority-high" className="mr-2" />
          <p className="">
            {task?.completion_gap_summary},{" "}
            {task?.work_item_progress_summary?.toLowerCase() ?? ""}
          </p>
        </div>
      )}
      <Card className="bg-white shadow-md p-2">
        <ul className="flex flex-col gap-2">
          {workItems.map((workItem, index) => (
            <li key={index} className="flex items-center gap-2">
              {workItem.is_completed ? (
                <>
                  <Icon icon="subtask-complete" title="complete" />
                  <p>{workItem.action}</p>
                  {workItem.completedDate && (
                    <p className="text-muted-foreground ml-auto">
                      {formatDateMonthDay(workItem.completedDate)}
                    </p>
                  )}
                </>
              ) : (
                <>
                  <Icon icon="subtask-incomplete" title="incomplete" />
                  <p>{workItem.action}</p>
                  <p className="text-muted-foreground ml-auto">Pending</p>
                </>
              )}
            </li>
          ))}
        </ul>
      </Card>
    </div>
  );
};
