export const MoveInsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    {...props}
  >
    <g fill={props.fill} fillRule="evenodd" clipRule="evenodd">
      <path d="M11.256 2.744a.83.83 0 0 0-.59-.244H5.334a.833.833 0 0 0-.833.833V6h-1V3.333A1.833 1.833 0 0 1 5.333 1.5h5.334A1.833 1.833 0 0 1 12.5 3.333V13.5H14a.5.5 0 0 1 0 1H2a.5.5 0 0 1 0-1h1.5V11h1v2.5h7V3.333a.83.83 0 0 0-.244-.589M6.166 8.5a.5.5 0 1 1 1 0v.007a.5.5 0 0 1-1 0z" />
      <path d="M2.646 6.646a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708-.708L3.293 9H1a.5.5 0 0 1 0-1h2.293l-.647-.646a.5.5 0 0 1 0-.708" />
    </g>
  </svg>
);
