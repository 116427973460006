import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  LeaseAuditSummary,
  TaskSortParam,
  TaskSummary,
  WorkOrderSummary,
} from "api-client";
import ChevronRight from "@/assets/img/chevron-right.svg";
import clsx from "clsx";
import {
  PriorityIndicator,
  Progress,
  Assignee,
  Icon,
  Button,
  Skeleton,
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "surface-components";
import ReactGA from "react-ga4";
import { getEntityName, TaskFacet, TaskType } from "@/lib/hooks/use-tasks";
import { personCell } from "../source-table/table-defintions/utils";
import { useEffect, useMemo, useState } from "react";
import { formatDate, formatDateMonthDayYear } from "@/lib/utils";

interface TaskTableProps {
  groupKey: string;
  title: string;
  selectedTaskId?: string;
  selectedTaskPropertyId?: string;
  isLoading: boolean;
  facet?: TaskFacet;
  type: TaskType;
  tasks: TaskSummary[];
  page?: number;
  pageSize?: number;
  total?: number;
  fetchPage: (key: string, page: number) => void;
  updateSort: (key: string, sort?: TaskSortParam) => void;
  onClickRow: (
    taskId: string,
    propertyId: string,
    type?: string,
    unit_number?: string
  ) => void;
  properties?: { id: string; name: string }[];
}

const emptyTasks = Array(5).fill({});

const getCompletionGapColumnName = (type: TaskType) => {
  switch (type) {
    case "ApplicationTask":
      return "Move in";
    case "MoveInTask":
      return "Move in";
    case "MoveOutTask":
      return "Move out";
    case "RenewalTask":
      return "Lease ends";
    default:
      return "Remaining";
  }
};

const sortFieldByColumnId: { [key: string]: string } = {
  property_name: "property_name",
  priority_group: "priority",
};

const getColumnsForType = (type: TaskType) => {
  switch (type) {
    case "ServiceRequestTask":
      return [
        "priority_group",
        "property_name",
        "work_order_priority",
        "work_order_description",
        "work_order_date_opened",
        "unit_number",
        "residents",
        "assigned_to",
      ];
    case "LeaseAuditTask":
      return [
        "priority_group",
        "property_name",
        "unit_number",
        "lease_audit_date",
        "lease_audit_period",
        "lease_audit_results",
        "lease_audit_failure_reason",
        "residents",
        "assigned_to",
      ];
    default:
      return [
        "priority_group",
        "property_name",
        "unit_number",
        "residents",
        "progress",
        "work_item_progress_summary",
        "completion_gap_summary",
        "assigned_to",
      ];
  }
};

const TRUNCATE_LENGTH = 30;

const useTableDef = (
  key: string,
  type: TaskTableProps["type"],
  tasks: TaskSummary[],
  onClickRow: (
    taskId: string,
    propertyId: string,
    type?: string,
    unit_number?: string
  ) => void,
  onChangeSort: (key: string, sort?: TaskSortParam) => void,
  isLoading: boolean = false,
  properties: { id: string; name: string }[] = []
) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const propertyNamesById = properties.reduce(
    (acc, property) => {
      acc[property.id] = property.name;
      return acc;
    },
    {} as Record<string, string>
  );
  const columnHelper = createColumnHelper<TaskSummary>();

  const allColumns: ColumnDef<TaskSummary, any>[] = [
    columnHelper.accessor("priority_group", {
      id: "priority_group",
      enableSorting: true,
      header: "",
      cell: (cell) => {
        const priorityGroup = cell.getValue();
        return (
          <PriorityIndicator priority={isLoading ? "archive" : priorityGroup} />
        );
      },
      size: 25,
    }),
    columnHelper.accessor("property_id", {
      id: "property_name",
      enableSorting: true,
      header: "Property",
      cell: (cell) => {
        if (isLoading) {
          return <Skeleton className="w-20 h-1" />;
        }
        return (
          <div>
            {propertyNamesById[cell.row.original.property_id] ||
              cell.row.original.property_id}
          </div>
        );
      },
      size: 80,
    }),
    columnHelper.accessor("unit_number", {
      id: "unit_number",
      header: "Unit",
      size: 40,
      enableSorting: false,
      cell: (cell) => {
        if (isLoading) {
          return <Skeleton className="h-1 w-full" />;
        }
        return <div>{cell.getValue()}</div>;
      },
    }),
    {
      id: "residents",
      size: 100,
      header: type === "ApplicationTask" ? "Applicant" : "Resident",
      enableSorting: false,
      accessorFn: (row: TaskSummary) => {
        return row.residents?.[0]?.full_name;
      },
      cell: (cell) => {
        if (isLoading) {
          return (
            <div className="text-muted-foreground flex items-center space-x-2">
              <Icon icon="person" />
              <Skeleton className="mt-[3px] h-1 w-6" />
              <Skeleton className="mt-[3px] h-1 w-8" />
            </div>
          );
        }
        return personCell(cell);
      },
    },
    {
      id: "progress",
      header: "Progress",
      enableSorting: false,
      cell: (cell) => {
        if (isLoading) {
          return (
            <span className="inline-flex items-center border border-solid border-border rounded-xl px-2 py-1">
              <Icon className="pr-2" icon="progress-zero" />
              <Skeleton className="w-5 h-1" />
            </span>
          );
        }
        return <Progress workItems={cell?.row?.original?.work_items || []} />;
      },
      size: 60,
    },
    columnHelper.accessor("work_item_progress_summary", {
      id: "work_item_progress_summary",
      header: "Status",
      enableSorting: false,
      cell: (cell) => {
        if (isLoading) {
          return (
            <div className="flex space-x-2">
              <Skeleton className="w-20 h-1" />
              <Skeleton className="w-12 h-1" />
            </div>
          );
        }
        const workItemProgressSummary = cell.getValue();
        return <div>{workItemProgressSummary}</div>;
      },
      minSize: 100,
      maxSize: 300,
    }),
    columnHelper.accessor("completion_gap_summary", {
      id: "completion_gap_summary",
      header: getCompletionGapColumnName(type),
      enableSorting: false,
      cell: (cell) => {
        if (isLoading) {
          return (
            <div className="flex space-x-2">
              <Skeleton className="w-20 h-1" />
              <Skeleton className="w-12 h-1" />
            </div>
          );
        }
        const completionGapSummary = cell.getValue();
        {
          /* TODO: icon */
        }
        return <div>{completionGapSummary}</div>;
      },
      size: 100,
    }),
    {
      id: "assigned_to",
      header: "Assigned",
      enableSorting: false,
      cell: (cell) => {
        if (isLoading) {
          return (
            <div className="flex items-center space-x-2">
              <Skeleton className="w-4 h-4 rouded-full" />
              <Skeleton className="w-6 h-1" />
              <Skeleton className="w-3 h-1" />
            </div>
          );
        }
        const assignedTo = cell.row.original.assigned_to;
        return <Assignee assignee={assignedTo} />;
      },
      maxSize: 75,
    },
    // Work order task specific columns
    {
      id: "work_order_priority",
      header: "Priority",
      enableSorting: false,
      size: 80,
      cell: (cell) => {
        if (isLoading) {
          return <Skeleton className="w-20 h-1" />;
        }
        const summaryEntity = cell.row.original
          .summary_entity as null | WorkOrderSummary;
        return <span>{summaryEntity?.priority}</span>;
      },
    },
    {
      id: "work_order_date_opened",
      header: "Date Opened",
      enableSorting: true,
      size: 80,
      cell: (cell) => {
        if (isLoading) {
          return <Skeleton className="w-20 h-1" />;
        }
        const summaryEntity = cell.row.original
          .summary_entity as null | WorkOrderSummary;
        return (
          <span>
            {summaryEntity?.date_opened &&
              formatDate(summaryEntity?.date_opened)}
          </span>
        );
      },
    },
    {
      id: "work_order_description",
      header: "Description",
      enableSorting: false,
      cell: (cell) => {
        if (isLoading) {
          return <Skeleton className="w-20 h-1" />;
        }
        const summaryEntity = cell.row.original
          .summary_entity as null | WorkOrderSummary;
        const text = summaryEntity?.description || "";
        if (text.length < TRUNCATE_LENGTH) {
          return <span>{text}</span>;
        }
        return (
          <HoverCard>
            <HoverCardTrigger>
              <div className="truncate">{text}</div>
            </HoverCardTrigger>
            <HoverCardContent className="bg-white rounded-2xl p-2 border-solid border border-border shadow-md w-64 mx-2">
              {text}
            </HoverCardContent>
          </HoverCard>
        );
      },
    },
    // Lease audit task specific columns
    {
      id: "lease_audit_date",
      header: "Audit Date",
      enableSorting: true,
      size: 80,
      cell: (cell) => {
        if (isLoading) {
          return <Skeleton className="w-20 h-1" />;
        }
        const summaryEntity = cell.row.original
          .summary_entity as null | LeaseAuditSummary;
        return (
          <span>
            {summaryEntity?.audit_date_time &&
              formatDate(summaryEntity?.audit_date_time)}
          </span>
        );
      },
    },
    {
      id: "lease_period",
      header: "Lease Period",
      enableSorting: false,
      size: 80,
      cell: (cell) => {
        if (isLoading) {
          return <Skeleton className="w-20 h-1" />;
        }
        const summaryEntity = cell.row.original
          .summary_entity as null | LeaseAuditSummary;
        if (
          summaryEntity?.lease_summary.start_date &&
          summaryEntity?.lease_summary.end_date
        ) {
          return (
            <span>
              {formatDateMonthDayYear(summaryEntity?.lease_summary.start_date)}{" "}
              - {formatDateMonthDayYear(summaryEntity?.lease_summary.end_date)}
            </span>
          );
        }
        return null;
      },
    },
    {
      id: "lease_audit_results",
      header: "Results",
      enableSorting: false,
      size: 80,
      cell: (cell) => {
        if (isLoading) {
          return <Skeleton className="w-20 h-1" />;
        }
        const summaryEntity = cell.row.original
          .summary_entity as null | LeaseAuditSummary;
        return (
          <span>
            {summaryEntity?.final_result || summaryEntity?.initial_result}
          </span>
        );
      },
    },
    {
      id: "lease_audit_failure_reason",
      header: "Reason",
      enableSorting: false,
      cell: (cell) => {
        if (isLoading) {
          return <Skeleton className="w-20 h-1" />;
        }
        const summaryEntity = cell.row.original
          .summary_entity as null | LeaseAuditSummary;
        return <span>{summaryEntity?.failure_reason}</span>;
      },
    },
    // Button for opening the drawer
    {
      id: "actions",
      enableSorting: false,
      cell: (cell) => (
        <button
          onClick={() => {
            if (isLoading) {
              return;
            }
            onClickRow(
              cell.row.original.id,
              cell.row.original.property_id,
              cell.row.original.type,
              cell.row.original.unit_number || undefined
            );
          }}
        >
          <img src={ChevronRight} />
        </button>
      ),
      size: 20,
    },
  ];

  const columnsById = allColumns.reduce(
    (acc, column) => {
      if (column.id) {
        acc[column.id] = column;
      }
      return acc;
    },
    {} as Record<string, ColumnDef<TaskSummary, any>>
  );

  const columns = useMemo(() => {
    return getColumnsForType(type)
      .map((id) => columnsById[id])
      .filter(Boolean)
      .concat([columnsById.actions]);
  }, [type, isLoading]);

  useEffect(() => {
    if (sorting.length === 0) {
      onChangeSort(key);
      return;
    }
    const [sort] = sorting;
    const field = sortFieldByColumnId[sort.id] || sort.id;
    onChangeSort(key, `${sort.desc ? "-" : ""}${field}` as TaskSortParam);
  }, [sorting]);
  return useReactTable({
    columns: columns,
    // For initial loading state
    data: isLoading && tasks.length === 0 ? emptyTasks : tasks,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => {
      return row.id + row.property_id;
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    manualSorting: true,
  });
};

const paginationDescription = ({
  total,
  page,
  pageSize,
  tasksLength,
}: {
  total: number;
  page: number;
  pageSize: number;
  tasksLength: number;
}) => {
  /**
   * Getting the start value. Calculates the number of items before the current page
   * Examples:
   * Page 1: (1 - 1) * 10 + 1 = 1 (The first item on page 1 is item 1.)
   * Page 2: (2 - 1) * 10 + 1 = 11 (The first item on page 2 is item 11.)
   * Page 3: (3 - 1) * 10 + 1 = 21 (The first item on page 3 is item 21.)
   */
  const start = (page - 1) * pageSize + 1;

  /**
   *  End value represents the index of the last item being displayed on the current page.
   *  If start = 21 and tasksLength = 10, then end = 21 + 10 - 1 = 30. This means that the last item on the page is item 30.
   */
  const end = start + tasksLength - 1;

  // Don't overrun the total number of tasks
  const adjustedEnd = Math.min(end, total);

  return `Showing ${start}${adjustedEnd > start ? ` - ${adjustedEnd}` : ""} of ${total} tasks`;
};

export const TaskTable = ({
  groupKey,
  isLoading,
  type,
  title,
  tasks,
  onClickRow,
  selectedTaskId,
  selectedTaskPropertyId,
  facet,
  total,
  page = 1,
  pageSize,
  fetchPage,
  updateSort,
  properties,
}: TaskTableProps) => {
  const table = useTableDef(
    groupKey,
    type,
    tasks,
    onClickRow,
    updateSort,
    isLoading,
    properties
  );
  const isFirstPage = page === 1;
  const isLastPage =
    total && page && pageSize ? total <= page * pageSize : false;

  const getFacetSection = () => {
    if (!facet && !isLoading) {
      return null;
    }
    return (
      <div className="flex items-center space-between border rounded-lg py-2 px-4 w-36 h-10">
        {isLoading ? (
          <>
            <div className="flex items-center space-x-1">
              <Icon icon="priority-high-skeleton" />
              <Skeleton className="w-3 h-1" />
            </div>
            <div className="flex items-center space-x-1">
              <Icon icon="priority-medium-skeleton" />
              <Skeleton className="w-3 h-1" />
            </div>
            <div className="flex items-center space-x-1">
              <Icon icon="priority-low-skeleton" />
              <Skeleton className="w-3 h-1" />
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center space-x-1">
              <Icon icon="priority-high" />
              <span className="text-foreground">{facet?.high ?? 0}</span>
            </div>
            <div className="flex items-center space-x-1">
              <Icon icon="priority-medium" />
              <span className="text-foreground">{facet?.medium ?? 0}</span>
            </div>
            <div className="flex items-center space-x-1">
              <Icon icon="priority-low" />
              <span className="text-foreground">{facet?.low ?? 0}</span>
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <div>
      <div>
        <div className="flex items-start justify-between mb-3">
          <div className="flex items-center space-x-2">
            {/* TODO: icon */}
            <span className="text-md text-foreground font-medium">{title}</span>
          </div>
          {getFacetSection()}
        </div>
        <div>
          {table.getRowModel().rows.length > 0 ? (
            <div>
              <table className="text-left border-separate border-spacing-0 w-full md:table-auto lg:table-fixed">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={clsx(
                            "border-t p-4 text-muted-foreground font-md text-sm uppercase select-none",
                            {
                              "text-left":
                                header.index < headerGroup.headers.length - 1 &&
                                header.index <= 2,
                              "text-right":
                                header.index === headerGroup.headers.length - 1,
                              "border-r":
                                header.index === headerGroup.headers.length - 1,
                              "border-l": header.index === 0,
                              "rounded-tl": header.index === 0,
                              "rounded-tr":
                                header.index === headerGroup.headers.length - 1,
                              "cursor-pointer": header.column.getCanSort(),
                            }
                          )}
                          onClick={header.column.getToggleSortingHandler()}
                          style={{ width: header.getSize() }}
                        >
                          {header.isPlaceholder ? null : (
                            <span className="flex items-center group space-x-1">
                              <span>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </span>
                              {header.column.getCanSort() &&
                                header.column.getIsSorted() && (
                                  <Icon
                                    icon={
                                      header.column.getIsSorted() === "asc"
                                        ? "sort-ascending"
                                        : "sort-descending"
                                    }
                                  />
                                )}
                            </span>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  key={groupKey}
                  style={{ height: 60 * table.getRowCount() }}
                >
                  {table.getRowModel().rows.map((row: Row<TaskSummary>, i) => {
                    return (
                      <tr
                        style={{ height: 60 }}
                        key={row.id || i}
                        onClick={() => {
                          ReactGA.event(
                            {
                              category: "TaskTable",
                              action: "click.taskRow",
                              label: "Task Row",
                            },
                            {
                              rowType: row.original.type,
                              propertyId: row.original.property_id,
                              unitNumber: row.original.unit_number || "",
                            }
                          );
                          onClickRow(
                            row.original.id,
                            row.original.property_id,
                            row.original.type,
                            row.original.unit_number || ""
                          );
                        }}
                        className={clsx(
                          "cursor-pointer hover:bg-accent hover:text-accent-foreground",
                          {
                            "bg-accent":
                              selectedTaskId === row.original.id &&
                              selectedTaskPropertyId ===
                                row.original.property_id,
                          }
                        )}
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className={clsx("border-b p-3", {
                                "border-t": row.index === 0,
                                "border-l": cell.column.getIndex() === 0,
                                "border-r":
                                  cell.column.getIndex() ===
                                  table.getAllColumns().length - 1,
                              })}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="flex items-center justify-between border-x border-b rounded-bl rounded-br px-3 text-muted-foreground text-sm w-100 bg-accent h-10">
                <div>
                  {isLoading ? (
                    <div className="flex items-center space-x-1">
                      <Skeleton className="w-20 h-1" />
                      <Skeleton className="w-12 h-1" />
                    </div>
                  ) : (
                    paginationDescription({
                      total: total || 0,
                      page: page || 1,
                      pageSize: pageSize || 0,
                      tasksLength: tasks.length,
                    })
                  )}
                </div>
                <div className="flex space-x-1">
                  <Button
                    className="px-1"
                    variant="ghost"
                    aria-label="previous"
                    disabled={isLoading || isFirstPage}
                    onClick={() => {
                      fetchPage(groupKey, page - 1);
                    }}
                  >
                    <Icon className="rotate-180" icon="chevron-right" />
                  </Button>
                  <Button
                    className="px-1"
                    variant="ghost"
                    aria-label="next"
                    disabled={isLoading || isLastPage}
                    onClick={() => {
                      fetchPage(groupKey, page + 1);
                    }}
                  >
                    <Icon icon="chevron-right" />
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-muted-foreground">
              Nice work! You have no urgent{" "}
              {getEntityName(type as TaskType)?.replace(/s$/, "")} tasks
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
