import { LeaseAudit } from "api-client";
import { Badge } from "surface-components";
import { formatDateMonthDayYear } from "../../../lib/utils";

interface LeaseAuditSummaryProps {
  item?: LeaseAudit;
}

export const LeaseAuditSummary: React.FC<LeaseAuditSummaryProps> = ({
  item,
}) => {
  const result = item?.initial_result || item?.final_result;

  return (
    <div className="p-6 bg-gradient-to-bl from-white to-indigo-100">
      <div className="flex flex-row items-center mb-4 space-x-2">
        <h2 className="text-xl text-gray-800">Real Time Audit Results:</h2>
        <Badge
          className="text-base"
          variant={result === "Failed" ? "destructive" : "default"}
        >
          {result === "Failed" ? "Fail" : result === "Passed" ? "Pass" : result}
          {item?.audit_date_time &&
            ` on ${formatDateMonthDayYear(item.audit_date_time)}`}
        </Badge>
      </div>
      {result === "Failed" && (
        <div>
          <h3 className="mb-4 text-md font-medium text-gray-800">
            Failed Checks
          </h3>
          <ul className="space-y-2">
            {item?.audit_checks?.map(
              (check, index) =>
                check.fail_reason && <li key={index}>{check.fail_reason}</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
