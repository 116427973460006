import { useCallback, useEffect, useMemo } from "react";
import {
  DefaultService,
  Task,
  TaskSortParam,
  TaskStatus,
  TaskSummary,
} from "@/api/client";
import { useMatch, useSearchParams } from "react-router-dom";
import { useUserProperties } from "./use-user-properties";
import React from "react";
import { API_ROOT, NAVIGATION } from "@/constants";
import { useFeatureFlag } from "@/lib/hooks/use-feature-flag";

export const taskTypes = {
  applications: "ApplicationTask",
  moveIns: "MoveInTask",
  moveOuts: "MoveOutTask",
  renewals: "RenewalTask",
  collections: "CollectionTask",
  maintenance: "MaintenanceTask",
  serviceRequest: "ServiceRequestTask",
  makeReady: "MakeReadyTask",
  leaseAudit: "LeaseAuditTask",
} as const;

export type TaskType = (typeof taskTypes)[keyof typeof taskTypes];

export const taskTypeFromParam = (typeParam?: string): TaskType | null => {
  switch (typeParam) {
    case NAVIGATION.APPLICATIONS:
      return "ApplicationTask";
    case NAVIGATION.MOVE_INS:
      return "MoveInTask";
    case NAVIGATION.MOVE_OUTS:
      return "MoveOutTask";
    case NAVIGATION.RENEWALS:
      return "RenewalTask";
    case NAVIGATION.COLLECTIONS:
      return "CollectionTask";
    case NAVIGATION.SERVICE_REQUESTS:
      return "ServiceRequestTask";
    case NAVIGATION.MAINTENANCE_REQUESTS:
      return "MaintenanceTask";
    case NAVIGATION.MAKE_READIES:
      return "MakeReadyTask";
    case NAVIGATION.LEASE_AUDITS:
      return "LeaseAuditTask";
    default:
      return null;
  }
};

export const getEntityName = (type: TaskType | null) => {
  switch (type) {
    case "ApplicationTask":
      return "Applications";
    case "MoveInTask":
      return "Move ins";
    case "MoveOutTask":
      return "Move outs";
    case "RenewalTask":
      return "Renewals";
    case "CollectionTask":
      return "Collections";
    case "MaintenanceTask":
      return "Maintenance requests";
    case "ServiceRequestTask":
      return "Service requests";
    case "MakeReadyTask":
      return "Make readies";
    case "LeaseAuditTask":
      return "Lease audits";
    default:
      return "";
  }
};

export const useTaskTypeFilter = () => {
  const match = useMatch(`/${NAVIGATION.PRIORITIES}/:type?`);
  const typeParam = match?.params.type;
  return taskTypeFromParam(typeParam);
};

const useAssignedToFilter = () => {
  const [params, setSearchParams] = useSearchParams();
  const assignedToFilter = params.get("assigned_to");

  const setAssignedToFilter = (userId: string) => {
    setSearchParams((params) => {
      if (userId) {
        params.set("assigned_to", userId);
      } else {
        params.delete("assigned_to");
      }
      return params;
    });
  };

  return { assignedToFilter, setAssignedToFilter };
};

export const useSelectedTask = () => {
  const { selectedPropertyIds: propertyIds } = useUserProperties();
  const [params, setSearchParams] = useSearchParams();
  const taskId = params.get("task_id");
  const taskPropertyId = params.get("task_p_id");
  const [selectedTask, setSelectedTask] = React.useState<Partial<Task> | null>(
    null
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const selectTask = useCallback(
    (
      taskId: string,
      propertyId: string,
      type?: string,
      unit_number?: string
    ) => {
      fetchTaskDetails(propertyId, taskId, type, unit_number);
      setSearchParams((params) => {
        params.set("task_id", taskId);
        params.set("task_p_id", propertyId);
        return params;
      });
    },
    [setSearchParams, propertyIds]
  );

  const clearSelectedTask = useCallback(() => {
    if (!params.get("task_id")) return;
    setSelectedTask(null);
    setSearchParams((params) => {
      params.delete("task_id");
      params.delete("task_p_id");
      return params;
    });
  }, [setSearchParams, params]);

  const fetchTaskDetails = async (
    propertyId: string,
    id: string,
    type?: string,
    unit_number?: string
  ) => {
    if (propertyId && id) {
      setIsLoading(true);
      setSelectedTask({
        id: id,
        property_id: propertyId,
        type: type,
        unit_summary: {
          unit_number: unit_number || null,
        },
      });
      try {
        const response =
          await DefaultService.getTaskApiWorkspacePropertyIdTasksIdGet(
            propertyId,
            id
          );
        setSelectedTask(response);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch task:", error);
        setIsLoading(false);
      }
    } else {
      setSelectedTask(null);
    }
  };

  useEffect(() => {
    if (
      taskId &&
      taskId !== selectedTask?.id &&
      taskPropertyId &&
      taskPropertyId !== selectedTask?.property_id
    ) {
      fetchTaskDetails(taskPropertyId, taskId);
    }
  }, [taskId, taskPropertyId]);

  return { selectedTask, selectTask, isLoading, clearSelectedTask };
};

interface TaskFilters {
  assignedTo?: string | null;
  status?: TaskStatus | null;
  type?: TaskType | null;
  priorityGroup?: "high" | "medium" | "low" | null;
}

export interface TaskGroupDef {
  key: string;
  title: string;
  filters: TaskFilters;
  page_size: number;
  sort?: TaskSortParam | null;
}

export interface TaskGroupState extends TaskGroupDef {
  total: number;
  page: number;
  loading: boolean;
  tasks: TaskSummary[] | null;
}

export interface TaskFacet {
  type: string;
  high: number;
  medium: number;
  low: number;
  total: number;
  completed: number;
  not_completed: number;
}

const DEFAULT_PAGE_SIZE = 5;

export const useTaskFacets = (filter?: TaskFilters) => {
  const { selectedPropertyIds: propertyIds } = useUserProperties();
  const [isLoading, setIsLoading] = React.useState(false);
  const [facets, setFacets] = React.useState<TaskFacet[] | null>(null);

  React.useEffect(() => {
    if (!propertyIds) return;
    DefaultService.tasksFacetsApiWorkspaceTasksFacetsGet(
      propertyIds,
      filter?.status,
      filter?.type
    )
      .then((facets) => {
        setFacets(facets);
      })
      .catch((error) => {
        console.error("Failed to fetch facets:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [propertyIds, filter?.status, filter?.type]);

  return { isLoading, facets };
};

export const useTasks = () => {
  const { value: leaseAuditsEnabled } = useFeatureFlag(
    "leaseAuditsEnabled",
    false
  );
  const selectedType = useTaskTypeFilter();
  const groupDefs: TaskGroupDef[] = useMemo(() => {
    if (selectedType) {
      return [
        {
          key: selectedType,
          title: `Open ${getEntityName(selectedType).toLowerCase()}`,
          filters: {
            type: selectedType,
            status: TaskStatus.NOT_COMPLETED,
          },
          page_size: 15,
        },
        {
          key: `${selectedType}-closed`,
          title: `Completed ${getEntityName(selectedType).toLowerCase()}`,
          filters: {
            type: selectedType,
            status: TaskStatus.COMPLETED,
          },
          page_size: DEFAULT_PAGE_SIZE,
          sort: TaskSortParam._COMPLETED_DATE,
        },
      ];
    }
    const groups: TaskGroupDef[] = [
      {
        key: taskTypes.applications,
        title: "Applications",
        filters: {
          type: taskTypes.applications,
          status: TaskStatus.NOT_COMPLETED,
          priorityGroup: "high",
        },
        page_size: DEFAULT_PAGE_SIZE,
      },
      {
        key: taskTypes.moveIns,
        title: "Move-ins",
        filters: {
          type: taskTypes.moveIns,
          status: TaskStatus.NOT_COMPLETED,
          priorityGroup: "high",
        },
        page_size: DEFAULT_PAGE_SIZE,
      },
      {
        key: taskTypes.moveOuts,
        title: "Move-outs",
        filters: {
          type: taskTypes.moveOuts,
          status: TaskStatus.NOT_COMPLETED,
          priorityGroup: "high",
        },
        page_size: DEFAULT_PAGE_SIZE,
      },
      {
        key: taskTypes.renewals,
        title: "Renewals",
        filters: {
          type: taskTypes.renewals,
          status: TaskStatus.NOT_COMPLETED,
          priorityGroup: "high",
        },
        page_size: DEFAULT_PAGE_SIZE,
      },
      {
        key: taskTypes.serviceRequest,
        title: "Service requests",
        filters: {
          type: taskTypes.serviceRequest,
          status: TaskStatus.NOT_COMPLETED,
          priorityGroup: "high",
        },
        page_size: DEFAULT_PAGE_SIZE,
      },
    ];
    if (leaseAuditsEnabled) {
      groups.push({
        key: taskTypes.leaseAudit,
        title: "Lease audits",
        filters: {
          type: taskTypes.leaseAudit,
          status: TaskStatus.NOT_COMPLETED,
          priorityGroup: "high",
        },
        page_size: DEFAULT_PAGE_SIZE,
      });
    }
    return groups;
  }, [selectedType]);
  const { selectedPropertyIds: propertyIds } = useUserProperties();

  const downloadParams = new URLSearchParams(
    selectedType
      ? {
          task_type: selectedType,
          sort: TaskSortParam._COMPLETED_DATE,
        }
      : {
          task_status: TaskStatus.NOT_COMPLETED,
          priority_group: "high",
          sort: TaskSortParam._PRIORITY,
        }
  );
  propertyIds.forEach((id) => downloadParams.append("property_ids", id));
  const downloadUrl = `${API_ROOT}/api/workspace/tasks/export?${downloadParams.toString()}`;

  const { assignedToFilter, setAssignedToFilter } = useAssignedToFilter();

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [groupsByKey, setGroupsByKey] = React.useState<
    Record<string, TaskGroupState>
  >({});

  const updateSort = (groupKey: string, sort?: TaskSortParam) => {
    fetchPage(groupKey, 1, sort || null);
  };

  const fetchPage = async (
    groupKey: string,
    page: number,
    sortParam?: TaskSortParam | null
  ) => {
    const group = groupsByKey[groupKey];
    if (!group || group.loading) return;
    const { filters } = group;
    const sort = sortParam === null ? sortParam : sortParam || group.sort;
    setGroupsByKey((groups) => {
      return {
        ...groups,
        [groupKey]: { ...groups[groupKey], sort, loading: true },
      };
    });
    try {
      const { tasks, total } = await DefaultService.tasksApiWorkspaceTasksGet(
        propertyIds,
        filters.status,
        filters.type,
        filters.priorityGroup,
        assignedToFilter,
        (page - 1) * group.page_size,
        group.page_size,
        (sort && [sort]) || undefined
      );
      setGroupsByKey((groups) => {
        return {
          ...groups,
          [groupKey]: {
            ...groups[groupKey],
            loading: false,
            page,
            tasks: tasks || [],
            total: total || 0,
          },
        };
      });
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
      setGroupsByKey((groups) => {
        return {
          ...groups,
          [groupKey]: {
            ...groups[groupKey],
            loading: false,
          },
        };
      });
    }
  };

  React.useEffect(() => {
    if (propertyIds) {
      setIsLoading(true);
      setGroupsByKey(
        groupDefs.reduce(
          (acc, group) => {
            acc[group.key] = {
              ...group,
              page: 1,
              total: 0,
              loading: false,
              tasks: Array(5).fill({}),
            };
            return acc;
          },
          {} as Record<string, TaskGroupState>
        )
      );
      Promise.all(
        groupDefs.map((group) =>
          DefaultService.tasksApiWorkspaceTasksGet(
            propertyIds,
            group.filters.status,
            group.filters.type,
            group.filters.priorityGroup,
            assignedToFilter,
            0,
            group.page_size,
            (group.sort && [group.sort]) || undefined
          )
        )
      )
        .then((tasks) => {
          setGroupsByKey(
            groupDefs.reduce(
              (acc, group, index) => {
                acc[group.key] = {
                  ...group,
                  total: tasks[index].total || 0,
                  page: 1,
                  page_size: group.page_size,
                  loading: false,
                  tasks: tasks[index].tasks || [],
                };
                return acc;
              },
              {} as Record<string, TaskGroupState>
            )
          );
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [propertyIds, assignedToFilter, groupDefs]);

  return {
    propertyIds,
    groups: Object.values(groupsByKey),
    assignedToFilter,
    setAssignedToFilter,
    updateSort,
    fetchPage,
    isLoading,
    error,
    downloadUrl,
  };
};
