// src/lib/combined-storage-service.ts
import {
  storageService as chromeStorageService,
  ChromeStorageService as StorageService,
} from "./chrome-storage-service"; // Rename to avoid conflict
import { localStorageService } from "./local-storage-service";

export const combinedStorageService: StorageService = {
  async getValueFromStorage(key: string, env: string) {
    try {
      const chromeResponse = await chromeStorageService.getValueFromStorage(
        key,
        env
      );
      if (chromeResponse.success) {
        return chromeResponse;
      }
    } catch (error) {
      console.warn(`Failed to get value from Chrome storage: ${error}`);
    }

    return localStorageService.getValueFromStorage(key, env);
  },

  async saveValueToStorage(key: string, value: unknown, env: string) {
    try {
      await chromeStorageService.saveValueToStorage(key, value, env);
    } catch (error) {
      console.warn(`Failed to save value to Chrome storage: ${error}`);
    }
    return localStorageService.saveValueToStorage(key, value, env);
  },
};
