import { Button, Icon } from "surface-components";
import { MiddleDot } from "../ui/middle-dot";
import { Charge, LeaseSummary, Resident360 } from "api-client";
import {
  BalanceDetail,
  ChargesDetail,
  LeaseHistoryDetail,
  MaintenanceSummaryDetail,
  PaymentSummaryDetail,
  PersonsDetail,
  PetsDetail,
  ResidentActivityDetail,
  ResidentStatusDetail,
  UnitDetail,
  VehiclesDetail,
} from "../ui/drawer-details";

interface ResidentDetailsProps {
  item: Partial<Resident360>;
  isLoading?: boolean;
  propertyId?: string;
  onClickResident?: (
    propertyId: string,
    residentId: string,
    name?: string | null
  ) => void;
}

const getRecurringCharges = (leases: LeaseSummary[]): Charge[] => {
  // TODO This matches what we do on the backend by just selecting the first
  // lease's recurring charges. We should make it consistent when we understand the requirements better.
  if (!leases || leases.length === 0) {
    return [];
  }

  const lease = leases[0];
  return lease.recurring_charges || [];
};

const ResidentDetails = ({
  item,
  isLoading,
  onClickResident,
  propertyId,
}: ResidentDetailsProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <ResidentActivityDetail
        label="Activity"
        value={item.activities}
        isLoading={isLoading}
      />
      <hr />
      <UnitDetail
        label="Unit"
        value={{
          unit_number: item.unit_number || "",
          square_feet: item.unit_number_of_bathrooms,
          floor_plan: item.floor_plan_name,
          num_bathrooms: item.unit_number_of_bathrooms,
          num_bedrooms: item.unit_number_of_bedrooms,
        }}
        isLoading={isLoading}
      />
      <hr />
      <PersonsDetail
        label="Residents"
        isLoading={isLoading}
        value={item.residents?.map((resident) => {
          return {
            name: resident.full_name,
            is_minor: resident.is_minor,
            phone_number: resident.phone_number,
            email_address: resident.email_address,
            is_primary: resident.is_primary,
            onClick: () => {
              if (resident.id && (propertyId || item.property_id)) {
                onClickResident?.(propertyId || item.property_id as string, resident.id, resident.full_name);
              }
            },
          };
        })}
      />
      <hr />
      <LeaseHistoryDetail
        label="Lease"
        value={item.leases || []}
        isLoading={isLoading}
      />
      <hr />
      <BalanceDetail
        label="Balance"
        value={{
          account_balance: item.account_balance ?? 0,
          last_payment_date: item.last_payment_date,
        }}
        isLoading={isLoading}
      />
      <hr />
      <ChargesDetail
        label="Recurring charges"
        value={getRecurringCharges(item.leases || [])}
        isLoading={isLoading}
      />
      <hr />
      <PaymentSummaryDetail
        label="Payment history"
        value={item?.payment_summary}
        isLoading={isLoading}
      />
      <hr />
      <ResidentStatusDetail
        label="Resident status"
        value={item.resident_account}
        isLoading={isLoading}
      />
      <hr />
      <MaintenanceSummaryDetail
        label="Maintenance history"
        value={item.maintenance_summary}
        isLoading={isLoading}
      />
      <hr />
      <PetsDetail label="Pets" value={item.pets} isLoading={isLoading} />
      <hr />
      <VehiclesDetail
        label="Vehicles"
        value={item.vehicles}
        isLoading={isLoading}
      />
    </div>
  );
};

const ResidentDetailsDrawer = ({
  resident,
  isLoading,
  onClose,
  propertyId,
  onClickResident,
}: {
  resident: Partial<Resident360>;
  isLoading: boolean;
  onClose: () => void;
  propertyId?: string;
  onClickResident?: (
    propertyId: string,
    residentId: string,
    name?: string | null
  ) => void;
}) => {
  return (
    <div>
      <div className="text-xl flex justify-between items-center text-muted-foreground px-6 h-[63px] border-b">
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="p-1 h-auto"
            onClick={() => {
              onClose();
            }}
          >
            <Icon icon="close" title="close" className="p-1" />
          </Button>
          <span className="text-foreground font-medium">Resident</span>
          {resident.full_name && (
            <>
              <MiddleDot />
              <span>{resident.full_name}</span>
            </>
          )}
        </div>
        <div>
          <Button
            onClick={() => {
              window.print();
            }}
            className="flex space-x-1"
            variant="outline"
          >
            <Icon icon="print" />
            <p>Print</p>
          </Button>
        </div>
      </div>
      <div className="flex flex-col space-y-4 py-4 px-6">
        <ResidentDetails
          item={resident}
          isLoading={isLoading}
          propertyId={propertyId}
          onClickResident={onClickResident}
        />
      </div>
    </div>
  );
};

export { ResidentDetails, ResidentDetailsDrawer };
