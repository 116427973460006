import { DefaultService, Resident360 } from "api-client";
import { useState } from "react";

const useResident = () => {
  const [resident, setResident] = useState<Partial<Resident360> | null>(null);
  const [isLoadingResident, setIsLoadingResident] = useState(false);

  const onResidentClick = (
    propertyId: string,
    residentId: string,
    fullName?: string | null
  ) => {
    setIsLoadingResident(true);
    setResident({ id: residentId, full_name: fullName });
    DefaultService.getResidentApiPropertiesPropertyIdResidentsResidentIdGet(
      propertyId,
      residentId
    )
      .then((res) => {
        setResident(res);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoadingResident(false);
      });
  };

  const clearResident = () => {
    setResident(null);
  }

  return { resident, isLoadingResident, onResidentClick, clearResident };
};

export { useResident };
