import {
  surface_core__api__entities__Application as Application,
  MakeReadyStatus,
  Task,
} from "api-client";
import { DrawerWorkItems } from "../drawer-work-items";
import {
  ApplicationProgressDetail,
  AssigneeDetail,
  AvailabilitySummaryDetail,
  DateDetail,
  Detail,
  LeaseSummaryDetails,
  PersonsDetail,
  PetsDetail,
  UnitDetail,
  VehiclesDetail,
} from "../../ui/drawer-details";
import { DrawerHeaderContainer } from "../drawer-header-container";
import ActionBar from "../action-bar/action-bar";

export const ApplicationDrawerContent = ({
  item,
  task,
  isLoading,
  onClickResident,
}: {
  task?: Partial<Task>;
  item?: Application;
  isLoading?: boolean;
  onClickResident?: (
    propertyId: string,
    residentId: string,
    name?: string | null
  ) => void;
}) => {
  return (
    <div className="flex flex-col">
      <DrawerHeaderContainer>
        <DrawerWorkItems task={task} isLoading={isLoading} />
        <ActionBar task={task} />
      </DrawerHeaderContainer>
      <div className="flex flex-col space-y-4 py-4 px-6">
        <AssigneeDetail
          isLoading={isLoading}
          label="Assigned to"
          value={task?.assigned_to}
          className="mb-4"
        />
        <hr />
        <UnitDetail
          label="Unit"
          value={task?.unit_summary}
          isLoading={isLoading}
        />
        <hr />
        <AvailabilitySummaryDetail
          label="Availability"
          value={{
            available_date: task?.unit_summary?.available_date,
            move_in_date: item?.move_in_date,
            make_ready_status: task?.unit_summary
              ?.make_ready_status as MakeReadyStatus | null,
          }}
          isLoading={isLoading}
        />
        <hr />
        <PersonsDetail
          label="Residents"
          value={task?.residents?.map((x) => {
            return {
              name: x.full_name,
              phone_number: x.phone_number,
              email_address: x.email_address,
              onClick: () => {
                if (x.id && task.property_id) {
                  onClickResident?.(task.property_id, x.id, x.full_name);
                }
              },
              // age: x.age,
            };
          })}
          isLoading={isLoading}
        />
        <hr />
        <Detail
          label="Leasing agent"
          value={item?.leasing_agent?.full_name}
          isLoading={isLoading}
        />
        <hr />
        <Detail
          label="Application type"
          value={item?.application_type}
          isLoading={isLoading}
        />
        <hr />
        <PetsDetail label="Pets" value={task?.pets} isLoading={isLoading} />
        <hr />
        <VehiclesDetail
          label="Vehicles"
          value={task?.vehicles}
          isLoading={isLoading}
        />
        <hr />
        <LeaseSummaryDetails
          label="Lease"
          value={task?.lease_summary}
          isLoading={isLoading}
        />
        <hr />
        <ApplicationProgressDetail
          label="Application progress"
          value={item?.status_changes}
          isLoading={isLoading}
        />
        <hr />
        <DateDetail
          label="Expiration date"
          value={item?.expiration_date}
          isLoading={isLoading}
        />
        <hr />
        <Detail label="Application ID" value={item?.id} isLoading={isLoading} />
      </div>
    </div>
  );
};
