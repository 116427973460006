export const ThumbsUpIcon = ({ active = false }) => (
  <svg
    className={active ? "fill-primary" : "fill-gray-600"}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
  >
    <g>
      <path
        fillOpacity="0.05"
        d="M7 8.5V15H4c-.13261 0-.25979-.0527-.35355-.1464C3.55268 14.7598 3.5 14.6326 3.5 14.5V9c0-.13261.05268-.25979.14645-.35355C3.74021 8.55268 3.86739 8.5 4 8.5h3Z"
      />
      <path d="M16.625 7.0075c-.1408-.15955-.3139-.28731-.5079-.37481-.194-.0875-.4043-.13273-.6171-.13269H12v-1c0-.66304-.2634-1.29893-.7322-1.76777C10.7989 3.26339 10.163 3 9.5 3c-.09289-.00007-.18396.02574-.263.07454-.07905.04879-.14293.11864-.1845.20171L6.69125 8H4c-.26522 0-.51957.10536-.70711.29289C3.10536 8.48043 3 8.73478 3 9v5.5c0 .2652.10536.5196.29289.7071.18754.1875.44189.2929.70711.2929h10.75c.3654.0001.7184-.1332.9925-.3748.2741-.2417.4506-.5751.4963-.9377l.75-6c.0265-.21124.0078-.42571-.0549-.62917-.0627-.20345-.168-.39122-.3089-.55083ZM4 9h2.5v5.5H4V9Zm11.9963-.9375-.75 6c-.0153.1209-.0741.232-.1655.3126-.0913.0805-.209.1249-.3308.1249H7.5V8.61812l2.29437-4.58937c.34003.06806.64603.25185.86583.52009.2198.26824.3399.60436.3398.95116V7c0 .13261.0527.25979.1464.35355.0938.09377.221.14645.3536.14645h4c.071-.00002.1411.01505.2058.04423.0646.02917.1224.07178.1693.12499.047.0532.082.11579.1029.1836.0209.06781.0271.13928.0183.20968Z" />
      <path
        fillOpacity={active ? "1" : "0"}
        d="M16.625 7.0075C16.4842 6.84795 16.3111 6.72019 16.1171 6.63269C15.9231 6.54519 15.7128 6.49996 15.5 6.5H12V5.5C12 4.83696 11.7366 4.20107 11.2678 3.73223C10.7989 3.26339 10.163 3 9.5 3C9.40711 2.99993 9.31604 3.02574 9.237 3.07454C9.15795 3.12333 9.09407 3.19318 9.0525 3.27625L6.69125 8H4C3.73478 8 3.48043 8.10536 3.29289 8.29289C3.10536 8.48043 3 8.73478 3 9V14.5C3 14.7652 3.10536 15.0196 3.29289 15.2071C3.48043 15.3946 3.73478 15.5 4 15.5H14.75C15.1154 15.5001 15.4684 15.3668 15.7425 15.1252C16.0166 14.8835 16.1931 14.5501 16.2388 14.1875L16.9888 8.1875C17.0153 7.97626 16.9966 7.76179 16.9339 7.55833C16.8712 7.35488 16.7659 7.16711 16.625 7.0075ZM4 9H6.5V14.5H4V9Z"
      />
    </g>
  </svg>
);
