import React, { useCallback, useEffect, useRef, useState } from "react";
import { GreetingCard } from "./cards/greeting-card";
import AnswerCard, { LoadStatus } from "./cards/answer-card";
import { ActiveTabStateEnum } from "@/lib/recoil/state";
import { Button, FormattedDate } from "surface-components";
import { Dialogue as ApiDialogue, AnswerSource } from "@/api/client";

type Dialogue = {
  dialogue_id?: string | null;
  question: string;
  loading: boolean;
  data?: ApiDialogue;
  error?: string;
  properties: string[];
  sources?: AnswerSource[] | null;
  answerChunks?: ApiDialogue["answer_stream_chunk"][];
};

type SurfaceAskContentUIProps = {
  greetingRef: React.RefObject<HTMLDivElement>;
  dialogues: Dialogue[];
  historyDialogues: ApiDialogue[];
  continuationToken: string | null;
  greeting: string;
  newestCardRef: HTMLDivElement | null;
  setNewestCardRef: (ref: HTMLDivElement) => void;
  activeTab: ActiveTabStateEnum;
  savedDialogueData: Dialogue | undefined;
  loadingSavedDialogues: boolean;
  handleLoadMore: () => void;
  scrollToNewHistory: boolean;
  setScrollToNewHistory: (value: boolean) => void;
};

const SurfaceAskContentUI: React.FC<SurfaceAskContentUIProps> = ({
  greetingRef,
  dialogues,
  historyDialogues,
  continuationToken,
  greeting,
  newestCardRef,
  setNewestCardRef,
  activeTab,
  savedDialogueData,
  loadingSavedDialogues,
  handleLoadMore,
  setScrollToNewHistory,
  scrollToNewHistory,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const firstNewHistoryRef = useRef<HTMLDivElement>(null);
  const [initialHistoryLength, setInitialHistoryLength] = useState<
    number | null
  >(null);

  const handleLoadMoreClick = async () => {
    const currentHistoryLength = historyDialogues.length;
    setScrollToNewHistory(true); // Prevent scroll to greeting
    await handleLoadMore();
    setInitialHistoryLength(currentHistoryLength);
  };

  useEffect(() => {
    if (
      initialHistoryLength !== null &&
      historyDialogues.length > initialHistoryLength &&
      scrollToNewHistory
    ) {
      if (firstNewHistoryRef.current) {
        firstNewHistoryRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      setInitialHistoryLength(null);
      setScrollToNewHistory(false); // Reset after scrolling
    }
  }, [
    historyDialogues,
    initialHistoryLength,
    scrollToNewHistory,
    setScrollToNewHistory,
  ]);

  const renderCard = useCallback(
    (dialogue: Dialogue, index: number) => {
      const isLastItem = index === dialogues.length - 1;
      const cardProps = {
        key: dialogue.dialogue_id || index,
        ref: isLastItem ? setNewestCardRef : null,
        dialogueId: dialogue.dialogue_id,
        title: dialogue.question || "Your Question",
        questionProperties: dialogue.properties || [],
        wrapperClassName: "scroll-mb-4",
      };

      if (dialogue.loading) {
        return (
          <AnswerCard
            {...cardProps}
            loadedSources={dialogue.sources || []}
            status={LoadStatus.Loading}
            answerChunks={dialogue?.answerChunks}
          />
        );
      } else if (dialogue.data) {
        return (
          <AnswerCard
            {...cardProps}
            answerText={dialogue.data?.answer || ""}
            loadedSources={dialogue.data.answer_sources || []}
            status={LoadStatus.Answered}
            feedback={dialogue.data.feedback}
            suggestions={dialogue.data.prompt_autosuggest || []}
            answeredDate={
              dialogue.data.answered_at
                ? new Date(dialogue.data.answered_at)
                : undefined
            }
          />
        );
      } else {
        return (
          <AnswerCard
            {...cardProps}
            answerText={dialogue.error}
            loadedSources={[]}
            status={LoadStatus.Error}
          />
        );
      }
    },
    [dialogues.length, newestCardRef, setNewestCardRef]
  );

  const renderCurrentExperience = () => (
    <>
      {continuationToken && (
        <div className="text-center mt-10">
          <Button variant="ghost" onClick={handleLoadMoreClick}>
            Load More
          </Button>
        </div>
      )}
      {historyDialogues?.filter((dialogue) => dialogue.answer).length > 0 && (
        <div data-testid="history-dialogues">
          {historyDialogues
            .filter((dialogue) => dialogue.answer)
            .slice()
            .reverse()
            .map((dialogue, index) => (
              <AnswerCard
                wrapperClassName={
                  !continuationToken && index === 0 ? "mt-12" : "scroll-mt-24"
                }
                key={dialogue.dialogue_id}
                dialogueId={dialogue.dialogue_id}
                title={dialogue.user_prompt}
                answerText={dialogue.answer as string}
                loadedSources={dialogue.answer_sources || []}
                status={LoadStatus.Answered}
                questionProperties={dialogue.properties || []}
                feedback={dialogue.feedback}
                suggestions={dialogue.prompt_autosuggest || []}
                answeredDate={
                  dialogue.answered_at
                    ? new Date(dialogue.answered_at)
                    : undefined
                }
                ref={index === 0 ? firstNewHistoryRef : null}
              />
            ))}
        </div>
      )}
      <div className="pt-8 block text-center">
        <FormattedDate />
      </div>

      <div ref={greetingRef} className={`${dialogues?.length === 0 && "pb-8"}`}>
        <GreetingCard greeting={greeting} />
      </div>

      {dialogues?.length > 0 && (
        <div ref={scrollContainerRef} data-testid="current-dialogues">
          {dialogues.map(renderCard)}
        </div>
      )}
    </>
  );

  const renderSavedAsks = () => {
    if (loadingSavedDialogues) {
      return null;
    }

    return (
      savedDialogueData && (
        <div data-testid="saved-dialogues">
          {renderCard(savedDialogueData, 0)}
        </div>
      )
    );
  };

  return (
    <div data-testid="ask-extension">
      {activeTab === ActiveTabStateEnum.AskAnything
        ? renderCurrentExperience()
        : renderSavedAsks()}
    </div>
  );
};

export default SurfaceAskContentUI;
