import {
  DefaultService,
  Property,
  UserCreate,
  UserRole,
  UserRoleEnum,
} from "api-client";

import { UpperNav } from "@/components/ui/upper-nav";
import { useAuth } from "@/lib/auth";
import { User } from "api-client";
import { useEffect, useState } from "react";
import { MiddleDot } from "@/components/ui/middle-dot";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { optionalCell } from "@/components/source-table/table-defintions/utils";
import { Drawer, DrawerContent, DrawerPortal } from "@/components/ui/drawer";
import {
  Button,
  Icon,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "surface-components";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useToast } from "@/components/ui/use-toast";

const columnHelper = createColumnHelper<User>();

const UsersTable = ({
  users,
  properties,
  selectUser,
  selectedUser,
}: {
  users: User[];
  properties: Property[];
  selectUser: (user: User) => void;
  selectedUser: User | null;
}) => {
  const propertiesById = properties.reduce(
    (acc, property) => {
      acc[property.id] = property;
      return acc;
    },
    {} as Record<string, Property>,
  );

  const table = useReactTable({
    data: users,
    columns: [
      columnHelper.accessor("email", { header: "Email", cell: optionalCell }),
      columnHelper.accessor("first_name", {
        header: "First Name",
        cell: optionalCell,
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        cell: optionalCell,
      }),
      columnHelper.accessor("mobile_number", {
        header: "Mobile Number",
        cell: optionalCell,
      }),
      columnHelper.accessor("is_enabled", {
        header: "Enabled",
      }),
      columnHelper.accessor("roles", {
        header: "Roles",
        cell: (cell) => {
          return (
            <div className="flex-column space-y-2">
              {cell.getValue().map((role: UserRole, idx: number) => {
                return (
                  <div
                    key={`${role.saas_tenant_id}-${role.user_id}-${role.property_id}-${role.role}-${idx}`}
                    className="flex items-center space-x-2"
                  >
                    <span>
                      {role.property_id
                        ? propertiesById[role.property_id].name
                        : "All Properties"}
                    </span>
                    <MiddleDot />
                    <span>{role.role}</span>
                  </div>
                );
              })}
            </div>
          );
        },
      }),
      columnHelper.accessor("id", { header: "ID" }),
    ],
    getCoreRowModel: getCoreRowModel<User>(),
  });
  return (
    <table className="text-left border-separate border-spacing-0 w-full table-auto">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className={clsx(
                  "border-y p-4 text-muted-foreground font-md text-sm uppercase cursor-pointer select-none text-left",
                  {
                    "border-r": header.index === headerGroup.headers.length - 1,
                    "border-l": header.index === 0,
                    "rounded-tl": header.index === 0,
                    "rounded-tr":
                      header.index === headerGroup.headers.length - 1,
                  },
                )}
              >
                {header.isPlaceholder ? null : (
                  <span className="flex items-center group">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </span>
                )}
              </th>
            ))}
            <th />
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            onClick={() => {
              selectUser(row.original);
            }}
            className={clsx(
              "cursor-pointer hover:bg-accent hover:text-accent-foreground",
              {
                "bg-accent": selectedUser?.id === row.original.id,
              },
            )}
          >
            {row.getVisibleCells().map((cell) => {
              return (
                <td
                  key={cell.id}
                  className={clsx("border-b p-3 text-left", {
                    "border-t": row.index === 0,
                    "border-l": cell.column.getIndex() === 0,
                    "border-r":
                      cell.column.getIndex() ===
                      table.getAllColumns().length - 1,
                    "rounded-tl":
                      row.index === 0 && cell.column.getIndex() === 0,
                    "rounded-tr":
                      row.index === 0 &&
                      cell.column.getIndex() ===
                        table.getAllColumns().length - 1,
                    "rounded-bl": row.index === table.getRowCount() - 1,
                    "rounded-br":
                      row.index === table.getRowCount() - 1 &&
                      cell.column.getIndex() ===
                        table.getAllColumns().length - 1,
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const UserDrawer = ({
  title,
  saasTenantId,
  properties,
  user,
  isValid,
  isDirty,
  onClose,
  update,
  reset,
  submit,
}: {
  title: string;
  saasTenantId: string;
  properties: Property[];
  isValid: boolean;
  isDirty: boolean;
  user: Partial<User>;
  onClose: () => void;
  reset: () => void;
  submit: () => void;
  update: (update: Partial<User>) => void;
}) => {
  return (
    <div>
      <div className="text-xl flex items-center justify-between text-muted-foreground px-4 py-5 border-b">
        <div className="flex items-center space-x-2">
          <Button variant="outline" className="p-1 h-auto" onClick={onClose}>
            <Icon icon="close" />
          </Button>
          <span className="text-foreground font-medium">{title}</span>
        </div>
        <div className="flex space-x-1 items-center">
          <Button variant="outline" disabled={!isDirty} onClick={reset}>
            Cancel
          </Button>
          <Button disabled={!isValid || !isDirty} onClick={submit}>
            Save
          </Button>
        </div>
      </div>
      <div className="p-4">
        <div className="flex-col space-y-2">
          <div className="flex-col space-y-2">
            <Label
              htmlFor="first_name"
              className="text-muted-foreground text-sm"
            >
              First Name
            </Label>
            <Input
              type="text"
              id="first_name"
              value={user.first_name}
              onChange={(e) => {
                update({
                  ...user,
                  first_name: e.target.value,
                });
              }}
              className="w-full border-b border-muted-foreground text-base"
            />
          </div>
          <div className="flex-col space-y-1">
            <Label
              htmlFor="last_name"
              className="text-muted-foreground text-sm"
            >
              Last Name
            </Label>
            <Input
              type="text"
              id="last_name"
              value={user.last_name}
              onChange={(e) => {
                update({
                  ...user,
                  last_name: e.target.value,
                });
              }}
              className="w-full border-b border-muted-foreground text-base"
            />
          </div>
          <div className="flex-col space-y-1">
            <Label htmlFor="email" className="text-muted-foreground text-sm">
              Email
            </Label>
            <Input
              type="email"
              id="email"
              value={user.email}
              onChange={(e) => {
                update({
                  ...user,
                  email: e.target.value,
                });
              }}
              className="w-full border-b border-muted-foreground text-base"
            />
          </div>
          <div className="flex-col space-y-1">
            <Label
              htmlFor="mobile_number"
              className="text-muted-foreground text-sm"
            >
              Mobile Number
            </Label>
            <Input
              type="tel"
              id="mobile_number"
              value={user.mobile_number ?? ""}
              onChange={(e) => {
                update({
                  ...user,
                  mobile_number: e.target.value,
                });
              }}
              className="w-full border-b border-muted-foreground text-base"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Label
              htmlFor="is_enabled"
              className="text-muted-foreground text-sm"
            >
              Enabled
            </Label>
            <Checkbox
              id="is_enabled"
              checked={user.is_enabled}
              onClick={() => {
                update({
                  ...user,
                  is_enabled: !user.is_enabled,
                });
              }}
            />
          </div>
          <div className="flex-col space-y-1">
            <Label htmlFor="roles" className="text-muted-foreground text-sm">
              Roles
            </Label>
            <div className="flex-col space-y-2">
              {user.roles?.map((role, idx) => {
                return (
                  <div
                    key={`${role.saas_tenant_id}-${role.user_id}-${role.property_id}-${role.role}-${idx}`}
                    className="flex items-center space-x-2"
                  >
                    <Select
                      defaultValue={role.property_id || "all"}
                      onValueChange={(value) => {
                        const newRoles = [...(user.roles || [])];
                        newRoles[idx] = {
                          ...role,
                          property_id: value === "all" ? undefined : value,
                        };
                        update({
                          ...user,
                          roles: newRoles,
                        });
                      }}
                    >
                      <SelectTrigger className={`w-64 p-2`}>
                        <SelectValue placeholder="All" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="all">All Properties</SelectItem>
                          {properties?.map((property) => {
                            return (
                              <SelectItem key={property.id} value={property.id}>
                                {property.name}
                              </SelectItem>
                            );
                          })}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <Select
                      defaultValue={role.role}
                      onValueChange={(value: string) => {
                        const newRoles = [...(user.roles || [])];
                        newRoles[idx] = {
                          ...role,
                          role: value as UserRoleEnum,
                        };
                        update({
                          ...user,
                          roles: newRoles,
                        });
                      }}
                    >
                      <SelectTrigger className={`w-64 p-2`}>
                        <SelectValue placeholder="Select an option" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {Object.values(UserRoleEnum).map((role) => {
                            return (
                              <SelectItem key={role} value={role}>
                                {role}
                              </SelectItem>
                            );
                          })}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {(user?.roles || []).length > 1 && (
                      <Button
                        variant="outline"
                        onClick={() => {
                          const newRoles = [...(user.roles || [])];
                          newRoles.splice(idx, 1);
                          update({
                            ...user,
                            roles: newRoles,
                          });
                        }}
                      >
                        <Icon icon="close" />
                      </Button>
                    )}
                  </div>
                );
              })}
              <Button
                variant="outline"
                onClick={() => {
                  update({
                    ...user,
                    roles: [
                      ...(user.roles || []),
                      {
                        role: UserRoleEnum.ONSITE_TEAM_MEMBER,
                        user_id: user?.id || "",
                        saas_tenant_id: saasTenantId,
                        property_id: undefined,
                      },
                    ],
                  });
                }}
              >
                Add Role
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Users = () => {
  const { toast } = useToast();
  const { user } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const [userForm, update] = useState<Partial<User> | null>(null);

  useEffect(() => {
    if (user?.saas_tenant_id) {
      DefaultService.getUsersApiAdminUsersGet(user.saas_tenant_id).then(
        (resp) => {
          setUsers(resp);
        },
      );
    }
  }, [user?.saas_tenant_id]);

  const userFormState = {
    ...selectedUser,
    ...userForm,
  };

  const insertUser = async () => {
    if (!userFormState.id) {
      DefaultService.createUserApiAdminUsersPost({
        ...userFormState,
      } as UserCreate)
        .then((resp) => {
          toast({
            title: "User saved successfully",
            variant: "success",
          });
          setUsers((prev) => {
            const newUsers = [...prev, resp];
            return newUsers;
          });
        })
        .catch((e) => {
          toast({
            title: "Error creating user",
            description: e.message,
            variant: "destructive",
          });
        });
    } else {
      DefaultService.updateUserApiAdminUsersUserIdPut(
        userFormState.id,
        userFormState as User,
      )
        .then((resp) => {
          toast({
            title: "User updated successfully",
            variant: "success",
          });
          setUsers((prev) => {
            const newUsers = prev.map((u) => {
              if (u.id === resp.id) {
                return resp;
              }
              return u;
            });
            return newUsers;
          });
        })
        .catch((e) => {
          toast({
            title: "Error updating user",
            description: e.message,
            variant: "destructive",
          });
        });
    }
  };

  const isFormValid = !!userFormState.roles?.length && !!userFormState.email;

  return (
    <div className="w-full h-full overflow-y-scroll pb-4 bg-white">
      <UpperNav
        left={
          <div className="flex space-x-2 items-center text-muted-foreground">
            <span className="text-foreground">Admin</span>
            <MiddleDot />
            <span>Users</span>
          </div>
        }
        right={<div></div>}
      />
      <div className="w-full container mt-8">
        <div className="flex items-center justify-between  mb-4">
          <div className="font-medium">Users</div>
          <Button
            onClick={() => {
              update({
                is_enabled: true,
                roles: [
                  {
                    role: UserRoleEnum.ONSITE_TEAM_MEMBER,
                    user_id: "",
                    saas_tenant_id: user?.saas_tenant_id!,
                    property_id: undefined,
                  },
                ],
              });
            }}
          >
            New User
          </Button>
        </div>
        <UsersTable
          properties={user?.properties || []}
          users={users}
          selectUser={setSelectedUser}
          selectedUser={selectedUser}
        />
      </div>
      <Drawer
        direction="right"
        open={!!(selectedUser || userForm)}
        dismissible={false}
        onOpenChange={(open) => {
          if (!open && (selectedUser || userForm)) {
            setSelectedUser(null);
            update(null);
          }
        }}
      >
        <DrawerPortal>
          <DrawerContent className="h-screen top-0 right-0 left-auto mt-0 w-[640px] overflow-y-scroll overflow-x-hidden focus-visible:outline-none">
            {(selectedUser || userForm) && (
              <UserDrawer
                saasTenantId={user?.saas_tenant_id!}
                properties={user?.properties || []}
                title={
                  selectedUser
                    ? `${selectedUser.first_name} ${selectedUser.last_name}`
                    : "New User"
                }
                user={userFormState}
                isValid={isFormValid}
                isDirty={!!userForm}
                onClose={() => {
                  setSelectedUser(null);
                  update(null);
                }}
                update={update}
                reset={() => {
                  setSelectedUser(null);
                  update(null);
                }}
                submit={() => {
                  insertUser();
                  setSelectedUser(null);
                  update(null);
                }}
              />
            )}
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </div>
  );
};
