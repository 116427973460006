import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  Updater,
  PaginationState,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { AnswerSource } from "api-client";
import { Icon, Button } from "surface-components";
import { applicationTableDefs } from "./table-defintions/application";
import { residentTableDefs } from "./table-defintions/resident";
import { unitTableDefs } from "./table-defintions/unit";
import { leaseTableDefs } from "./table-defintions/lease";
import { floorPlanTableDefs } from "./table-defintions/floor-plan";
import { policyTableDefs } from "./table-defintions/policy";
import { moveInTableDefs } from "./table-defintions/move-in";
import { moveOutTableDefs } from "./table-defintions/move-out";
import { renewalTaskTableDefs } from "./table-defintions/renewal-task";
import { createServiceRequestTableDefs } from "./table-defintions/service-request";

const getEntityTableDef = (source: AnswerSource, properties: {id: string, name: string}[]) => {
  const item = source.data?.items?.[0];
  if (!item) {
    return null;
  }
  switch (item.type) {
    case "Application":
      return applicationTableDefs;
    case "Resident360":
    case "Resident":
      return residentTableDefs;
    case "Unit":
      return unitTableDefs;
    case "Lease":
      return leaseTableDefs;
    case "FloorPlan":
      return floorPlanTableDefs;
    case "Policy":
      return policyTableDefs;
    case "MoveIn":
      return moveInTableDefs;
    case "MoveOut":
      return moveOutTableDefs;
    case "RenewalTask":
      return renewalTaskTableDefs;
    case "ServiceRequestTask":
      return createServiceRequestTableDefs(properties);
    default:
      console.error("Unknown item type", item);
      return null;
  }
};

export const useTableDef = (
  source: AnswerSource,
  onClickRow: (sourceId: string, rowId: string) => void,
  sorting: SortingState,
  setSorting: (updaterOrValue: Updater<SortingState> | SortingState) => void,
  pagination: PaginationState,
  setPagination: (updaterOrValue: Updater<PaginationState> | PaginationState) => void,
  properties: {id: string; name: string}[],
) => {
  const tableDefs = getEntityTableDef(source, properties);
  const initialColumns = tableDefs?.staticColumns.concat(
    source.priority_column_names || [],
  );
  const columns = tableDefs?.columns
    .filter((column) => initialColumns?.includes(column.id!))
    .slice(0, 5);

  columns?.push({
    id: "actions",
    cell: (cell) => (
      <Button
        variant="ghost"
        onClick={() => {
          onClickRow(source.id, cell.row.id);
        }}
      >
        <Icon icon="chevron-right" />
      </Button>
    ),
  });

  return useReactTable({
    columns: columns || [],
    data: source.data?.items || [],
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row[tableDefs?.rowId || "id"],
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });
};
