export const RenewalsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    {...props}
  >
    <g fill={props.fill}>
      <path fillOpacity=".05" d="M15.5 5v3h-3zm-11 10 3-3h-3z" />
      <path d="M15.691 4.538a.5.5 0 0 0-.545.108l-1.004 1.005C12.884 4.569 11.46 4 10 4 7.324 4 5.714 5.58 5.646 5.646a.5.5 0 1 0 .708.708C6.367 6.34 7.734 5 10 5c1.448 0 2.62.678 3.432 1.36l-1.286 1.286a.5.5 0 0 0 .354.854h3A.5.5 0 0 0 16 8V5a.5.5 0 0 0-.309-.462M15 7.5h-1.293L15 6.207zm-1.354 6.146C13.633 13.66 12.266 15 10 15c-1.448 0-2.62-.678-3.432-1.36l1.286-1.286A.5.5 0 0 0 7.5 11.5h-3a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .854.354l1.003-1.005C7.118 15.431 8.54 16 10 16c2.676 0 4.286-1.58 4.354-1.646a.5.5 0 0 0-.708-.708M5 12.5h1.293L5 13.793z" />
    </g>
  </svg>
);
