import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Icon,
} from "surface-components";
import { DefaultService, UserSession } from "api-client";
import React from "react";
import clsx from "clsx";

export const SaasTenantSelect = ({ user }: { user: UserSession }) => {
  const saas_tenant = user?.saas_tenants?.find(
    (tenant) => tenant.id === user?.saas_tenant_id
  );
  const [open, setOpen] = React.useState(false);
  return user?.saas_tenants && user?.saas_tenants?.length > 1 ? (
    <DropdownMenu open={open} onOpenChange={() => setOpen((open) => !open)}>
      <DropdownMenuTrigger
        className="text-xl bg-muted p-0 flex items-center"
        asChild
      >
        <Button
          className="flex items-center space-x-1 text-xl text-foreground p-0 outline-none"
          variant="ghost"
        >
          <span>{saas_tenant?.name}</span>
          <Icon
            icon="chevron-down"
            className={clsx(
              "transition-rotate duration-300",
              open && "rotate-180"
            )}
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <ul>
          {user?.saas_tenants
            .filter((tenant) => tenant.id !== saas_tenant?.id)
            .map((tenant) => (
              <li key={tenant.id} className="w-[200px]">
                <DropdownMenuItem>
                  <a
                    className="w-full block p-2 cursor-pointer text-base"
                    onClick={async (e) => {
                      e.preventDefault();
                      await DefaultService.updateMeApiMePatch({
                        saas_tenant_id: tenant.id,
                      });
                      location.assign(location.pathname);
                    }}
                  >
                    {tenant.name}
                  </a>
                </DropdownMenuItem>
              </li>
            ))}
        </ul>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <span className="text-foreground">{saas_tenant?.name}</span>
  );
};
