import { properties, savedDialoguesState } from "@/lib/recoil/state";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Button, HoverCard, HoverCardContent, HoverCardTrigger, Icon } from "surface-components";
import { DialogueFeedback } from "api-client";
import { SaveIcon } from "@/components/ui/icons/save-icon";
import { Feedback } from "@/components/ask/feedback/feedback";
import { timeAgo } from "@/lib/utils";
import ReactGA from "react-ga4";

type AnswerCardFooterProps = {
  dialogueId?: string | null;
  questionProperties: string[];
  feedback?: DialogueFeedback | null;
  answeredDate?: Date;
  title: string;
};

export function AnswerCardFooter({
  dialogueId,
  questionProperties,
  feedback,
  answeredDate,
  title,
}: AnswerCardFooterProps) {
  const availableProperties = useRecoilValue(properties);
  const savedDialogues = useRecoilValue(savedDialoguesState) || [];
  const setSavedDialogues = useSetRecoilState(savedDialoguesState);

  const isSaved =
    dialogueId &&
    savedDialogues.some((dialogue) => dialogue.dialogueId === dialogueId);

  const getQuestionPropertyName = (questionProperties: string[]) => {
    if (questionProperties.length === 1) {
      return availableProperties.find((p) => p.id === questionProperties[0])
        ?.name;
    } else if (questionProperties.length === availableProperties.length) {
      return "All Properties";
    } else {
      return `${questionProperties.length} Properties`;
    }
  };

  const handleSaveAnswer = () => {
    ReactGA.event("click.saveAnswer", {
      dialogueId: dialogueId || "",
    });
    if (dialogueId) {
      setSavedDialogues((prev) => {
        const previous = prev || [];
        const updated = previous.some(
          (dialogue) => dialogue.dialogueId === dialogueId
        )
          ? previous.filter((dialogue) => dialogue.dialogueId !== dialogueId)
          : [...previous, { dialogueId: dialogueId, title }];
        return updated;
      });
    }
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <Button variant="outline" size={"sm"} className="bg-transparent mr-2">
          <a
            href={`/ask/question/${dialogueId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
            onClick={() => {
              ReactGA.event("click.sources", { dialogueId });
            }}
          >
            <Icon icon="applications" />
            <span className="text-smdm">Surfaced data</span>
          </a>
        </Button>
        <Button
          variant="outline"
          className="bg-transparent flex items-center mr-4"
          size="icon"
          onClick={() => {
            ReactGA.event("click.downloadSources", { dialogueId });
          }}
        >
          <a
            href={`/api/ask/${dialogueId}/sources`}
            target="_blank"
            rel="noopener noreferrer"
            download={`ask-sources-${dialogueId}.zip`}
          >
            <Icon icon="download" />
          </a>
        </Button>
        {questionProperties?.length > 0 && (
          <HoverCard>
            <HoverCardTrigger>
              <span className="text-left text-muted-foreground text-sm">
                {getQuestionPropertyName(questionProperties)}
              </span>
            </HoverCardTrigger>
            <HoverCardContent side="top">
              <ul className="text-left text-muted-foreground">
                {questionProperties.map((id) => {
                  return (
                    <li key={id}>
                      {availableProperties.find((p) => p.id === id)?.name}
                    </li>
                  );
                })}
              </ul>
            </HoverCardContent>
          </HoverCard>
        )}
      </div>
      <div className="flex gap-2 items-center">
        {answeredDate && (
          <span className="text-muted-foreground text-sm">
            {timeAgo(answeredDate)}
          </span>
        )}
        <Feedback feedback={feedback} dialogueId={dialogueId} />
        <Button
          variant="outline"
          size="icon"
          onClick={handleSaveAnswer}
          disabled={false}
        >
          <SaveIcon active={isSaved ? true : false} />
        </Button>
      </div>
    </div>
  );
}
