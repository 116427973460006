import { LeaseAuditCheck } from "api-client";

export const getUniqueLeaseDocs = (data: LeaseAuditCheck[]): string[] => {
  const uniqueDocs = new Set<string>();

  data.forEach((item) => {
    if (item.lease_document_name) {
      uniqueDocs.add(item.lease_document_name);
    }
  });

  return Array.from(uniqueDocs);
};

export const statusFormatter = (status: string): string => {
  switch (status) {
    case "Passed":
      return "Pass";
    case "Failed":
      return "Fail";
    default:
      return status;
  }
};
