// grab saved asks
// set selectedSavedDialogueState when clicked

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
  savedDialoguesState,
  selectedSavedDialogueState,
  SavedAsk,
  activeTabState,
  ActiveTabStateEnum,
} from "@/lib/recoil/state";

export const useManageSavedDialogues = () => {
  const savedDialogues = useRecoilValue(savedDialoguesState);
  const [activeSavedDialogueState, setSelectedSavedDialogueState] =
    useRecoilState(selectedSavedDialogueState);
  const setActiveTabState = useSetRecoilState(activeTabState);

  const setActiveSavedDialogueState = (dialogue: SavedAsk) => {
    setSelectedSavedDialogueState(dialogue);
    setActiveTabState(ActiveTabStateEnum.SavedAsks);
  };

  const setNoActiveDialogueState = () => {
    setSelectedSavedDialogueState({ dialogueId: "", title: "" });
    setActiveTabState(ActiveTabStateEnum.AskAnything);
  };

  const handleSetSavedDialogueState = (dialogue: SavedAsk) => {
    activeSavedDialogueState.dialogueId === dialogue.dialogueId
      ? setNoActiveDialogueState()
      : setActiveSavedDialogueState(dialogue);
  };

  return {
    savedDialogues,
    activeSavedDialogueState,
    setActiveSavedDialogueState,
    setNoActiveDialogueState,
    handleSetSavedDialogueState,
  };
};
