import { createColumnHelper } from "@tanstack/react-table";
import { FloorPlan } from "api-client";
import { TableDef, optionalCell } from "./utils";

const floorPlanColumnHelps = createColumnHelper<FloorPlan>();

const floorPlanTableDefs: TableDef = {
  columns: [
    floorPlanColumnHelps.accessor("floor_plan_name", {
      id: "name",
      header: "Name",
      cell: optionalCell,
    }),
    floorPlanColumnHelps.accessor("number_of_bedrooms", {
      id: "number_of_bedrooms",
      header: "Bedrooms",
      cell: optionalCell,
    }),
    floorPlanColumnHelps.accessor("number_of_bathrooms", {
      id: "number_of_bathrooms",
      header: "Bathrooms",
      cell: optionalCell,
    }),
    floorPlanColumnHelps.accessor("square_footage", {
      id: "square_footage",
      header: "Square Footage",
      cell: optionalCell,
    }),
  ],
  staticColumns: [
    "floor_plan_name",
    "number_of_bedrooms",
    "number_of_bathrooms",
    "square_footage",
  ],
  rowId: "id",
};

export { floorPlanTableDefs };
