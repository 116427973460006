// Custom hook to encapsulate the logic for setting properties
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { properties, selectedPropertyIds } from "@/lib/recoil/state";
import { useAuth } from "@/lib/auth";
import { NavigateOptions, useSearchParams } from "react-router-dom";

export const useUserProperties = () => {
  const [params, setSearchParams] = useSearchParams();
  const [userProperties, setProperties] = useRecoilState(properties);
  const propertyIdParam = params.getAll("property_id");
  const propertyIds = (
    propertyIdParam
      ? userProperties.filter((p) => propertyIdParam?.includes(p.id))
      : userProperties
  ).map((p) => p.id);
  const [globalSelectedProperty, setGlobalSelectedProperty] =
    useRecoilState(selectedPropertyIds);
  const { user } = useAuth();

  const selectProperties = (propertyIds: string | string[], navOptions?: NavigateOptions) => {
    setSearchParams((params) => {
      const ids = Array.isArray(propertyIds) ? propertyIds : [propertyIds];
      params.delete("property_id");
      ids.forEach((id) => {
        params.append("property_id", id);
      });
      return params;
    }, navOptions);
  };

  const removeProperty = (propertyId: string) => {
    setSearchParams((params) => {
      params.delete("property_id", propertyId);
      return params;
    });
  };

  useEffect(() => {
    if (user?.properties) {
      setProperties(user.properties);
    }
  }, [user, setProperties]);

  useEffect(() => {
    if (
      propertyIds &&
      propertyIds.join("") !== globalSelectedProperty.join("")
    ) {
      setGlobalSelectedProperty(propertyIds);
    }
  }, [propertyIds, setGlobalSelectedProperty]);

  return {
    selectedPropertyIds: globalSelectedProperty,
    selectProperties,
    removeProperty,
    properties: userProperties,
  };
};
