import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { ConfigCatProvider } from 'configcat-react';
import * as Sentry from '@sentry/react';
import { extensionManager } from '@/lib/extension-manager';

import { getConfig } from './config';
import App from './App';

const { sentryDsn, configCatSdkKey, mode, gaId } = getConfig();

extensionManager.initialize();

ReactGA.initialize(gaId);
if (sentryDsn) {
  Sentry.init({
    environment: mode,
    dsn: sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: import.meta.env.PROD ? 1 : 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ConfigCatProvider sdkKey={configCatSdkKey}>
      <App />
    </ConfigCatProvider>
  </React.StrictMode>
);
