import clsx from "clsx";
import React from "react";

const DrawerHeaderContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        "p-6 bg-gradient-to-bl from-white to-indigo-100 flex-col space-y-4",
        className
      )}
    >
      {children}
    </div>
  );
};

export { DrawerHeaderContainer };
