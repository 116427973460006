import { useState, useRef, useEffect } from "react";
import { CircleSurfaceIcon } from "@/components/ui/icons/circle-surface";
import { Button, PropertySelect } from "surface-components";
import { useAskAndPoll } from "@/lib/hooks/use-ask-and-poll";
import clsx from "clsx";
import { useUserProperties } from "@/lib/hooks/use-user-properties";

// TODO: Store the user input in local storage if
// the user closes the extension and reopens it

type AskInputProps = {
  placeholder: string;
  autoFocus?: boolean;
};

export const AskInput = ({ placeholder, autoFocus = false }: AskInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { askQuestion, cancelQuestion, loadingDialogue } = useAskAndPoll();
  const { properties, selectedPropertyIds, selectProperties } =
    useUserProperties();
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const loadingDialogueId = loadingDialogue?.id;
  const styles = {
    containerBase:
      "border border-solid flex gap-4 rounded-2xl flex-col flex-col p-4 transition-all",
    containerStateFocused: "bg-white shadow-md border-border",
    containerStateBlurred: "border-border hover:bg-white/40",

    input:
      "bg-transparent border-none focus:outline-none rounded-md flex-1 text-black text-md",
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  const handleInteraction = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (
      event.type === "click" ||
      (event as React.KeyboardEvent).key === "Enter"
    ) {
      if (loadingDialogueId) {
        cancelQuestion();
      } else if (inputValue.trim() !== "") {
        askQuestion(inputValue);
        setInputValue("");
      }
    }
  };

  const handleEnterKey = (event: React.KeyboardEvent) => {
    if ((event as React.KeyboardEvent).key === "Enter") {
      if (loadingDialogue) {
        cancelQuestion();
      }
      if (inputValue.trim() !== "") {
        askQuestion(inputValue);
        setInputValue("");
      }
    }
  };
  return (
    <div
      className={`${styles.containerBase} ${isFocused ? styles.containerStateFocused : styles.containerStateBlurred}`}
    >
      <div className="w-[300px] text-sm">
        <PropertySelect
          properties={properties}
          selectedPropertyIds={selectedPropertyIds}
          updatePropertyIds={selectProperties}
        />
      </div>
      <div className="flex justify-between size-full gap-1">
        <input
          id="ask-input"
          autoComplete="off"
          className={styles.input}
          ref={inputRef}
          type="text"
          spellCheck="true"
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={placeholder}
          value={inputValue}
          onKeyDown={handleEnterKey}
        />
        <Button
          onClick={handleInteraction}
          onKeyDown={(e) => handleInteraction(e)}
          disabled={!loadingDialogueId && !isFocused && inputValue === ""}
          aria-label={loadingDialogueId ? "Cancel" : "Ask"}
          className={clsx(
            "justify-center items-center gap-1 inline-flex transition-[width] duration-300",
            {
              "w-12": loadingDialogueId,
              "w-24": !loadingDialogueId,
            }
          )}
          variant="askBranded"
          size="lg"
        >
          {loadingDialogueId ? (
            <div className="w-3 h-3 bg-white rounded-sm" />
          ) : (
            <>
              <div className="w-6 h-6">
                <CircleSurfaceIcon color={"#F0F2F4"} size={"small"} />
              </div>
              <p className="text-gray-100 text-xl">Ask</p>
            </>
          )}
        </Button>
      </div>
    </div>
  );
};
