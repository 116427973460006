import { cn } from "@/lib/utils";
interface IUpperNavProps {
  left: JSX.Element;
  right: JSX.Element;
  className?: string;
}

const UpperNav = ({ left, right, className }: IUpperNavProps) => {
  return (
    <>
      <div
        className={cn(
          "flex justify-between items-center py-4 px-8 border-b h-[64px] ",
          className
        )}
      >
        <div className="flex items-center space-x-4 text-xl">{left}</div>
        {right}
      </div>
    </>
  );
};

export { UpperNav };
