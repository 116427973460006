import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { ResidentOccupancy, Unit } from "api-client";
import { TableDef, optionalCell, optionalDateCell, unitCell } from "./utils";

const unitColumnHelps = createColumnHelper<Unit>();

const unitTableDefs: TableDef = {
  columns: [
    unitColumnHelps.accessor("unit_number", {
      id: "unit_number",
      header: "Unit",
      cell: unitCell,
    }),
    unitColumnHelps.accessor("property_name", {
      id: "property_name",
      header: "Property Name",
      cell: optionalCell,
    }),
    unitColumnHelps.accessor("floor_number", {
      id: "floor_number",
      header: "Floor",
      cell: optionalCell,
    }),
    unitColumnHelps.accessor("floor_plan_name", {
      id: "floor_plan_name",
      header: "Floor Plan",
      cell: optionalCell,
    }),
    unitColumnHelps.accessor("number_of_bedrooms", {
      id: "number_of_bedrooms",
      header: "Bedrooms",
    }),
    unitColumnHelps.accessor("number_of_bathrooms", {
      id: "number_of_bathrooms",
      header: "Bathrooms",
    }),
    unitColumnHelps.accessor("square_footage", {
      id: "square_footage",
      header: "Square Footage",
    }),
    unitColumnHelps.accessor("is_furnished", {
      id: "is_furnished",
      header: "Furnished",
      cell: optionalCell,
    }),
    unitColumnHelps.accessor("occupancy_status", {
      id: "occupancy_status",
      header: "Occupancy Status",
    }),
    unitColumnHelps.accessor("make_ready_status", {
      id: "make_ready_status",
      header: "Make Ready Status",
    }),
    unitColumnHelps.accessor("availability_status", {
      id: "availability_status",
      header: "Availability Status",
    }),
    unitColumnHelps.accessor("amenities", {
      id: "amenities",
      header: "Amenities",
      cell: (cell: CellContext<Unit, string[] | null>) => {
        const amenities = cell.getValue();
        if (!amenities || !amenities.length) {
          return "-";
        }
        return amenities.join(", ");
      },
    }),
    unitColumnHelps.accessor("current_residents", {
      id: "current_residents",
      header: "Current Residents",
      cell: (cell: CellContext<Unit, ResidentOccupancy[] | null>) => {
        const residents = cell.getValue();
        if (!residents || !residents.length) {
          return "-";
        }
        return residents.map((resident) => resident.full_name).join(", ");
      },
    }),
    unitColumnHelps.accessor("pets", {
      id: "pets",
      header: "Pets",
      cell: (cell: CellContext<Unit, string[] | null>) => {
        const pets = cell.getValue();
        if (!pets || !pets.length) {
          return "-";
        }
        return pets.join(", ");
      },
    }),
    unitColumnHelps.accessor("available_date", {
      id: "available_date",
      header: "Available Date",
      cell: optionalDateCell,
    }),
    unitColumnHelps.accessor("next_move_in_date", {
      id: "next_move_in_date",
      header: "Move-In Date",
      cell: optionalDateCell,
    }),
    unitColumnHelps.accessor("move_out_date", {
      id: "move_out_date",
      header: "Move-Out Date",
      cell: optionalDateCell,
    }),
  ],
  staticColumns: ["unit_number", "occupancy_status", "floor_plan_name"],
  rowId: "id",
};

export { unitTableDefs };
