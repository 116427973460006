import { DefaultService, UserSession } from "api-client";
import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

interface AuthContextType {
  loading: boolean;
  user: UserSession | null;
}

const AuthContext = React.createContext<AuthContextType>({
  loading: true,
  user: null,
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authState, setAuthState] = React.useState<AuthContextType>({
    loading: true,
    user: null,
  });
  React.useEffect(() => {
    DefaultService.meApiMeGet()
      .then((resp) => {
        setAuthState({ user: resp, loading: false });
      })
      .catch(() => {
        setAuthState({ user: null, loading: false });
      });
  }, []);
  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

const AuthRequired = () => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.loading) {
    return null;
  }

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  return <Outlet />;
};

const AdminRequired = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  if (auth.loading) {
    return null;
  }

  if (!auth.user?.is_admin) {
    return <Navigate to="/" />;
  }

  return children;
};

export { AuthProvider, useAuth, AuthRequired, AdminRequired };
