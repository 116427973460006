import { Renewal, Task } from "api-client";
import { DrawerWorkItems } from "./drawer-work-items";
import {
  AssigneeDetail,
  ChargesDetail,
  LeaseSummaryDetails,
  MaintenanceSummaryDetail,
  PaymentSummaryDetail,
  PersonsDetail,
  PetsDetail,
  RenewalDetail,
  UnitDetail,
  VehiclesDetail,
} from "../ui/drawer-details";
import { DrawerHeaderContainer } from "./drawer-header-container";
import ActionBar from "./action-bar/action-bar";

export const RenwalDrawerContent = ({
  item,
  task,
  isLoading,
  onClickResident,
}: {
  task?: Partial<Task>;
  item?: Renewal;
  isLoading?: boolean;
  onClickResident?: (
    propertyId: string,
    residentId: string,
    name?: string | null
  ) => void;
}) => {
  return (
    <div className="flex flex-col">
      <DrawerHeaderContainer>
        <DrawerWorkItems task={task} isLoading={isLoading} />
        <ActionBar task={task} />
      </DrawerHeaderContainer>
      <div className="flex flex-col space-y-4 py-4 px-6">
        <AssigneeDetail
          isLoading={isLoading}
          label="Assigned to"
          value={task?.assigned_to}
          className="mb-4"
        />
        <hr />
        <UnitDetail
          label="Unit"
          value={task?.unit_summary}
          isLoading={isLoading}
        />
        <hr />
        <PersonsDetail
          label="Residents"
          value={task?.residents?.map((x) => {
            return {
              name: x.full_name,
              phone_number: x.phone_number,
              email_address: x.email_address,
              onClick: () => {
                if (x.id && task.property_id) {
                  onClickResident?.(task.property_id, x.id, x.full_name);
                }
              },
              // age: x.age,
            };
          })}
          isLoading={isLoading}
        />
        <hr />
        <RenewalDetail label="Renewal" value={item} isLoading={isLoading} />
        <hr />
        <LeaseSummaryDetails
          label="Current Lease"
          value={task?.lease_summary}
          isLoading={isLoading}
        />
        <hr />
        <PaymentSummaryDetail
          label="Payment history"
          value={item?.payment_summary}
          isLoading={isLoading}
        />
        <hr />
        <MaintenanceSummaryDetail
          label="Maintenance history"
          value={item?.maintenance_summary}
          isLoading={isLoading}
        />
        <hr />
        <ChargesDetail
          label="Charges"
          value={task?.lease_summary?.recurring_charges}
          isLoading={isLoading}
        />
        <hr />
        <PetsDetail label="Pets" value={task?.pets} isLoading={isLoading} />
        <hr />
        <VehiclesDetail
          label="Vehicles"
          value={task?.vehicles}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
