export const AskAnythingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
  >
    <g fill={props.fill}>
      <path d="m7.693 4.755.1.05c.404.208.733.537.941.942l.05.1c.2.395.767.395.971 0l.05-.1c.209-.405.538-.734.942-.942l.1-.05a.545.545 0 0 0 0-.97l-.1-.051a2.2 2.2 0 0 1-.942-.942l-.05-.1a.545.545 0 0 0-.97 0l-.05.1a2.2 2.2 0 0 1-.942.942l-.1.05a.545.545 0 0 0 0 .97M8.48 8.66a.558.558 0 0 0 0-.997l-.608-.312a2.93 2.93 0 0 1-1.263-1.263l-.312-.608a.558.558 0 0 0-.996 0l-.312.608c-.28.542-.721.984-1.263 1.263l-.608.312a.558.558 0 0 0 0 .996l.608.313c.542.279.983.72 1.263 1.262l.312.608a.558.558 0 0 0 .996 0l.312-.608c.28-.542.721-.983 1.263-1.262zM16.297 11.15l-.617-.316a3.9 3.9 0 0 1-1.72-1.72l-.317-.617a1.62 1.62 0 0 0-1.455-.892c-.616 0-1.174.342-1.454.892l-.316.616a3.9 3.9 0 0 1-1.721 1.721l-.617.317c-.55.279-.891.837-.891 1.454s.341 1.175.891 1.454l.617.317c.746.379 1.342.975 1.72 1.72l.317.617c.28.55.838.892 1.454.892.617 0 1.175-.342 1.455-.892l.316-.616a3.9 3.9 0 0 1 1.721-1.721l.617-.317c.55-.28.892-.837.892-1.454s-.342-1.175-.892-1.454m-.567 1.797-.617.316a5.14 5.14 0 0 0-2.266 2.267l-.317.617a.38.38 0 0 1-.341.208.38.38 0 0 1-.342-.208l-.317-.617a5.14 5.14 0 0 0-2.266-2.267l-.617-.316a.38.38 0 0 1-.208-.342c0-.071.02-.246.208-.342l.617-.316A5.14 5.14 0 0 0 11.53 9.68l.317-.617a.38.38 0 0 1 .341-.208c.071 0 .246.02.342.208l.317.617a5.14 5.14 0 0 0 2.267 2.267l.616.316c.188.096.208.271.208.342 0 .07-.02.246-.208.341" />
    </g>
  </svg>
);
