import { LogoIcon } from "../components/ui/icons/logo-icon";

const LoginSuccess = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center relative z-10">
        <div className="mb-8">
          <LogoIcon height={96} width={96} />
        </div>
        <div className="mb-8">
          <h2 className="text-3xl">You're Signed In!</h2>
        </div>
        <div className="text-center max-w-xs">
          <p>
            Now you can click on the "Ask" tab in any web page and get instant
            access to property information.
          </p>
        </div>
      </div>
    </>
  );
};

export { LoginSuccess };
