import { differenceInMonths, differenceInYears, addYears, differenceInDays } from 'date-fns';
import { ApplicationDetails } from "./application";
import {
  surface_core__ask__entitites__askentities__Application as Application,
  Data,
  FloorPlan,
  Lease,
  surface_core__ask__entitites__askentities__MoveIn as MoveIn,
  surface_core__ask__entitites__askentities__MoveOut as MoveOut,
  Policy,
  Resident360,
  ResidentActivity,
  Unit,
} from "api-client";
import { ResidentDetails } from "@/components/resident-details/drawer";
import { UnitDetails } from "./unit";
import { PolicyDetails } from "./policy";
import { humanize } from "../../lib/utils";
import { LeaseDetails } from "./lease";
import { MoveInDetails } from "./move-in";
import { MoveOutDetails } from "./move-out";
import { FloorPlanDetails } from "./floor-plan";

type SourceEntity = Data["items"][0];

const getEntityName = (item: SourceEntity) => {
  switch (item.type) {
    case "Application":
      return (item as Application).applicant_full_name;
    case "Resident360":
    case "Resident":
      return (item as Resident360).full_name;
    case "Unit":
      return (item as Unit).unit_number;
    case "Lease":
      return (item as Lease).id;
    case "Policy":
      return humanize((item as Policy).name);
    case "FloorPlan":
      return (item as FloorPlan).floor_plan_name;
    case "MoveIn":
      return (item as MoveIn).unit_number;
    case "MoveOut":
      return (item as MoveOut).unit_number;
    default:
      console.error("Unknown item type", item);
      return null;
  }
};

const getEntityDetails = (
  dialogueId: string | null | undefined,
  sourceId: string | null | undefined,
  item: SourceEntity
) => {
  switch (item.type) {
    case "Application":
      return <ApplicationDetails item={item as Application} />;
    case "Resident360":
    case "Resident":
      return <ResidentDetails item={item as Resident360} />;
    case "Unit":
      return <UnitDetails item={item as Unit} />;
    case "Lease":
      return <LeaseDetails item={item as Lease} />;
    case "Policy":
      return (
        <PolicyDetails
          dialogueId={dialogueId}
          sourceId={sourceId}
          item={item as Policy}
        />
      );
    case "FloorPlan":
      return <FloorPlanDetails item={item as FloorPlan} />;
    case "MoveIn":
      return <MoveInDetails item={item as MoveIn} />;
    case "MoveOut":
      return <MoveOutDetails item={item as MoveOut} />;
    case "Task":
      return null;
    default:
      console.error("Unknown item type", item);
      return null;
  }
};

const getDrawerTitle = (item: SourceEntity) => {
  switch (item.type) {
    case "Resident360":
      return "Resident";
    case "Policy":
      return "Policies";
    case "MoveIn":
      return "Move-In";
    case "MoveOut":
      return "Move-Out";
    case "FloorPlan":
      return "Floor Plan";
    default:
      return item.type;
  }
};

const getTimePeriod = (startDate?: string | Date | null, endDate?: string | Date | null) => {
  if (!startDate || !endDate) {
      return '';
  }

  const yearsDifference = differenceInYears(endDate, startDate);

  if (yearsDifference >= 1) {
      // Calculate remaining months after the full years
      const adjustedDate = addYears(startDate, yearsDifference);
      const monthsDifference = differenceInMonths(endDate, adjustedDate);
      
      if (monthsDifference === 0) {
          return yearsDifference === 1 ? `${yearsDifference} year` : `${yearsDifference} years`;
      } else {
          return `${yearsDifference} year${yearsDifference > 1 ? 's' : ''} ${monthsDifference} month${monthsDifference > 1 ? 's' : ''}`;
      }
  }

  // If less than a year, calculate months
  const monthsDifference = differenceInMonths(endDate, startDate);

  if (monthsDifference >= 1) {
      return monthsDifference === 1 ? `${monthsDifference} month` : `${monthsDifference} months`;
  }

  // If less than a month, calculate days
  const daysDifference = differenceInDays(endDate, startDate);
  return daysDifference === 1 ? `${daysDifference} day` : `${daysDifference} days`;
}

const getActivityIcon = (item: ResidentActivity) => {
  const hasKeywords = (keywords: string[]) =>
    keywords.some((keyword) => item.activity_type.includes(keyword)); 

  if (hasKeywords(["Email", "Mail", "Receipt", "Sent"])) {
    return "mail";
  }

  if (hasKeywords(["Phone", "Call"])) {
    return "phone";
  }

  if (hasKeywords(["Text", "SMS"])) {
    return "mobile";
  }

  if (hasKeywords(["Payment"])) {
    return "billing";
  }

  if (hasKeywords(["Work Order", "Inspection"])) {
    return "mechanical";
  }

  return "document";
};

export { getEntityName, getEntityDetails, getDrawerTitle, getActivityIcon, getTimePeriod };
