interface Config {
  configCatSdkKey: string;
  gaId: string;
  mode: string;
  sentryDsn: string;
}

export function getConfig(): Config {
  const { VITE_CONFIGCAT_SDK_KEY, VITE_SENTRY_DSN, VITE_GA_ID, MODE } =
    import.meta.env;
  return {
    configCatSdkKey: VITE_CONFIGCAT_SDK_KEY || "",
    gaId: VITE_GA_ID || "",
    mode: MODE,
    sentryDsn: VITE_SENTRY_DSN || "",
  };
}
