import { Lease } from "api-client";
import {
  DateDetail,
  PersonsDetail,
  Detail,
  UnitDetail,
  PetsDetail,
  VehiclesDetail,
} from "../ui/drawer-details";

interface LeaseDetailsProps {
  item: Lease;
}

const LeaseDetails = ({ item }: LeaseDetailsProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <UnitDetail
        label="Unit"
        value={{
          unit_number: item.unit_number || "",
          square_feet: item.square_footage,
          num_bedrooms: item.number_of_bedrooms,
          num_bathrooms: item.number_of_bathrooms,
          floor_plan: item.floor_plan_name,
        }}
      />
      <hr />
      <DateDetail label="Move-in date" value={item.move_in_date} />
      <hr />
      <DateDetail label="Move-out date" value={item.move_out_date} />
      <hr />
      <DateDetail label="Lease Start Date" value={item.lease_start_date} />
      <hr />
      <DateDetail label="Lease End Date" value={item.lease_end_date} />
      <hr />
      <Detail label="Commitment Type" value={item.commitment_type} />
      <hr />
      <Detail label="Departure Intent" value={item.departure_intent} />
      <hr />
      <DateDetail label="Approved Date" value={item.approved_date} />
      <hr />
      <DateDetail label="Signed Date" value={item.signed_date} />
      <hr />
      <DateDetail
        label="Counter-signed Date"
        value={item.counter_signed_date}
      />
      <hr />
      <PersonsDetail
        label="Residents"
        value={[
          {
            name: item.primary_resident?.full_name,
          },
          ...(item.co_residents || [])
            .concat(item.other_residents || [])
            .map((x) => {
              return {
                name: x.full_name,
                is_minor: x.is_minor,
              };
            }),
        ]}
      />
      <hr />
      <PetsDetail label="Pets" value={item.pets} />
      <hr />
      <VehiclesDetail label="Vehicles" value={item.vehicles} />
      <hr />
      <Detail label="Property" value={item.property_name} />
      <hr />
      <Detail label="Building" value={item.building_name} />
      <hr />
    </div>
  );
};

export { LeaseDetails };
