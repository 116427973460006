import { SaasTenantSelect } from "@/components/ui/saas-tenant-select";
import { useAuth } from "@/lib/auth";
import { Outlet } from "react-router-dom";
import LogoIconGrey from "../assets/img/logo-icon-grey.svg";
import { StatefulNavButton } from "@/components/ui/nav-button";

const Admin = () => {
  const { user } = useAuth();
  return (
    <div className="flex w-full bg-muted">
      <div className="w-[300px] border-r">
        <div className="py-4 px-8 border-b text-xl text-foreground h-[64px] flex items-center">
          <div className="flex space-x-2">
            <img src={LogoIconGrey} />
            {user && <SaasTenantSelect user={user} />}
          </div>
        </div>
        <div className="flex flex-col py-4 px-2 space-y-1">
          <StatefulNavButton to="/admin/users" end>
            Users
          </StatefulNavButton>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export { Admin };
