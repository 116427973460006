import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * Me
     * @returns UserSession Successful Response
     * @throws ApiError
     */
    static meApiMeGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/me',
        });
    }
    /**
     * Update Me
     * @param requestBody
     * @returns UserSession Successful Response
     * @throws ApiError
     */
    static updateMeApiMePatch(requestBody) {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    static logoutApiLogoutGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/logout',
        });
    }
    /**
     * Login Microsoft
     * @param scope
     * @param context
     * @returns any Successful Response
     * @throws ApiError
     */
    static loginMicrosoftApiLoginMicrosoftGet(scope, context) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/login/microsoft',
            query: {
                'scope': scope,
                'context': context,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Auth Microsoft
     * Authenticate the user
     * @returns any Successful Response
     * @throws ApiError
     */
    static authMicrosoftApiAuthMicrosoftGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/microsoft',
        });
    }
    /**
     * Login Google
     * @param scope
     * @param context
     * @returns any Successful Response
     * @throws ApiError
     */
    static loginGoogleApiLoginGoogleGet(scope, context) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/login/google',
            query: {
                'scope': scope,
                'context': context,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Auth Google
     * Authenticate the user
     * @returns any Successful Response
     * @throws ApiError
     */
    static authGoogleApiAuthGoogleGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/google',
        });
    }
    /**
     * Create Saas Tenant
     * @param requestBody
     * @returns SaasTenant Successful Response
     * @throws ApiError
     */
    static createSaasTenantApiAdminSaasTenantsPost(requestBody) {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/saas_tenants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Saas Tenant
     * @param saasTenantId
     * @returns SaasTenant Successful Response
     * @throws ApiError
     */
    static getSaasTenantApiAdminSaasTenantsSaasTenantIdGet(saasTenantId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/saas_tenants/{saas_tenant_id}',
            path: {
                'saas_tenant_id': saasTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Dialogue As Admin
     * @param dialogueId
     * @param saasTenantId
     * @returns any Successful Response
     * @throws ApiError
     */
    static getDialogueAsAdminApiAdminDialoguesDialogueIdGet(dialogueId, saasTenantId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/dialogues/{dialogue_id}',
            path: {
                'dialogue_id': dialogueId,
            },
            query: {
                'saas_tenant_id': saasTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Search Dialogues
     * @param saasTenantId
     * @param query
     * @param userId
     * @param maxResults
     * @returns any Successful Response
     * @throws ApiError
     */
    static searchDialoguesApiAdminDialoguesSearchPost(saasTenantId, query, userId, maxResults) {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/dialogues/search',
            query: {
                'saas_tenant_id': saasTenantId,
                'query': query,
                'user_id': userId,
                'max_results': maxResults,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create User
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    static createUserApiAdminUsersPost(requestBody) {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Users
     * @param saasTenantId
     * @returns User Successful Response
     * @throws ApiError
     */
    static getUsersApiAdminUsersGet(saasTenantId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/users',
            query: {
                'saas_tenant_id': saasTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update User
     * @param userId
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    static updateUserApiAdminUsersUserIdPut(userId, requestBody) {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User
     * @param userId
     * @returns User Successful Response
     * @throws ApiError
     */
    static getUserApiAdminUsersUserIdGet(userId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Property
     * @param requestBody
     * @returns Property Successful Response
     * @throws ApiError
     */
    static createPropertyApiAdminPropertiesPost(requestBody) {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/properties',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Properties
     * @param saasTenantId
     * @returns Property Successful Response
     * @throws ApiError
     */
    static getPropertiesApiAdminPropertiesGet(saasTenantId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/properties',
            query: {
                'saas_tenant_id': saasTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Core Properties
     * @param saasTenantId
     * @returns any Successful Response
     * @throws ApiError
     */
    static getCorePropertiesApiAdminCorePropertiesGet(saasTenantId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/core-properties',
            query: {
                'saas_tenant_id': saasTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Roles
     * @returns UserRoleEnum Successful Response
     * @throws ApiError
     */
    static getUserRolesApiAdminPropertyRolesGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/property-roles',
        });
    }
    /**
     * Get Infra Config
     * @param saasTenantId
     * @param deploymentId
     * @returns any Successful Response
     * @throws ApiError
     */
    static getInfraConfigApiAdminInfraConfigGet(saasTenantId, deploymentId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/infra/config',
            query: {
                'saas_tenant_id': saasTenantId,
                'deployment_id': deploymentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get App By Id
     * @param saasTenantId
     * @param propertyId
     * @param applicationId
     * @returns any Successful Response
     * @throws ApiError
     */
    static getAppByIdApiCoreApplicationsGet(saasTenantId, propertyId, applicationId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/core/applications',
            query: {
                'saas_tenant_id': saasTenantId,
                'property_id': propertyId,
                'application_id': applicationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Unit By Number
     * @param saasTenantId
     * @param propertyId
     * @param unitNumber
     * @returns any Successful Response
     * @throws ApiError
     */
    static getUnitByNumberApiCoreUnitsGet(saasTenantId, propertyId, unitNumber) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/core/units',
            query: {
                'saas_tenant_id': saasTenantId,
                'property_id': propertyId,
                'unit_number': unitNumber,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Entrata Doc By Id
     * @param saasTenantId
     * @param propertyId
     * @param serviceEndpoint
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    static getEntrataDocByIdApiFetchEntratadocsGet(saasTenantId, propertyId, serviceEndpoint, name) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/fetch/entratadocs',
            query: {
                'saas_tenant_id': saasTenantId,
                'property_id': propertyId,
                'service_endpoint': serviceEndpoint,
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Eval Dialogues
     * @param saasTenantId
     * @param requestBody
     * @returns DialogueEvalResult Successful Response
     * @throws ApiError
     */
    static evalDialoguesApiAdminEvalDialoguesPost(saasTenantId, requestBody) {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/eval-dialogues',
            query: {
                'saas_tenant_id': saasTenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Greeting
     * @returns any Successful Response
     * @throws ApiError
     */
    static greetingApiGreetingGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greeting',
        });
    }
    /**
     * Suggestions
     * @returns any Successful Response
     * @throws ApiError
     */
    static suggestionsApiSuggestionsGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/suggestions',
        });
    }
    /**
     * Askhistory
     * @param continuationToken
     * @param maxSources
     * @param maxSourceItems
     * @returns DialogueHistory Successful Response
     * @throws ApiError
     */
    static askHistoryApiAskHistoryGet(continuationToken, maxSources, maxSourceItems) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ask/history',
            query: {
                'continuation_token': continuationToken,
                'max_sources': maxSources,
                'max_source_items': maxSourceItems,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ask
     * @param requestBody
     * @param respondSync
     * @returns Dialogue Successful Response
     * @throws ApiError
     */
    static askApiAskPost(requestBody, respondSync) {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ask',
            query: {
                'respond_sync': respondSync,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Dialogue
     * @param dialogueId
     * @param chunkId
     * @param maxSources
     * @param maxSourceItems
     * @returns Dialogue Successful Response
     * @throws ApiError
     */
    static getDialogueApiAskDialogueIdGet(dialogueId, chunkId, maxSources, maxSourceItems) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ask/{dialogue_id}',
            path: {
                'dialogue_id': dialogueId,
            },
            query: {
                'chunk_id': chunkId,
                'max_sources': maxSources,
                'max_source_items': maxSourceItems,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Cancel Ask
     * @param dialogueId
     * @returns void
     * @throws ApiError
     */
    static cancelAskApiAskDialogueIdCancelPut(dialogueId) {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/ask/{dialogue_id}/cancel',
            path: {
                'dialogue_id': dialogueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ask Sources
     * @param dialogueId
     * @returns any Successful Response
     * @throws ApiError
     */
    static askSourcesApiAskDialogueIdSourcesGet(dialogueId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ask/{dialogue_id}/sources',
            path: {
                'dialogue_id': dialogueId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ask Source
     * @param dialogueId
     * @param sourceId
     * @returns any Successful Response
     * @throws ApiError
     */
    static askSourceApiAskDialogueIdSourcesSourceIdGet(dialogueId, sourceId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ask/{dialogue_id}/sources/{source_id}',
            path: {
                'dialogue_id': dialogueId,
                'source_id': sourceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Ask Source Items
     * @param dialogueId
     * @param sourceId
     * @param limit
     * @param offset
     * @returns SourceItemListResponse Successful Response
     * @throws ApiError
     */
    static askSourceItemsApiAskDialogueIdSourcesSourceIdItemsGet(dialogueId, sourceId, limit, offset) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ask/{dialogue_id}/sources/{source_id}/items',
            path: {
                'dialogue_id': dialogueId,
                'source_id': sourceId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Save Feedback
     * @param dialogueId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    static saveFeedbackApiAskDialogueIdFeedbackPost(dialogueId, requestBody) {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ask/{dialogue_id}/feedback',
            path: {
                'dialogue_id': dialogueId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Daily Report
     * @param dailyInterval
     * @returns any Successful Response
     * @throws ApiError
     */
    static dailyReportApiAskUsagereportGet(dailyInterval) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/ask/usagereport/',
            query: {
                'daily_interval': dailyInterval,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Sms
     * @returns any Successful Response
     * @throws ApiError
     */
    static smsApiSmsInboundPost() {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sms-inbound',
        });
    }
    /**
     * Tasks
     * @param propertyIds
     * @param taskStatus
     * @param taskType
     * @param priorityGroup
     * @param assignedTo
     * @param offset
     * @param limit
     * @param sort
     * @returns TaskSummaryListResponse Successful Response
     * @throws ApiError
     */
    static tasksApiWorkspaceTasksGet(propertyIds, taskStatus, taskType, priorityGroup, assignedTo, offset, limit = 5, sort) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workspace/tasks',
            query: {
                'property_ids': propertyIds,
                'task_status': taskStatus,
                'task_type': taskType,
                'priority_group': priorityGroup,
                'assigned_to': assignedTo,
                'offset': offset,
                'limit': limit,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Tasks Export
     * @param propertyIds
     * @param taskStatus
     * @param taskType
     * @param priorityGroup
     * @param assignedTo
     * @param sort
     * @returns TaskSummaryListResponse Successful Response
     * @throws ApiError
     */
    static tasksExportApiWorkspaceTasksExportGet(propertyIds, taskStatus, taskType, priorityGroup, assignedTo, sort) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workspace/tasks/export',
            query: {
                'property_ids': propertyIds,
                'task_status': taskStatus,
                'task_type': taskType,
                'priority_group': priorityGroup,
                'assigned_to': assignedTo,
                'sort': sort,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Tasks Facets
     * @param propertyIds
     * @param taskStatus
     * @param taskType
     * @returns any Successful Response
     * @throws ApiError
     */
    static tasksFacetsApiWorkspaceTasksFacetsGet(propertyIds, taskStatus, taskType) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workspace/tasks/facets',
            query: {
                'property_ids': propertyIds,
                'task_status': taskStatus,
                'task_type': taskType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Task
     * @param propertyId
     * @param id
     * @returns Task Successful Response
     * @throws ApiError
     */
    static getTaskApiWorkspacePropertyIdTasksIdGet(propertyId, id) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workspace/{property_id}/tasks/{id}',
            path: {
                'property_id': propertyId,
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Eval Tasks For Temporal Updates
     * @param saasTenantId
     * @param propertyId
     * @param taskType
     * @returns any Successful Response
     * @throws ApiError
     */
    static evalTasksForTemporalUpdatesApiWorkspaceSaasTenantIdEvalTasksForTemporalUpdatesGet(saasTenantId, propertyId, taskType) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workspace/{saas_tenant_id}/eval_tasks_for_temporal_updates',
            path: {
                'saas_tenant_id': saasTenantId,
            },
            query: {
                'property_id': propertyId,
                'task_type': taskType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Document
     * @param externalDocId
     * @returns any Successful Response
     * @throws ApiError
     */
    static getDocumentApiWorkspaceDocumentsExternalDocIdGet(externalDocId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workspace/documents/{external_doc_id}',
            path: {
                'external_doc_id': externalDocId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Resident List
     * @param propertyId
     * @returns ResidentIndex Successful Response
     * @throws ApiError
     */
    static getResidentListApiPropertiesPropertyIdResidentListGet(propertyId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/properties/{property_id}/resident-list',
            path: {
                'property_id': propertyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Resident
     * @param propertyId
     * @param residentId
     * @returns Resident360 Successful Response
     * @throws ApiError
     */
    static getResidentApiPropertiesPropertyIdResidentsResidentIdGet(propertyId, residentId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/properties/{property_id}/residents/{resident_id}',
            path: {
                'property_id': propertyId,
                'resident_id': residentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Work Order Summaries For Resident
     * @param propertyId
     * @param residentId
     * @returns WorkOrderSummary Successful Response
     * @throws ApiError
     */
    static getWorkOrderSummariesForResidentApiPropertiesPropertyIdResidentsResidentIdWorkordersGet(propertyId, residentId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/properties/{property_id}/residents/{resident_id}/workorders',
            path: {
                'property_id': propertyId,
                'resident_id': residentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Possible Repeat Work Order Summaries
     * @param propertyId
     * @param workOrderId
     * @returns WorkOrderSummary Successful Response
     * @throws ApiError
     */
    static getPossibleRepeatWorkOrderSummariesApiPropertiesPropertyIdWorkordersWorkOrderIdPossibleRepeatWorkordersGet(propertyId, workOrderId) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/properties/{property_id}/workorders/{work_order_id}/possible_repeat_workorders',
            path: {
                'property_id': propertyId,
                'work_order_id': workOrderId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Root
     * @returns any Successful Response
     * @throws ApiError
     */
    static rootApiCheckGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/check',
        });
    }
    /**
     * Microsoft Identity Association
     * @returns any Successful Response
     * @throws ApiError
     */
    static microsoftIdentityAssociationWellKnownMicrosoftIdentityAssociationJsonGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/.well-known/microsoft-identity-association.json',
        });
    }
    /**
     * Storybook
     * @returns any Successful Response
     * @throws ApiError
     */
    static storybookStorybookGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/storybook',
        });
    }
    /**
     * Extension
     * @returns any Successful Response
     * @throws ApiError
     */
    static extensionExtensionGet() {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/extension',
        });
    }
    /**
     * Fallback
     * @param fallbackPath
     * @returns any Successful Response
     * @throws ApiError
     */
    static fallbackFallbackPathGet(fallbackPath) {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{fallback_path}',
            path: {
                'fallback_path': fallbackPath,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
