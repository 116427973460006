import "../styleguide.css";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { AuthRequired } from "./lib/auth";
import { Login } from "./pages/login";
import { Admin } from "./pages/admin";
import { Download } from "./pages/download";
import { DefaultService } from "./api/client";
import { DialogueDetails } from "./pages/dialogue-details";
import { AdminRequired, AuthProvider } from "./lib/auth";
import { Priorities } from "./pages/priorities";
import { AppPage } from "./pages/app-page";
import { Ask } from "./pages/ask";
import { Users } from "./pages/users";
import { LoginSuccess } from "./pages/login-success";
import { RecoilRoot } from "recoil";
import { Toaster } from "./components/ui/toaster";
import { NewUser } from "./pages/new-user";
import { UserOnboarding } from "./pages/user-onboarding";
import ReactGA from "react-ga4";


export const routerConfig: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to="/ask" replace />, // Redirect "/" to "/ask"
  },
  {
    path: "/",
    element: <AuthRequired />,
    children: [
      {
        path: "/",
        element: <AppPage />,
        children: [
          {
            path: "/ask",
            element: <Ask />,
          },
          {
            path: "/ask/question/:dialogueId",
            element: <DialogueDetails />,
            loader: async ({ params }) => {
              const dialogue = await DefaultService.getDialogueApiAskDialogueIdGet(
                params.dialogueId,
                null,
                null,
                0
              );
              return { dialogue };
            },
          },
          {
            path: "/priorities/:taskType?",
            element: <Priorities />,
          },
        ]
      },

    ],
  },
  {
    path: "/admin/",
    element: (
      <AdminRequired>
        <Admin />
      </AdminRequired>
    ),
    children: [
      { path: "tenants", element: <div>Tenants</div> },
      {
        path: "users",
        element: <Users />,
      },
    ],
  },
  {
    path: "/download",
    element: <Download />,
  },
  { path: "/login", element: <Login /> },
  { path: "/login-success", element: <LoginSuccess /> },
  { path: "/new-user", element: <NewUser /> },
  { path: "/user-onboarding", element: <UserOnboarding /> },
];

const router = createBrowserRouter(routerConfig);

router.subscribe(({ location }) => {
  ReactGA.send({
    hitType: "pageview",
    page: location.pathname + location.search,
  });
});

function App() {
  return (
    <RecoilRoot>
      <div className="flex justify-center min-h-screen max-h-full relative">
        <Toaster />
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </div>
    </RecoilRoot>
  );
}

export default App;
