import { FloorPlan } from "api-client";
import { Detail } from "../ui/drawer-details";

interface FloorPlanDetailsProps {
  item: FloorPlan;
}

const FloorPlanDetails = ({ item }: FloorPlanDetailsProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <Detail label="Bedrooms" value={item.number_of_bedrooms} />
      <hr />
      <Detail label="Bathrooms" value={item.number_of_bathrooms} />
      <hr />
      <Detail label="Square Footage" value={item.square_footage} />
    </div>
  );
};

export { FloorPlanDetails };
