import { Button, Icon } from "surface-components";
import { extensionManager } from "@/lib/extension-manager";
import { Task } from "@/api/client";

/**
 * Task types that require extension for deeplinking:
 * - ApplicationTask
 * - ServiceRequestTask
 * - MoveInTask
 *
 * Task types that support direct navigation:
 * - MoveOutTask
 * - RenewalTask
 * Note: These may still require the extension for additional functionality
 */

const canNativelyDeeplink = (taskType?: string) => {
  if (!taskType) return false;
  return ["MoveOutTask", "RenewalTask"].includes(taskType);
};

const buildNativeDeeplink = (
  baseUrl: string,
  leaseId: string,
  customerId: string
) => {
  return `${baseUrl}/?module=customers_systemxxx&lease[lease_id]=${leaseId}&lease[customer_id]=${customerId}`;
};

const handleNativeDeeplink = (task: Partial<Task>) => {
  // @ts-expect-error entitySource is not built yet
  const leaseId = task.entitySource?.sourceIds?.lease_id || "15699549";
  // @ts-expect-error entitySource is not built yet
  const customerId = task.entitySource?.sourceIds?.customer_id || "32984004";
  // @TODO: Read this from task entitySource
  const entrataBaseUrl = "https://lantowerluxuryliving.entrata.com";
  const url = buildNativeDeeplink(entrataBaseUrl, leaseId, customerId);
  window.open(url, "_blank");
};

const handleExtensionDeeplink = (task: Partial<Task>) => {
  window.dispatchEvent(
    new CustomEvent("RequestFromPage", {
      detail: {
        action: "openDeeplink",
        // @ts-expect-error entitySource is not built yet
        source: task?.entitySource,
      },
    })
  );
};

export const EntrataDeeplink = ({
  task,
  text = "View in Entrata",
}: {
  task: Partial<Task>;
  text?: string;
}) => {
  const extensionDetected = extensionManager.isInstalled();
  const useNativeDeeplink = canNativelyDeeplink(task.type);

  const handleClick = () => {
    if (useNativeDeeplink) {
      handleNativeDeeplink(task);
    } else if (extensionDetected) {
      handleExtensionDeeplink(task);
    }
  };

  return (
    <Button onClick={handleClick} variant="outline" className="flex space-x-1">
      {text}
      <Icon className="ml-2" icon="external-link" />
    </Button>
  );
};
