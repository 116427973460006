import { Login } from "./login";
import MockExtension from "@/assets/img/mock-extension.svg";

const NewUser = () => {
  return (
    <div className="w-full flex">
      <div className="bg-primaryGradientBlueTeal flex flex-grow  flex-col h-screen overflow-hidden">
        <h3 className="text-3xl font-medium text-white p-8 animate-fade-in-up">
          Get ready to ask anything about property
        </h3>

        <img
          src={MockExtension}
          className="h-5/6 self-end animate-fade-in-up-slow"
        />
      </div>
      <div className="flex-grow flex flex-col items-center justify-center shadow-lg">
        <Login inlineContext="newUser" />
      </div>
    </div>
  );
};

export { NewUser };
