import { Badge, Icon } from "surface-components";
import { LeaseAuditCheck } from "api-client";
import { statusFormatter } from "./utils";
import { formatCurrency } from "../../../lib/utils";

export const LeaseAuditCharges = ({
  checks,
}: {
  checks?: LeaseAuditCheck[];
}) => {
  const categorizeChecks = (
    checks: LeaseAuditCheck[] | undefined
  ): {
    residentsMatch: LeaseAuditCheck[];
    leaseDatesMatch: LeaseAuditCheck[];
    chargesMatch: LeaseAuditCheck[];
  } => {
    const categories: {
      residentsMatch: LeaseAuditCheck[];
      leaseDatesMatch: LeaseAuditCheck[];
      chargesMatch: LeaseAuditCheck[];
    } = {
      residentsMatch: [],
      leaseDatesMatch: [],
      chargesMatch: [],
    };

    if (!checks) return categories;

    return checks.reduce((acc, check) => {
      switch (check.check_type) {
        case "Residents Match":
          acc.residentsMatch.push(check);
          break;
        case "Lease Dates Match":
          acc.leaseDatesMatch.push(check);
          break;
        case "Charges Match":
          acc.chargesMatch.push(check);
          break;
        default:
          console.warn(`Unknown check type: ${check.check_type}`);
      }
      return acc;
    }, categories);
  };

  const categorizedChecks = categorizeChecks(checks);

  return (
    <div>
      <div className="border-b pb-2">
        <div className="flex justify-between items-center">
          <h2>Rent Roll</h2>
          <h2>Lease documents</h2>
        </div>
      </div>
      <div className="py-4">
        <div className="space-y-4">
          {categorizedChecks.leaseDatesMatch.length > 0 && (
            <div>
              <h3 className="text-sm font-medium mb-2">Lease Dates</h3>
              {categorizedChecks.leaseDatesMatch.map((check, index) => (
                <AuditItem
                  key={index}
                  item={{
                    status: check.initial_result || "",
                    description: check.value_label || "Lease term",
                    value: check.rent_roll_value || "",
                    documentValue: check.lease_document_value || "",
                  }}
                />
              ))}
            </div>
          )}
          {categorizedChecks.chargesMatch.length > 0 && (
            <div>
              <h3 className="text-sm font-medium mb-2">Charges</h3>
              {categorizedChecks.chargesMatch.map((check, index) => (
                <AuditItem
                  key={index}
                  item={{
                    status: check.initial_result || "",
                    description: check.value_label || "Charge",
                    value:
                      (check.rent_roll_value &&
                        formatCurrency(Number(check.rent_roll_value))) ||
                      "",
                    documentValue: check.lease_document_value
                      ? formatCurrency(Number(check.lease_document_value))
                      : "",
                  }}
                />
              ))}
            </div>
          )}

          {categorizedChecks.residentsMatch.length > 0 && (
            <div>
              <h3 className="text-sm font-medium mb-2">Residents</h3>
              {categorizedChecks.residentsMatch.map((check, index) => (
                <AuditItem
                  key={index}
                  item={{
                    status: check.initial_result || "",
                    description: "Resident",
                    value: check.rent_roll_value || "",
                    documentValue: check.lease_document_value || "",
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type AuditItem = {
  status: string;
  description: string;
  value: string;
  documentValue: string;
};

const AuditItem = ({ item }: { item: AuditItem }) => {
  return (
    <div className="flex justify-between items-center py-1">
      <div className="flex items-center space-x-2">
        <Badge
          variant="secondary"
          className={
            item.status === "Passed"
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          }
        >
          {statusFormatter(item.status)}
        </Badge>
        <span>
          {item.description}: {item.value}
        </span>
      </div>
      <div className="flex items-center space-x-2">
        <Icon icon="document" />
        <span>{item.documentValue}</span>
        <Icon icon="external-link" />
      </div>
    </div>
  );
};
