import { UpperNav } from "../components/ui/upper-nav";
import { useRecoilValue } from "recoil";
import { selectedSavedDialogueState } from "@/lib/recoil/state";
import SurfaceAskContent from "@/components/ask/surface-ask-content";
import { AskInput } from "@/components/ask/input/ask-input";
import {
  Icon,
  HoverCard,
  Button,
  HoverCardTrigger,
  HoverCardContent,
} from "surface-components";
import { DefaultService } from "@/api/client";
import { useNavigate } from "react-router-dom";

const Ask = () => {
  const navigate = useNavigate();
  const handleLogoutClick = async () => {
    await DefaultService.logoutApiLogoutGet();
    return navigate("/login");
  };
  const activeSavedDialogue = useRecoilValue(selectedSavedDialogueState);
  return (
    // 100% - 300 is the width of the left nav
    <div className="w-[calc(100%-300px)] h-full">
      <div>
        <UpperNav
          className="bg-white bg-opacity-30 backdrop-blur-lg z-40 sticky"
          left={
            <div className="flex space-x-2">
              {activeSavedDialogue?.dialogueId ? (
                <span className="text-foreground">
                  Ask · <span className="text-muted-foreground">Question</span>
                </span>
              ) : (
                <span className="text-foreground">Ask Anything</span>
              )}
            </div>
          }
          right={
            <div className="flex items-center space-x-2 text-muted-foreground">
              <HoverCard>
                <HoverCardTrigger>
                  <Button variant="outline">
                    <Icon icon="person" />
                  </Button>
                </HoverCardTrigger>
                <HoverCardContent className="bg-white rounded-2xl p-1 border-solid border border-border shadow-md w-32 mx-2">
                  <Button
                    onClick={handleLogoutClick}
                    variant="ghost"
                    size="sm"
                    className="w-full"
                  >
                    Logout
                  </Button>
                </HoverCardContent>
              </HoverCard>
            </div>
          }
        />
      </div>
      <div className="absolute top-8 h-[calc(100%-32px)] w-[calc(100%-300px)] flex flex-col border-l border-border border-solid">
        <div className="overflow-y-auto grid place-items-center flex-1 scrollbar-hide">
          <div className="max-w-screen-lg">
            <SurfaceAskContent />
          </div>
        </div>
        <div className="mb-4 mx-2">
          <AskInput placeholder="Ask anything" />
        </div>
      </div>
    </div>
  );
};

export { Ask };
