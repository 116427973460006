import { Button } from "surface-components";
import { LogoIcon } from "../components/ui/icons/logo-icon";
import { API_ROOT } from "../constants";

const Download = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-8">
        <LogoIcon height={96} width={96} />
      </div>
      <div className="mb-8">
        <h2 className="text-3xl">Download Surface Ask</h2>
      </div>
      <div className="flex flex-col mb-4">
        <div className="mb-4 w-full">
          <Button className="w-full">
            <a
              href={`${API_ROOT}/extension/assets/download.zip`}
              download="surface-extension.zip"
            >
              Download extension as Zip
            </a>
          </Button>
        </div>
      </div>
      <div className="text-center max-w-xs">
        <p>By downloading, you agree to our</p>
        <p>
          {" "}
          <a className="text-primary font-bold" href="#">
            Terms of Use
          </a>{" "}
          and{" "}
          <a className="text-primary font-bold" href="#">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export { Download };
