import { atom, AtomEffect } from "recoil";
import { localStorageService } from "./local-storage-service";
import { storageService as chromeStorageService } from "./chrome-storage-service";
import { AnswerSource, AnswerStreamChunk, Dialogue } from "@/api/client";
import { combinedStorageService } from "./combined-storage-service";

// Conflict resolution function
export const checkAndResolveConflict = async (key: string, env: string) => {
  const compositeKey = `${env}_${key}`;
  const chromeResponse = await chromeStorageService.getValueFromStorage(
    key,
    env
  );
  const localResponse = await localStorageService.getValueFromStorage(key, env);

  if (
    chromeResponse.success &&
    localResponse.success &&
    JSON.stringify(chromeResponse[compositeKey]) !==
      JSON.stringify(localResponse[compositeKey])
  ) {
    // Chrome storage wins
    await localStorageService.saveValueToStorage(
      key,
      chromeResponse[compositeKey],
      env
    );
    return chromeResponse[compositeKey];
  }

  return localResponse[compositeKey];
};

// Storage effect with conflict resolution
export const storageEffectWithConflictResolution =
  <T>(
    key: string,
    env: string,
    storage = combinedStorageService
  ): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    checkAndResolveConflict(key, env).then((resolvedValue) => {
      const compositeKey = `${env}_${key}`;
      if (resolvedValue !== null && resolvedValue !== undefined) {
        setSelf(resolvedValue as T);
      } else {
        storage
          .getValueFromStorage(key, env)
          .then((storedValue) => {
            if (storedValue.success) {
              setSelf(storedValue[compositeKey] as T);
            }
          })
          .catch((error) => {
            console.error(
              `Error getting value from storage for key ${compositeKey}:`,
              error
            );
          });
      }
    });

    onSet((newValue, _, isReset) => {
      const compositeKey = `${env}_${key}`;
      if (isReset) {
        storage
          .saveValueToStorage(key, null, env)
          .catch((error) =>
            console.error(
              `Error saving value to storage for key ${compositeKey}:`,
              error
            )
          );
      } else {
        storage
          .saveValueToStorage(key, newValue, env)
          .catch((error) =>
            console.error(
              `Error saving value to storage for key ${key}:`,
              error
            )
          );
      }
    });
  };

const environment = import.meta.env.MODE || "development";

export interface SavedAsk {
  dialogueId: string;
  title: string;
}

export const savedDialoguesState = atom<SavedAsk[] | null>({
  key: "savedDialoguesState",
  default: [],
  effects: [
    storageEffectWithConflictResolution<SavedAsk[] | null>(
      "saved_dialogues",
      environment
    ),
  ],
});

export const selectedSavedDialogueState = atom<SavedAsk>({
  key: "savedAsksState",
  default: { dialogueId: "", title: "" },
});

export const selectedPropertyState = atom<string>({
  key: "selectedPropertyState",
  default: "",
});

export const sidebarOpenState = atom<boolean>({
  key: "sidebarOpenState",
  default: false,
});

export type Property = {
  id: string;
  saas_tenant_id: string;
  name: string;
  description: string;
  address: string;
  is_enabled?: boolean | undefined;
};

type Properties = Property[];

export const properties = atom<Properties>({
  key: "properties",
  default: [],
});

export const selectedPropertyIds = atom<Property["id"][]>({
  key: "selectedPropertyIds",
  default: [],
});

export const greetingState = atom<string>({
  key: "greeting",
  default: "",
});

export const loginStatus = atom<boolean>({
  key: "loginStatus",
  default: false,
});

export type DialogueState = {
  id: string;
  dialogue_id?: string | null;
  question: string;
  answerChunks?: AnswerStreamChunk[];
  loading: boolean;
  data?: Dialogue;
  error?: string;
  properties: string[];
  sources?: AnswerSource[];
};

export const dialogueSessionState = atom<DialogueState[]>({
  key: "DialogueSessionState",
  default: [],
});

export const dialogueHistoryState = atom<Dialogue[]>({
  key: "dialogueHistoryState",
  default: [],
});

export const continuationTokenState = atom<string | null>({
  key: "continuationTokenState",
  default: null,
});

export enum ActiveTabStateEnum {
  AskAnything = "ask-anything",
  SavedAsks = "saved-asks",
}

export const activeTabState = atom<ActiveTabStateEnum>({
  key: "activeTabState",
  default: ActiveTabStateEnum.AskAnything,
});
