export const TableIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={props.fill} fillRule="evenodd" clipRule="evenodd"></g>
      <path d="M7.5 8.5V14.5H4V8.5H7.5Z" fillOpacity="0.05" />
      <path
        d="M16 5H4C3.86739 5 3.74021 5.05268 3.64645 5.14645C3.55268 5.24021 3.5 5.36739 3.5 5.5V14C3.5 14.2652 3.60536 14.5196 3.79289 14.7071C3.98043 14.8946 4.23478 15 4.5 15H15.5C15.7652 15 16.0196 14.8946 16.2071 14.7071C16.3946 14.5196 16.5 14.2652 16.5 14V5.5C16.5 5.36739 16.4473 5.24021 16.3536 5.14645C16.2598 5.05268 16.1326 5 16 5ZM4.5 9H7V11H4.5V9ZM8 9H15.5V11H8V9ZM15.5 6V8H4.5V6H15.5ZM4.5 12H7V14H4.5V12ZM15.5 14H8V12H15.5V14Z"
      />
    </svg>
  );
};
