import { EntrataDeeplink } from "./entrata-deeplink";
import { Task } from "@/api/client";
import { useFeatureFlag } from "@/lib/hooks/use-feature-flag";

const ActionBar = ({ task }: { task?: Partial<Task> }) => {
  const { value: deeplinkingEnabled } = useFeatureFlag(
    "isDeeplinkingEnabled",
    false
  );

  if (!deeplinkingEnabled || !task) return null;

  return (
    <div className="flex flex-wrap space-x-2">
      <EntrataDeeplink task={task} />
    </div>
  );
};

export default ActionBar;
