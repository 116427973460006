import { DefaultService, Task, WorkOrder, WorkOrderSummary } from "api-client";
import { DrawerWorkItems } from "../drawer-work-items";
import {
  AssigneeDetail,
  Detail,
  LeaseSummaryDetails,
  PaymentSummaryDetail,
  PersonsDetail,
  UnitDetail,
  WorkOrderHistoryDetail,
  YesNoDetail,
} from "../../ui/drawer-details";
import { useEffect, useState } from "react";
import { DrawerHeaderContainer } from "../drawer-header-container";
import ActionBar from "../action-bar/action-bar";
import { ServiceRequestTimeline } from "./service-request-timeline";

export const ServiceRequestDrawerContent = ({
  item,
  task,
  isLoading: isTaskLoading,
  onClickResident,
}: {
  task?: Partial<Task>;
  item?: WorkOrder;
  isLoading?: boolean;
  onClickResident?: (
    propertyId: string,
    residentId: string,
    name?: string | null
  ) => void;
}) => {
  const propertyId = task?.property_id;
  const primaryResident = task?.residents?.[0];

  const [isMaintenanceHistoryLoading, setIsMaintenanceHistoryLoading] =
    useState(false);
  const [maintenanceHistory, setMaintenanceHistory] = useState<
    WorkOrderSummary[] | null
  >(null);

  useEffect(() => {
    if (!propertyId || !primaryResident?.id) {
      return;
    }
    setIsMaintenanceHistoryLoading(true);
    DefaultService.getWorkOrderSummariesForResidentApiPropertiesPropertyIdResidentsResidentIdWorkordersGet(
      propertyId,
      primaryResident.id
    )
      .then((res) => {
        setMaintenanceHistory(res);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsMaintenanceHistoryLoading(false);
      });
  }, [propertyId, primaryResident?.id]);

  const isLoading = isTaskLoading || isMaintenanceHistoryLoading;

  return (
    <div className="flex flex-col">
      <DrawerHeaderContainer>
        <DrawerWorkItems task={task} isLoading={isLoading} />
        <ServiceRequestTimeline
          dateOpened={item?.created_date}
          dateClosed={item?.completion_date}
          comments={item?.comments || []}
          statusChanges={item?.status_changes || []}
        />
        <ActionBar task={task} />
      </DrawerHeaderContainer>
      <div className="flex flex-col space-y-4 py-4 px-6">
        <AssigneeDetail
          isLoading={isLoading}
          label="Assigned to"
          value={task?.assigned_to}
          className="mb-4"
        />
        <hr />
        <UnitDetail
          label="Unit"
          value={task?.unit_summary}
          isLoading={isLoading}
        />
        <hr />
        <Detail label="Priority" value={item?.priority} isLoading={isLoading} />
        <hr />
        <Detail
          label="Description"
          value={item?.description}
          isLoading={isLoading}
        />
        <hr />
        <YesNoDetail
          label="Permission to enter"
          value={item?.permission_to_enter}
          isLoading={isLoading}
        />
        <hr />
        <Detail
          label="Entry instructions"
          value={item?.entry_instructions}
          isLoading={isLoading}
        />
        <hr />
        <WorkOrderHistoryDetail
          label="Resident maintenance history"
          value={maintenanceHistory}
          isLoading={isLoading}
        />
        <hr />
        <PersonsDetail
          label="Residents"
          value={task?.residents?.map((resident) => ({
            name: resident.full_name,
            onClick: () => {
              if (resident.id && task.property_id) {
                onClickResident?.(
                  task.property_id,
                  resident.id,
                  resident.full_name
                );
              }
            },
          }))}
          isLoading={isLoading}
        />
        <hr />
        <LeaseSummaryDetails
          label="Lease"
          value={task?.lease_summary}
          isLoading={isLoading}
        />
        <hr />
        <PaymentSummaryDetail
          label="Payment history"
          value={item?.payment_history}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
