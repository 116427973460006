export const SaveIcon = ({ active = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    className={active ? "fill-primary" : "fill-gray-600"}
  >
    <path d="M13.5 4h-7c-.26522 0-.51957.10536-.70711.29289C5.60536 4.48043 5.5 4.73478 5.5 5v11c.00004.0892.02397.1768.06929.2537.04531.0769.11038.1402.18843.1834.07806.0433.16627.0648.25547.0625.0892-.0024.17614-.0286.25181-.0759L10 14.0894l3.7356 2.3343c.0757.0472.1626.0732.2517.0755.0891.0023.1772-.0193.2551-.0625.078-.0433.143-.1065.1883-.1833.0452-.0768.0692-.1643.0693-.2534V5c0-.26522-.1054-.51957-.2929-.70711C14.0196 4.10536 13.7652 4 13.5 4Zm0 11.0981-3.2356-2.0218c-.0795-.0497-.1713-.0761-.26503-.0761-.09371 0-.18553.0264-.26499.0761L6.5 15.0981V5h7v10.0981Z" />
    <path
      fillOpacity={active ? "1" : "0"}
      d="M10 14.0894l-3.7356 2.3343c-.07567.0473-.16261.0735-.25181.0759-.0892.0023-.17741-.0192-.25547-.0625-.07805-.0432-.14312-.1065-.18843-.1834-.04532-.0769-.06925-.1645-.06929-.2537V5c0-.26522.10536-.51957.29289-.70711C5.98043 4.10536 6.23478 4 6.5 4h7c.26522 0 .51957.10536.7071.29289.1875.18754.2929.44189.2929.70711v11c0 .0891-.024.1766-.0693.2534-.0452.0768-.1103.14-.1883.1833-.078.0433-.166.0648-.2551.0625-.0891-.0023-.176-.0283-.2517-.0755L10 14.0894Z"
    />
  </svg>
);
