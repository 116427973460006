/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var DailyReportInterval;
(function (DailyReportInterval) {
    DailyReportInterval["TODAY"] = "today";
    DailyReportInterval["TODAY_1"] = "today-1";
    DailyReportInterval["TODAY_2"] = "today-2";
    DailyReportInterval["TODAY_3"] = "today-3";
    DailyReportInterval["TODAY_4"] = "today-4";
    DailyReportInterval["TODAY_5"] = "today-5";
    DailyReportInterval["TODAY_6"] = "today-6";
    DailyReportInterval["TODAY_7"] = "today-7";
})(DailyReportInterval || (DailyReportInterval = {}));
