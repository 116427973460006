import { NavButton } from "@/components/ui/nav-button";
import { SaveIcon } from "@/components/ui/icons/save-icon";
import { useManageSavedDialogues } from "@/lib/hooks/use-manage-saved-dialogues";

export const SavedAsks = () => {
  const {
    savedDialogues,
    activeSavedDialogueState,
    handleSetSavedDialogueState,
  } = useManageSavedDialogues();
  if (!savedDialogues || savedDialogues.length === 0) return null;
  return (
    <div>
      <h3 className="text-h6 text-muted-foreground px-4">SAVED ASKS</h3>
      <div className="px-2">
        {savedDialogues.map((savedDialogue) => (
          <NavButton
            variant="dark"
            isActive={
              savedDialogue.dialogueId === activeSavedDialogueState.dialogueId
            }
            key={savedDialogue.dialogueId}
            onClick={() => handleSetSavedDialogueState(savedDialogue)}
            Icon={() => (
              <SaveIcon
                active={
                  savedDialogue.dialogueId ===
                  activeSavedDialogueState.dialogueId
                }
              />
            )}
          >
            <div className="truncate w-36 whitespace-nowrap overflow-hidden">
              {savedDialogue.title}
            </div>
          </NavButton>
        ))}
      </div>
    </div>
  );
};
