export const PrioritiesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
  >
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M15 4.5a.5.5 0 0 1 .5.5v9.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5M10 8a.5.5 0 0 1 .5.5v6a.5.5 0 1 1-1 0v-6A.5.5 0 0 1 10 8m-4.5 3.5a.5.5 0 0 0-1 0v3a.5.5 0 0 0 1 0z"
      clipRule="evenodd"
    />
  </svg>
);
