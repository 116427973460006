export const ThumbsDownIcon = ({ active = false }) => (
  <svg
    className={active ? "fill-destructive" : "fill-gray-600"}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
  >
    <g>
      <path
        fillOpacity="0.05"
        d="M7 5v6.5H4c-.13261 0-.25979-.0527-.35355-.1464C3.55268 11.2598 3.5 11.1326 3.5 11V5.5c0-.13261.05268-.25979.14645-.35355C3.74021 5.05268 3.86739 5 4 5h3Z"
      />
      <path d="m16.9888 11.8125-.75-6c-.0457-.36257-.2222-.696-.4963-.93767-.2741-.24167-.6271-.37496-.9925-.37483H4c-.26522 0-.51957.10536-.70711.29289C3.10536 4.98043 3 5.23478 3 5.5V11c0 .2652.10536.5196.29289.7071C3.48043 11.8946 3.73478 12 4 12h2.69125l2.36125 4.7238c.04157.083.10545.1529.1845.2017.07904.0488.17011.0746.263.0745.663 0 1.2989-.2634 1.7678-.7322C11.7366 15.7989 12 15.163 12 14.5v-1h3.5c.2129.0001.4233-.0452.6173-.1327.194-.0875.3672-.2153.508-.375.1409-.1596.2461-.3473.3088-.5508.0626-.2034.0813-.4178.0547-.629ZM6.5 11H4V5.5h2.5V11Zm9.375 1.3306c-.0466.0536-.1042.0965-.169.1258-.0647.0292-.135.0441-.206.0436h-4c-.1326 0-.2598.0527-.3536.1464-.0937.0938-.1464.221-.1464.3536v1.5c.0001.3468-.12.6829-.3398.9512-.2198.2682-.5258.452-.86583.52L7.5 11.3819V5.5h7.25c.1218-.00004.2395.04439.3308.12494.0914.08056.1502.1917.1655.31256l.75 6c.0093.0704.0033.142-.0176.2098-.0209.0679-.0563.1304-.1037.1833Z" />
      <path
        fillOpacity={active ? "1" : "0"}
        d="M16.9888 11.8125L16.2388 5.8125C16.1931 5.44993 16.0166 5.1165 15.7425 4.87483C15.4684 4.63316 15.1154 4.49987 14.75 4.5H4C3.73478 4.5 3.48043 4.60536 3.29289 4.79289C3.10536 4.98043 3 5.23478 3 5.5V11C3 11.2652 3.10536 11.5196 3.29289 11.7071C3.48043 11.8946 3.73478 12 4 12H6.69125L9.0525 16.7238C9.09407 16.8068 9.15795 16.8767 9.237 16.9255C9.31604 16.9743 9.40711 17.0001 9.5 17C10.163 17 10.7989 16.7366 11.2678 16.2678C11.7366 15.7989 12 15.163 12 14.5V13.5H15.5C15.7129 13.5001 15.9233 13.4548 16.1173 13.3673C16.3113 13.2798 16.4845 13.152 16.6253 12.9923C16.7662 12.8327 16.8714 12.645 16.9341 12.4415C16.9967 12.2381 17.0154 12.0237 16.9888 11.8125ZM6.5 11H4V5.5H6.5V11Z"
      />
    </g>
  </svg>
);
