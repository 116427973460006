import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Button } from "surface-components";
import { ThumbsDownIcon } from "@/components/ui/icons/thumbs-down";
import { ThumbsUpIcon } from "@/components/ui/icons/thumbs-up";
import { DefaultService, DialogueFeedback } from "api-client";
import ReactGA from "react-ga4";

type FeedbackProps = {
  feedback?: DialogueFeedback | null;
  dialogueId?: string | null;
};

const sanitizeFeedback = (content: string) => {
  // TODO: make this better
  return content.replace(/<\/?[^>]+(>|$)/g, "");
};

export const Feedback = ({ feedback, dialogueId }: FeedbackProps) => {
  const feedbackRef = useRef<HTMLDivElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState("");
  const [localFeedbackVote, setLocalFeedbackVote] = useState<null | boolean>(
    feedback?.feedback_vote ?? null
  );

  // We want the feedback text entry to be in view when it is shown
  useEffect(() => {
    if (feedbackRef && feedbackRef.current) {
      feedbackRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [showFeedbackInput]);

  const isThumbsUpActive = localFeedbackVote === true;
  const isThumbsDownActive = localFeedbackVote === false;

  const handleThumbUp = async () => {
    ReactGA.event("click.feedbackThumbsUp", {
      dialogueId: dialogueId || "",
    });
    setIsSubmitting(true);

    const newVote = localFeedbackVote === true ? null : true;
    DefaultService.saveFeedbackApiAskDialogueIdFeedbackPost(
      dialogueId as string,
      {
        feedback_vote: newVote,
      }
    )
      .then(() => {
        setLocalFeedbackVote(newVote);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleThumbDown = () => {
    ReactGA.event("click.feedbackThumbsDown", {
      dialogueId: dialogueId || "",
    });
    setShowFeedbackInput(!showFeedbackInput);
    if (!showFeedbackInput) {
      setLocalFeedbackVote(false);
    } else {
      setLocalFeedbackVote(null);
    }
  };

  const handleCloseFeedbackInput = () => {
    setShowFeedbackInput(false);
    setLocalFeedbackVote(null);
  };

  const submitFeedback = async () => {
    ReactGA.event("submitFeedback", {
      dialogueId: dialogueId || "",
    });
    const sanitizedFeedback = sanitizeFeedback(feedbackContent);
    setIsSubmitting(true);
    try {
      await DefaultService.saveFeedbackApiAskDialogueIdFeedbackPost(
        dialogueId as string,
        {
          feedback_vote: false,
          feedback_content: sanitizedFeedback,
        }
      );
      setLocalFeedbackVote(false);
    } catch (e) {
      console.log("error");
    }
    setIsSubmitting(false);
    // Reset state after submission
    setShowFeedbackInput(false);
    setFeedbackContent("");
  };

  const feedbackInputMarkup = (
    <div ref={feedbackRef}>
      <hr className="border-gray-200 mt-2 mb-1" />
      <div className="flex flex-col align-middle py-2 gap-2">
        <div className="flex gap-1">
          <ThumbsDownIcon />
          <p className="text-gray-600">Why wasn&apos;t this helpful?</p>
        </div>
        <textarea
          rows={3}
          className="border border-gray-300 rounded-lg p-2 w-full bg-white"
          value={feedbackContent}
          onChange={(e) => setFeedbackContent(e.target.value)}
          disabled={isSubmitting}
          placeholder="Please add more details here. Let us know what you expected and what can be improved about this response."
        />
        <div className="flex justify-end gap-1">
          <Button
            onClick={handleCloseFeedbackInput}
            className="mt-2"
            variant="outline"
            disabled={isSubmitting}
          >
            Close
          </Button>
          <Button
            onClick={submitFeedback}
            className="mt-2"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );

  const portalRoot = document.getElementById(`portal-root-${dialogueId}`);

  return (
    <>
      <div className="justify-end items-center flex gap-x-0">
        <Button
          variant="outline"
          size="icon"
          onClick={handleThumbUp}
          aria-checked={isThumbsUpActive}
          aria-label="Thumbs up"
          className="-mr-px rounded-xl rounded-tr-none rounded-br-none bg-white"
          disabled={isSubmitting}
        >
          <div className="p-2">
            <ThumbsUpIcon active={isThumbsUpActive && !isSubmitting} />
          </div>
        </Button>
        <Button
          variant="outline"
          size="icon"
          onClick={handleThumbDown}
          aria-checked={isThumbsDownActive}
          className="-mr-px rounded-xl rounded-tl-none rounded-bl-none bg-white"
          aria-label="Thumbs down"
          disabled={isSubmitting}
        >
          <div className="p-2">
            <ThumbsDownIcon active={isThumbsDownActive && !isSubmitting} />
          </div>
        </Button>
      </div>
      {showFeedbackInput && portalRoot
        ? createPortal(feedbackInputMarkup, portalRoot as Element)
        : null}
    </>
  );
};
