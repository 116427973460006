export interface LocalStorageService {
  getValueFromStorage(key: string, env: string): Promise<StorageResponse>;
  saveValueToStorage(key: string, value: unknown, env: string): Promise<void>;
}

export interface StorageResponse {
  success: boolean;
  error?: string;
  [key: string]: unknown; // This allows for additional properties
}

export const localStorageService: LocalStorageService = {
  getValueFromStorage: (key: string, env: string) => {
    const storageKey = `${env}_${key}`;
    return new Promise((resolve) => {
      const item = localStorage.getItem(storageKey);
      if (item === null) {
        resolve({ success: true, [storageKey]: null });
      } else {
        try {
          const value = JSON.parse(item);
          resolve({ success: true, [storageKey]: value });
        } catch (error) {
          console.error(
            `Error parsing JSON from localStorage for key "${key}":`,
            error,
          );
          resolve({
            success: false,
            error: `Error parsing JSON for key "${key}".`,
          });
        }
      }
    });
  },

  saveValueToStorage: (key: string, value: unknown, env: string) => {
    const storageKey = `${env}_${key}`;
    return new Promise<void>((resolve) => {
      if (value === null) {
        localStorage.removeItem(storageKey);
      } else {
        localStorage.setItem(storageKey, JSON.stringify(value));
      }
      resolve();
    });
  },
};
