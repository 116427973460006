import { MiddleDot } from "../ui/middle-dot";
import { Button } from "surface-components";
import CloseIcon from "../../assets/img/close.svg";
import {
  getEntityName,
  getEntityDetails,
  getDrawerTitle,
} from "./entity-detail-helpers";
import { Data } from "api-client";
type SourceEntity = Data["items"][0];

interface EntityDetailsDrawerProps {
  dialogueId?: string | null;
  sourceId?: string | null;
  item: SourceEntity;
  onClose: () => void;
}

const EntityDetailsDrawer = ({
  onClose,
  item,
  sourceId,
  dialogueId,
}: EntityDetailsDrawerProps) => {
  const itemName = getEntityName(item);
  return (
    <div className="bg-white flex-1 h-full">
      <div className="text-xl flex items-center space-x-2 text-muted-foreground px-4 py-5 border-b">
        <Button variant="outline" className="p-1 h-auto" onClick={onClose}>
          <img src={CloseIcon} alt="Close" />
        </Button>
        <span className="text-foreground font-medium">
          {getDrawerTitle(item)}
        </span>
        {itemName && (
          <>
            <MiddleDot />
            <span>{itemName}</span>
          </>
        )}
      </div>
      <div className="p-4">{getEntityDetails(dialogueId, sourceId, item)}</div>
    </div>
  );
};

export { EntityDetailsDrawer };
