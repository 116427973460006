interface StorageResponse {
  success: boolean;
  error?: string;
  [key: string]: unknown;
}

export interface ChromeStorageService {
  getValueFromStorage(key: string, env: string): Promise<StorageResponse>;
  saveValueToStorage(key: string, value: unknown, env: string): Promise<void>;
}

window.surfaceExtensionDetected = false;

export const getChromeExtensionDetected = () => window.surfaceExtensionDetected;
export const setChromeExtensionDetected = (value: boolean) => {
  window.surfaceExtensionDetected = value;
};

export const storageService: ChromeStorageService = {
  getValueFromStorage: (key: string, env: string) => {
    return new Promise((resolve, reject) => {
      const responseEventName = `responseForGetValue_${Date.now()}`;

      const handleResponse = (event: Event) => {
        const customEvent = event as CustomEvent<StorageResponse>;
        const response = customEvent.detail;

        if (response?.success) {
          resolve(response);
        } else {
          reject(new Error(response?.error || "Unknown error"));
        }
        window.removeEventListener(responseEventName, handleResponse);
      };

      // We need the content script to be ready before we can make requests against the chrome storage
      // API. We also need to acknoledge to the extension that we have received the content script ready event
      // because there is a race condition regarding which app initializes first and the content script will
      // attempt a few retries until the app has acknowledged that it has received the ready event.
      // In cases where there is no extension installed, this will timeout after 500ms.
      const waitForContentScriptReady = new Promise<void>((resolve, reject) => {
        if (getChromeExtensionDetected()) {
          resolve();
          return;
        }

        const handleReady = () => {
          setChromeExtensionDetected(true);
          window.dispatchEvent(
            new CustomEvent("content-script-ready-received")
          );
          window.removeEventListener("content-script-ready", handleReady);
          clearTimeout(timeoutId);
          resolve();
        };
        window.addEventListener("content-script-ready", handleReady);

        const timeoutId = setTimeout(() => {
          window.removeEventListener("content-script-ready", handleReady);
          reject("Timeout waiting for content script to be ready");
        }, 500);
      });

      waitForContentScriptReady
        .then(() => {
          window.addEventListener(responseEventName, handleResponse);
          window.dispatchEvent(
            new CustomEvent("RequestFromPage", {
              detail: {
                action: "getFromStorage",
                key: `${env}_${key}`,
                responseEventName,
              },
            })
          );
        })
        .catch(() => {
          resolve({
            success: false,
            error: "Surface extension not detected",
          });
        });
    });
  },

  saveValueToStorage: (key: string, value: unknown, env: string) => {
    return new Promise<void>((resolve, reject) => {
      const responseEventName = `responseForSaveValue_${Date.now()}`;
      window.addEventListener(
        responseEventName,
        (event: Event) => {
          const customEvent = event as CustomEvent<StorageResponse>;
          const response = customEvent.detail;
          if (response?.success) {
            resolve();
          } else {
            reject(new Error(response?.error));
          }
        },
        { once: true }
      );

      window.dispatchEvent(
        new CustomEvent("RequestFromPage", {
          detail: {
            action: "saveToStorage",
            key: `${env}_${key}`,
            value,
            responseEventName,
          },
        })
      );
    });
  },
};
