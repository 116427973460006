export const ApplicationsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
  >
    <g fill={props.fill}>
      <path
        fillOpacity=".05"
        d="M4 5.333A2.333 2.333 0 0 1 6.333 3h7.334A2.333 2.333 0 0 1 16 5.333v9.334A2.333 2.333 0 0 1 13.667 17H6.333A2.333 2.333 0 0 1 4 14.667z"
      />
      <path
        fillRule="evenodd"
        d="M5.135 4.468c.282-.282.668-.452 1.339-.542.685-.092 1.588-.093 2.86-.093h1.333c1.27 0 2.174.001 2.859.093.67.09 1.057.26 1.339.542s.452.668.542 1.339c.092.685.093 1.588.093 2.86v2.666c0 1.271-.001 2.174-.093 2.86-.09.67-.26 1.056-.542 1.338s-.668.452-1.339.542c-.685.092-1.588.093-2.86.093H9.334c-1.27 0-2.174 0-2.859-.093-.67-.09-1.057-.26-1.339-.541-.282-.283-.452-.669-.542-1.34-.092-.685-.093-1.588-.093-2.859V8.666c0-1.27.001-2.174.093-2.859.09-.67.26-1.057.542-1.339m4.198-1.635h-.037c-1.225 0-2.196 0-2.955.102-.782.105-1.414.327-1.913.826s-.72 1.131-.826 1.913c-.102.76-.102 1.73-.102 2.955v2.742c0 1.225 0 2.195.102 2.955.105.781.327 1.414.826 1.912.498.5 1.131.721 1.913.826.76.102 1.73.102 2.955.102h1.408c1.225 0 2.196 0 2.955-.102.782-.105 1.414-.326 1.913-.825l-.353-.354.353.353c.5-.498.72-1.13.826-1.912.102-.76.102-1.73.102-2.955V8.629c0-1.225 0-2.196-.102-2.955-.105-.782-.327-1.415-.826-1.913-.499-.5-1.131-.72-1.913-.826-.76-.102-1.73-.102-2.955-.102H9.333m-2 4a.5.5 0 0 0 0 1h5.333a.5.5 0 1 0 0-1zm0 2.667a.5.5 0 1 0 0 1h5.333a.5.5 0 1 0 0-1zm0 2.666a.5.5 0 0 0 0 1h3.333a.5.5 0 1 0 0-1z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
