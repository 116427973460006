// hooks/use-get-history.ts
import { useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  dialogueHistoryState,
  continuationTokenState,
} from "@/lib/recoil/state";
import { DefaultService } from "@/api/client";

export const fetchDialogueHistory = async (token?: string) => {
  const continuationToken = token ? token : undefined;
  const { dialogues: newDialogues, continuation_token } =
    await DefaultService.askHistoryApiAskHistoryGet(continuationToken);
  return { newDialogues, continuation_token };
};

export const useGetHistory = () => {
  const setDialogues = useSetRecoilState(dialogueHistoryState);
  const continuationToken = useRecoilValue(continuationTokenState);
  const setContinuationToken = useSetRecoilState(continuationTokenState);

  const fetchHistory = useCallback(
    async (token?: string) => {
      try {
        const { newDialogues, continuation_token } =
          await fetchDialogueHistory(token);

        if (token) {
          setDialogues((prevDialogues) => [
            ...prevDialogues,
            ...(newDialogues || []),
          ]);
        } else {
          setDialogues(newDialogues || []);
        }

        setContinuationToken(continuation_token || null);
      } catch (error) {
        console.error("Failed to fetch dialogues:", error);
      }
    },
    [setDialogues, setContinuationToken]
  );

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  // Making refetch async to return the fetchHistory promise to use it
  // in the content UI to handle the load more button and scroll position
  const refetch = useCallback(async () => {
    if (continuationToken) {
      return await fetchHistory(continuationToken);
    }
  }, [continuationToken, fetchHistory]);

  return { refetch };
};
