/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var LeaseDepartureIntent;
(function (LeaseDepartureIntent) {
    LeaseDepartureIntent["NONE"] = "None";
    LeaseDepartureIntent["NOTICE"] = "Notice";
    LeaseDepartureIntent["RENEWED"] = "Renewed";
    LeaseDepartureIntent["TRANSFERRED"] = "Transferred";
})(LeaseDepartureIntent || (LeaseDepartureIntent = {}));
