import { surface_core__ask__entitites__askentities__MoveIn as MoveIn } from "api-client";
import {
  DateDetail,
  PersonsDetail,
  Detail,
  LeaseCompletionStatusDetail,
  ApplicationStatusDetail,
} from "../ui/drawer-details";

interface MoveInDetailsProps {
  item: MoveIn;
}

const MoveInDetails = ({ item }: MoveInDetailsProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <Detail label="Property" value={item.property_name} />
      <hr />
      <DateDetail label="Move-In Date" value={item.move_in_date} />
      <hr />
      <PersonsDetail
        label="Residents"
        value={item.resident_full_names?.map((name) => ({
          name,
        }))}
      />
      <hr />
      <Detail label="Application ID" value={item.application_id} />
      <hr />
      <ApplicationStatusDetail
        label="Application Status"
        value={item.application_status}
      />
      <hr />
      <DateDetail label="Lease Start Date" value={item.lease_start_date} />
      <hr />
      <LeaseCompletionStatusDetail
        label="Lease Completion Status"
        value={item.lease_completion_status}
      />
    </div>
  );
};

export { MoveInDetails };
