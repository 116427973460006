import { Button } from "surface-components";
import MicrosoftLogo from "../assets/img/microsoft-logo.svg";
import GoogleLogo from "../assets/img/google-color.svg";
import { LogoIcon } from "../components/ui/icons/logo-icon";
import { LOGIN_MICROSOFT, LOGIN_GOOGLE } from "../constants";
import { useSearchParams } from "react-router-dom";

enum LoginType {
  MICROSOFT,
  GOOGLE,
}

type LoginProps = {
  // Override for query parameter context for new user flow
  inlineContext?: "newUser";
};

const Login = ({ inlineContext }: LoginProps) => {
  const [searchParams] = useSearchParams();
  const context = searchParams.get("context");

  const composeLoginUrl = (loginType: LoginType): string => {
    const baseUrl =
      loginType === LoginType.MICROSOFT ? LOGIN_MICROSOFT : LOGIN_GOOGLE;

    // Determine the context to use, prioritizing inlineContext if present
    const finalContext = inlineContext || context;

    // Append context parameter if it exists
    if (finalContext) {
      searchParams.set("context", finalContext);
    }

    return `${baseUrl}?${searchParams.toString()}`;
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-8">
        <LogoIcon height={96} width={96} />
      </div>
      <div className="mb-8">
        <h2 className="text-3xl">Sign in to Surface</h2>
      </div>
      <div className="flex flex-col mb-8">
        <div className="mb-4 w-full">
          <Button className="w-full">
            <a href={composeLoginUrl(LoginType.MICROSOFT)} className="flex">
              <img src={MicrosoftLogo} className="mr-0.5" />
              Sign in with Microsoft account
            </a>
          </Button>
        </div>
        <div className="w-full">
          <Button className="w-full" variant="secondary">
            <a href={composeLoginUrl(LoginType.GOOGLE)} className="flex">
              <img src={GoogleLogo} className="mr-0.5" />
              Sign in with Google account
            </a>
          </Button>
        </div>
      </div>
      <div className="text-center max-w-xs">
        <p>By signing in you agree to our</p>
        <p>
          {" "}
          <a className="text-primary font-bold" href="#">
            Terms of Use
          </a>{" "}
          and{" "}
          <a className="text-primary font-bold" href="#">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export { Login };
