import React from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { Button } from "surface-components";

interface NavButtonProps {
  Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  children: React.ReactNode | string;
  variant?: "light" | "dark";
  onClick?: () => void;
  isActive?: boolean;
  shimmer?: boolean;
}

export const NavButton: React.FC<NavButtonProps> = ({
  Icon,
  children,
  onClick,
  isActive = false,
  shimmer = false,
}) => {
  return (
    <Button
      variant="ghost"
      aria-pressed={isActive}
      onClick={onClick}
      className={clsx("w-full justify-start gap-2", {
        "animate-shimmer": shimmer,
        "animate-shimmer-slow": shimmer,
        "bg-gradient-to-r from-blue-100 via-gray-50 to-blue-100": shimmer,
      })}
    >
      {Icon && (
        <span className="w-6 block items-center">
          {<Icon fill={isActive ? "#234DEC" : "#576077"} />}
        </span>
      )}
      <span>{children}</span>
    </Button>
  );
};

interface StatefulNavButtonProps extends Omit<NavButtonProps, "isActive"> {
  to: string;
  search?: string;
  end?: boolean;
  shimmer?: boolean;
}

export const StatefulNavButton: React.FC<StatefulNavButtonProps> = ({
  Icon,
  children,
  variant,
  to,
  end,
  onClick,
  search = "",
  shimmer = false,
}) => {
  return (
    <NavLink to={to + search} end={end} className="w-full">
      {({ isActive }) => (
        <NavButton
          onClick={onClick}
          Icon={Icon}
          variant={variant}
          isActive={isActive}
          shimmer={shimmer}
        >
          {children}
        </NavButton>
      )}
    </NavLink>
  );
};
