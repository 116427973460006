import { LeaseAuditCheck } from "api-client";
import { Icon } from "surface-components";
import { getUniqueLeaseDocs } from "./utils";

interface LeaseAuditDocumentsProps {
  label: string;
  value: LeaseAuditCheck[] | undefined;
  className?: string;
  isLoading?: boolean;
}

export const LeaseAuditDocuments: React.FC<LeaseAuditDocumentsProps> = ({
  value,
  // isLoading,
}) => {
  const uniqueDocs = getUniqueLeaseDocs(value ?? []);
  return (
    <div className="flex-col mb-4">
      <h2 className="mb-6">Checked against</h2>
      <div className="flex-col space-y-2">
        {uniqueDocs.map((doc, index) => (
          <div key={index} className="flex items-center space-x-2">
            <Icon icon="document" />
            <a
              target="_blank"
              href="#todo-link-to-doc"
              className="text-primary hover:underline"
            >
              {doc}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
