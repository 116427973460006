export const MoveOutsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    {...props}
  >
    <g fill={props.fill} fillRule="evenodd" clipRule="evenodd">
      <path d="M4.744 2.744a.83.83 0 0 1 .59-.244h5.333a.833.833 0 0 1 .833.833V6h1V3.333A1.833 1.833 0 0 0 10.667 1.5H5.333A1.833 1.833 0 0 0 3.5 3.333V13.5H2a.5.5 0 0 0 0 1h12a.5.5 0 0 0 0-1h-1.5V11h-1v2.5h-7V3.333c0-.22.088-.433.244-.589M9.834 8.5a.5.5 0 1 0-1 0v.007a.5.5 0 0 0 1 0z" />
      <path d="M13.146 6.646a.5.5 0 0 1 .708 0l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708-.708L13.793 9H11.5a.5.5 0 0 1 0-1h2.293l-.647-.646a.5.5 0 0 1 0-.708" />
    </g>
  </svg>
);
