import { formatDateTime } from "@/lib/utils";
import { Card } from "surface-components";
import { WorkOrderComment, WorkOrderStatusChange } from "api-client";

function isComment(item: any): item is WorkOrderComment {
  return item.hasOwnProperty("comment");
}

function isStatusChange(item: any): item is WorkOrderStatusChange {
  return item.hasOwnProperty("to_status");
}

const getEventDateTime = (
  item: WorkOrderComment | WorkOrderStatusChange
): string | null | undefined => {
  if (isComment(item)) {
    return item.created_date;
  } else if (isStatusChange(item)) {
    return item.change_datetime;
  }
};

export const ServiceRequestTimeline = ({
  dateOpened,
  dateClosed,
  comments,
  statusChanges,
}: {
  dateOpened?: string | null;
  dateClosed?: string | null;
  comments: WorkOrderComment[];
  statusChanges: WorkOrderStatusChange[];
}) => {
  const sortedEvents = [...comments, ...statusChanges].sort((a, b) => {
    const aDateTime = getEventDateTime(a);
    const bDateTime = getEventDateTime(b);
    if (aDateTime && bDateTime) {
      return aDateTime > bDateTime ? 1 : -1;
    }
    return 0;
  });
  return (
    <Card className="bg-white shadow-md p-2 flex-col space-y-2">
      <div>Timeline</div>
      {dateOpened && (
        <div className="flex justify-between">
          <div className="flex space-x-2 items-start">
            <div className="bg-gray-600  p-1.5 rounded-sm mt-1.5" />
            <div>Opened Date</div>
          </div>
          <div>{formatDateTime(dateOpened)}</div>
        </div>
      )}
      {sortedEvents.map((event) => {
        if (isComment(event)) {
          return (
            <div className="flex-col space-y-2">
              <div className="flex space-x-2 items-start">
                <div className="bg-gray-600  p-1.5 rounded-sm mt-1.5" />
                <div>Comment</div>
              </div>
              <div className="flex-col space-y-2 p-2 rounded-md bg-muted">
                <div className="flex space-x-2 text-sm">
                  <span>{event.created_by}</span>
                  {event.created_date && (
                    <span>{formatDateTime(event.created_date)}</span>
                  )}
                </div>
                <div>{event.comment}</div>
              </div>
            </div>
          );
        } else if (isStatusChange(event)) {
          return (
            <div className="flex justify-between">
              <div className="flex space-x-2 items-start">
                <div className="bg-gray-600  p-1.5 rounded-sm mt-1.5" />
                <div>Status Change</div>
              </div>
              <div className="flex-col space-y-2">
                <div className="text-right">
                  <strong>{event.to_status}</strong>
                </div>
                {event.change_datetime && (
                  <div>{formatDateTime(event.change_datetime)}</div>
                )}
              </div>
            </div>
          );
        }
      })}
      {dateClosed && (
        <div className="flex justify-between">
          <div className="flex space-x-2 items-start">
            <div className="bg-gray-600  p-1.5 rounded-sm mt-1.5" />
            <div>Closed Date</div>
          </div>
          <div>{formatDateTime(dateClosed)}</div>
        </div>
      )}
    </Card>
  );
};
