import { TaskType, TaskFacet, TaskGroupState } from "@/lib/hooks/use-tasks";
import { MiddleDot } from "@/components/ui/middle-dot";
import { UpperNav } from "@/components/ui/upper-nav";
import { Drawer, DrawerContent, DrawerPortal } from "@/components/ui/drawer";
import { TaskDetailsDrawer } from "@/components/task-details/drawer";
import { Task, TaskSortParam, TaskStatus, UserSession } from "api-client";
import { TaskTable } from "@/components/task-table";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { DefaultService } from "@/api/client";
import { useNavigate } from "react-router-dom";
import {
  PropertySelect,
  Icon,
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  Button,
} from "surface-components";
import { useResident } from "@/lib/hooks/use-resident";
import {
  ResidentDetailsDrawer,
} from "@/components/resident-details/drawer";

const AssignedToFiler = ({
  userId,
  assignedToFilter,
  setAssignedToFilter,
}: {
  userId: string;
  assignedToFilter: string | null;
  setAssignedToFilter: (userId: string) => void;
}) => {
  return (
    <Tabs
      defaultValue="all-tasks"
      className="w-[400px]"
      value={assignedToFilter === userId ? "my-tasks" : "all-tasks"}
      onValueChange={(v) => {
        if (v === "all-tasks") {
          setAssignedToFilter("");
        } else {
          setAssignedToFilter(userId || "");
        }
      }}
    >
      <TabsList>
        <TabsTrigger className="text-[16px]" value="all-tasks">
          All tasks
        </TabsTrigger>
        <TabsTrigger className="text-[16px]" value="my-tasks">
          My tasks
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
};

interface TaskGroups extends TaskGroupState {
  type: TaskType;
  facet?: TaskFacet;
}

export interface TasksListProps {
  user: UserSession;
  title: string;
  greeting?: string;
  propertyIds: string[];
  setPropertyIds: (propertyIds: string[]) => void;
  isLoadingList: boolean;
  taskGroups: TaskGroups[];
  isLoadingSelectedTask: boolean;
  selectedTask: Partial<Task> | null;
  assignedToFilter: string | null;
  setAssignedToFilter: (userId: string) => void;
  clearSelectedTask: () => void;
  selectTask: (taskId: string, propertyId: string) => void;
  fetchPage: (groupKey: string, page: number) => void;
  updateSort: (groupKey: string, sort?: TaskSortParam) => void;
  downloadUrl?: string;
}

export const TasksList = ({
  user,
  title,
  greeting,
  selectedTask,
  propertyIds,
  setPropertyIds,
  isLoadingList,
  taskGroups,
  isLoadingSelectedTask,
  selectTask,
  clearSelectedTask,
  assignedToFilter,
  setAssignedToFilter,
  fetchPage,
  updateSort,
  downloadUrl,
}: TasksListProps) => {
  const navigate = useNavigate();
  const handleLogoutClick = async () => {
    await DefaultService.logoutApiLogoutGet();
    return navigate("/login");
  };
  const { resident, isLoadingResident, onResidentClick, clearResident } =
    useResident();

  const closeDrawer = () => {
    clearSelectedTask();
    clearResident();
  };

  return (
    <div className="w-full h-100vh pb-4 bg-white h-screen overflow-hidden">
      <UpperNav
        left={
          <div className="flex space-x-2 items-center">
            <span className="text-foreground">{title}</span>
            <MiddleDot />
            <PropertySelect
              buttonClassName="text-xl"
              properties={user.properties || []}
              selectedPropertyIds={propertyIds}
              updatePropertyIds={setPropertyIds}
            />
          </div>
        }
        right={
          <div className="flex items-center space-x-2 text-muted-foreground">
            <Button className="flex space-x-1" variant="outline">
              <Icon icon="download" />
              <a href={downloadUrl} download>
                {" "}
                <span>Download</span>
              </a>
            </Button>
            <HoverCard>
              <HoverCardTrigger>
                <Button variant="outline">
                  <Icon icon="person" />
                </Button>
              </HoverCardTrigger>
              <HoverCardContent className="bg-white rounded-2xl p-1 border-solid border border-border shadow-md w-32 mx-2">
                <Button
                  onClick={handleLogoutClick}
                  variant="ghost"
                  size="sm"
                  className="w-full"
                >
                  Logout
                </Button>
              </HoverCardContent>
            </HoverCard>
          </div>
        }
      />
      <div className="w-full container pt-8 overflow-y-scroll h-[calc(100%-32px)]">
        {greeting && (
          <h2 className="text-2xl text-foreground font-medium mb-6">
            {greeting}
          </h2>
        )}

        <div>
          <AssignedToFiler
            userId={user.id}
            assignedToFilter={assignedToFilter}
            setAssignedToFilter={setAssignedToFilter}
          />
          {taskGroups.map(
            ({
              title,
              type,
              tasks,
              facet,
              filters,
              page,
              total,
              page_size,
              key,
              loading: isTableLoading,
            }) => {
              if (filters.status === TaskStatus.COMPLETED && !tasks?.length) {
                return null;
              }
              return (
                <div key={key} className="mt-8">
                  <TaskTable
                    groupKey={key}
                    isLoading={isLoadingList || isTableLoading}
                    title={title}
                    facet={facet}
                    type={type as TaskType}
                    tasks={tasks || []}
                    selectedTaskId={selectedTask?.id}
                    selectedTaskPropertyId={selectedTask?.property_id}
                    onClickRow={selectTask}
                    page={page}
                    pageSize={page_size}
                    total={total}
                    fetchPage={fetchPage}
                    updateSort={updateSort}
                    properties={user.properties || []}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
      <Drawer
        direction="right"
        open={!!selectedTask}
        dismissible={false}
        onOpenChange={(open) => {
          if (!open && selectedTask) {
            closeDrawer();
          }
        }}
      >
        <DrawerPortal>
          <DrawerContent className="h-screen top-0 right-0 left-auto mt-0 w-[640px] overflow-y-scroll overflow-x-hidden focus-visible:outline-none">
            {resident && (
              <ResidentDetailsDrawer
                resident={resident}
                isLoading={isLoadingResident}
                onClose={closeDrawer}
                onClickResident={onResidentClick}
                propertyId={selectedTask?.property_id}
              />
            )}
            {!resident && selectedTask && (
              <TaskDetailsDrawer
                isLoading={isLoadingSelectedTask}
                task={selectedTask}
                onClose={closeDrawer}
                onClickResident={onResidentClick}
              />
            )}
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </div>
  );
};
