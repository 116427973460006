import clsx from "clsx";
import {
  formatCurrency,
  formatDate,
  formatDateTime,
  humanize,
} from "../../lib/utils";
import {
  Address,
  AvailabilityStatus,
  Charge,
  LeaseSummary,
  LeasedItem,
  MakeReadyStatus,
  OccupancyStatus,
  Pet,
  Renewal,
  ResidentAccount,
  ResidentActivity,
  ResidentVehicle,
  StatusChange,
  UnitSummary,
  WorkOrderSummary,
  surface_core__api__entities__MoveOut,
} from "api-client";
import {
  Assignee,
  Icon,
  Status,
  StatusIcon,
  Skeleton,
  Button,
} from "surface-components";
import { MiddleDot } from "./middle-dot";
import { getActivityIcon, getTimePeriod } from "../source-entity-details/entity-detail-helpers";

interface DetailProps<V = React.ReactNode> {
  className?: string;
  isLoading?: boolean;
  loadingElement?: JSX.Element;
  label: React.ReactNode;
  value?: V | null;
}

const Detail = ({
  label,
  value,
  className,
  isLoading,
  loadingElement,
}: DetailProps) => {
  const getValue = () => {
    if (isLoading) {
      return (
        loadingElement || (
          <div className="flex items-center space-x-2 mt-2">
            <Skeleton className="mt-[3px] h-1 w-9" />
            <Skeleton className="mt-[3px] h-1 w-5" />
          </div>
        )
      );
    }
    return value || "--";
  };
  return (
    <div
      className={clsx(
        "flex items-start space-x-2 text-muted-foreground",
        className
      )}
    >
      <div className="w-1/4 column">{label}</div>
      <div className="w-3/3 text-foreground">{getValue()}</div>
    </div>
  );
};

const YesNoDetail = ({
  label,
  value,
  className,
  isLoading,
}: DetailProps<boolean | null>) => {
  let text: string = "--";
  if (value === true) {
    text = "Yes";
  } else if (value === false) {
    text = "No";
  }
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      value={text}
      className={className}
    />
  );
};

const DateDetail = ({ label, value, className, isLoading }: DetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="clock" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-6" />
        </div>
      }
      value={
        value && (
          <div className="flex items-center space-x-2">
            <Icon icon="clock" />
            <span>
              {isLoading ? (
                <Skeleton className="mt-[3px] h-1 w-4" />
              ) : (
                `${typeof value === "string" ? formatDate(value) : value}`
              )}
            </span>
          </div>
        )
      }
      className={className}
    />
  );
};

interface PersonsDetailProps
  extends DetailProps<
    | string
    | {
        name?: string | null;
        is_minor?: boolean | null;
        phone_number?: string | null;
        email_address?: string | null;
        is_primary?: boolean | null;
        onClick?: (name: string) => void;
      }[]
    | null
  > {
    onClickName?: (propertyId: string, residentId: string, name?: string) => void;
  }

const PersonsDetail = ({
  label,
  value,
  className,
  isLoading,
}: PersonsDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="person" />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <MiddleDot />
          <Skeleton className="mt-[3px] h-1 w-8" />
        </div>
      }
      value={
        <div className="flex-col space-y-4">
          {typeof value === "string"
            ? value
            : value?.map(
                (x, i) =>
                  x.name && (
                    <div key={i} className="flex items-center space-x-2">
                      {x.is_minor ? (
                        <Icon icon="child" />
                      ) : (
                        <Icon icon="person" />
                      )}
                      <div className="flex-col space-y-1">
                        <div className="flex items-center space-x-2">
                          <div className="leading-5">

                            {x.onClick ? (
                              <Button
                                variant="link"
                                className="p-0"
                                onClick={() => x.onClick?.(x.name || "")}
                              >
                                {x.name}
                              </Button>
                            ) : (
                              <span>{x.name}</span>
                            )}
                          </div>
                          {x.is_primary && (
                            <div className="flex items-center space-x-2 text-muted-foreground">
                              <MiddleDot />
                              <span>Primary</span>
                            </div>
                          )}
                        </div>
                        <div className="flex space-x-2">
                          {x.phone_number && (
                            <a
                              className="text-primary"
                              href={`tel:${x.phone_number}`}
                            >
                              <span>{x.phone_number}</span>
                            </a>
                          )}
                          {x.email_address && (
                            <div className="flex items-center space-x-2">
                              <MiddleDot />
                              <a
                                className="text-primary"
                                href={`mailto:${x.email_address}`}
                              >
                                {x.email_address}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
              )}
        </div>
      }
      className={className}
    />
  );
};

interface PetsDetailProps extends DetailProps<Pet[] | null> {}

const PetsDetail = ({
  label,
  value,
  className,
  isLoading,
}: PetsDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="pet" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-8" />
          <MiddleDot />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <Skeleton className="mt-[3px] h-1 w-8" />
        </div>
      }
      value={
        value?.length && (
          <div className="flex-col space-y-4">
            {value.map((x, i) => (
              <div key={i} className="flex items-center space-x-2">
                <Icon icon="pet" />
                <span>{humanize(x.type || "")}</span>
                {x.name && <span>("{x.name}")</span>}
                {x.breed && (
                  <div className="flex items-center space-x-2 text-muted-foreground">
                    <MiddleDot />
                    <span>{humanize(x.breed)}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        )
      }
      className={className}
    />
  );
};

interface LeasedItemsDetailProps extends DetailProps<LeasedItem[] | null> {}

const LeasedItemsDetail = ({
  label,
  value,
  className,
  isLoading,
}: LeasedItemsDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Skeleton className="mt-[3px] h-1 w-6" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-1" />
          <Skeleton className="mt-[3px] h-1 w-4" />
        </div>
      }
      value={
        value?.length && (
          <div className="flex-col space-y-4">
            {value.map((x, i) => (
              <div key={i} className="flex items-center space-x-2">
                <span>{x.name}</span>{" "}
                <span>
                  ({x.start_date} - {x.end_date})
                </span>
              </div>
            ))}
          </div>
        )
      }
      className={className}
    />
  );
};

interface UnitDetailProps extends DetailProps<UnitSummary | null> {}

const UnitDetail = ({
  label,
  value,
  className,
  isLoading,
}: UnitDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="unit" />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <MiddleDot />
          <Skeleton className="mt-[3px] h-1 w-10" />
          <MiddleDot />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <MiddleDot />
          <Skeleton className="mt-[3px] h-1 w-2" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <MiddleDot />
          <Skeleton className="mt-[3px] h-1 w-2" />
          <Skeleton className="mt-[3px] h-1 w-4" />
        </div>
      }
      value={
        value?.unit_number && (
          <div className="flex items-center space-x-2">
            <Icon icon="unit" />
            <span>{value.unit_number}</span>
            {value.floor_plan && (
              <>
                <MiddleDot />
                <span>{value.floor_plan}</span>
              </>
            )}
            {value.square_feet && (
              <>
                <MiddleDot />
                <span>{value.square_feet} sqft</span>
              </>
            )}
            {value.num_bedrooms && (
              <>
                <MiddleDot />
                <span>{value.num_bedrooms} bd</span>
              </>
            )}
            {value.num_bathrooms && (
              <>
                <MiddleDot />
                <span>{value.num_bathrooms} ba</span>
              </>
            )}
          </div>
        )
      }
      className={className}
    />
  );
};

interface VehiclesDetailProps extends DetailProps<ResidentVehicle[] | null> {}

const VehiclesDetail = ({
  label,
  value,
  className,
  isLoading,
}: VehiclesDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="car" />
          <Skeleton className="mt-[3px] h-1 w-8" />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <MiddleDot />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <MiddleDot />
          <Skeleton className="mt-[3px] h-1 w-8" />
        </div>
      }
      value={
        value?.length && (
          <div className="flex-col space-y-4">
            {value?.map((x, i) => (
              <div key={i} className="flex items-center space-x-2">
                <Icon icon="car" />
                <span>
                  {" "}
                  {x.make} {x.model}
                </span>
                <div className="flex items-center space-x-2 text-muted-foreground">
                  {x.color && (
                    <>
                      <MiddleDot />
                      <span>{humanize(x.color)}</span>
                    </>
                  )}
                  {x.license_plate_number && (
                    <>
                      <MiddleDot />
                      <span>{x.license_plate_number}</span>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        )
      }
      className={className}
    />
  );
};

const LeaseCompletionStatusDetail = ({
  label,
  value,
  className,
  isLoading,
}: DetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      value={
        value && (
          <div className="flex items-center space-x-2">
            {/* TODO: Icon */}
            <span>{value}</span>
          </div>
        )
      }
      className={className}
    />
  );
};

const ApplicationStatusDetail = ({
  label,
  value,
  className,
  isLoading,
}: DetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="progress-zero" />
          <Skeleton className="mt-[3px] h-1 w-4" />
        </div>
      }
      value={
        value && (
          <div className="flex items-center space-x-2">
            <StatusIcon status={value as Status} />
            <span>{value}</span>
          </div>
        )
      }
      className={className}
    />
  );
};

const ScreeningStatusDetail = ({
  label,
  value,
  className,
  isLoading,
}: DetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      value={
        value && (
          <div className="flex items-center space-x-2">
            {/* TODO: Icon */}
            <span>{value}</span>
          </div>
        )
      }
      className={className}
    />
  );
};

interface OccupancyStatusDetailProps extends DetailProps {
  value?: OccupancyStatus | null;
}

const OccupancyStatusDetail = ({
  label,
  value,
  className,
  isLoading,
}: OccupancyStatusDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      value={
        value && (
          <div className="flex items-center space-x-2">
            {/* TODO: Icon */}
            <span>{value}</span>
          </div>
        )
      }
      className={className}
    />
  );
};

interface MakeReadyStatusDetailProps extends DetailProps {
  value?: MakeReadyStatus | null;
}

const MakeReadyStatusDetail = ({
  label,
  value,
  className,
  isLoading,
}: MakeReadyStatusDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      value={
        value && (
          <div className="flex items-center space-x-2">
            {/* TODO: Icon */}
            <span>{value}</span>
          </div>
        )
      }
      className={className}
    />
  );
};

interface AvailabilityStatusDetailProps extends DetailProps {
  value?: AvailabilityStatus | null;
}

const AvailabilityStatusDetail = ({
  label,
  value,
  className,
  isLoading,
}: AvailabilityStatusDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      value={
        value && (
          <div className="flex items-center space-x-2">
            {/* TODO: Icon */}
            <span>{value}</span>
          </div>
        )
      }
      className={className}
    />
  );
};

interface AssigneeDetailProps
  extends DetailProps<{
    full_name?: string | null | undefined;
    id: string | null;
    email_address?: string | null | undefined;
    avatar_url?: string | null | undefined;
  }> {}

const AssigneeDetail = ({
  label,
  value,
  className,
  isLoading,
}: AssigneeDetailProps) => {
  return (
    <div className="flex-col space-y-1">
      <Detail
        label={label}
        isLoading={isLoading}
        loadingElement={
          <div className="text-muted-foreground flex items-center space-x-2">
            <Skeleton className="mt-[3px] h-5 w-5 rounded-full" />
            <Skeleton className="mt-[3px] h-1 w-10" />
            <Skeleton className="mt-[3px] h-1 w-8" />
          </div>
        }
        value={value && <Assignee assignee={value} editable={false} />}
        className={className}
      />
    </div>
  );
};

interface AvailabilitySummaryDetailProps
  extends DetailProps<{
    available_date?: string | null;
    move_in_date?: string | null;
    make_ready_status?: MakeReadyStatus | null;
  }> {}

const AvailabilitySummaryDetail = ({
  label,
  value,
  className,
  isLoading,
}: AvailabilitySummaryDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex-col space-y-4">
          <div className="flex space-x-2 items-center">
            <Icon className="mr-1" icon="clock" /> Move in:{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
            <Skeleton className="mt-[3px] h-1 w-6" />
          </div>
          <div className="flex space-x-2 items-center">
            <Icon className="mr-1" icon="clock" /> Available:{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
            <Skeleton className="mt-[3px] h-1 w-6" />
          </div>
          <div className="flex space-x-2 items-center">
            <Icon className="mr-1" icon="progress-zero" /> Make ready:{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
            <Skeleton className="mt-[3px] h-1 w-8" />
          </div>
        </div>
      }
      value={
        value && (
          <div className="flex-col space-y-4">
            <div className="flex space-x-2 items-center">
              <Icon className="mr-1" icon="clock" /> Move in:{" "}
              {value.move_in_date ? formatDate(value.move_in_date) : "--"}
            </div>
            <div className="flex space-x-2 items-center">
              <Icon className="mr-1" icon="clock" /> Available:{" "}
              {value.available_date ? formatDate(value.available_date) : "--"}
            </div>
            <div className="flex space-x-2 items-center">
              <Icon
                className="mr-1"
                icon={
                  value.make_ready_status === "Ready"
                    ? "progress-done"
                    : "progress-two"
                }
              />{" "}
              Make ready: {value.make_ready_status}
            </div>
          </div>
        )
      }
      className={className}
    />
  );
};

interface ApplicationProgressDetailProps extends DetailProps<StatusChange[]> {}
const ApplicationProgressDetail = ({
  label,
  value,
  className,
  isLoading,
}: ApplicationProgressDetailProps) => {
  return (
    <Detail
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="clock" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-3" />
        </div>
      }
      label={label}
      value={
        value && (
          <div className="flex-col space-y-4">
            {value.map((x, i) => (
              <div key={i} className="flex items-center space-x-2">
                <Icon icon="clock" />
                <span>{x.status}:</span>
                <span>
                  {x.change_datetime ? formatDate(x.change_datetime) : "--"}
                </span>
              </div>
            ))}
          </div>
        )
      }
      className={className}
    />
  );
};

interface LeaseSummaryDetailsProps extends DetailProps<LeaseSummary | null> {}

const LeaseSummaryDetails = ({
  label,
  value,
  className,
  isLoading,
}: LeaseSummaryDetailsProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="applications" />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <Skeleton className="mt-[3px] h-1 w-2" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-3" />
          <MiddleDot />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-4" />
        </div>
      }
      value={
        value && (
          <div className="flex items-center space-x-2">
            <Icon icon="applications" />
            {value.start_date && value.end_date && (
              <span>
                {formatDate(value.start_date)} - {formatDate(value.end_date)}
              </span>
            )}
          </div>
        )
      }
      className={className}
    />
  );
};

interface ChargesDetailProps extends DetailProps<Charge[] | null> {}

const ChargesDetail = ({
  label,
  value,
  className,
  isLoading,
}: ChargesDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="billing" />
          <Skeleton className="mt-[3px] h-1 w-8" />
          <Skeleton className="mt-[3px] h-1 w-10" />
        </div>
      }
      value={
        value && (
          <div className="flex-col space-y-4">
            {value.map((x, i) => (
              <div key={i} className="flex items-center space-x-2">
                <Icon icon="billing" />
                <span>
                  {x.description}: {x.amount ? formatCurrency(x.amount) : "--"}
                </span>
              </div>
            ))}
          </div>
        )
      }
      className={className}
    />
  );
};

interface RenewalDetailProps extends DetailProps<Renewal | null> {}

const RenewalDetail = ({
  label,
  value,
  className,
  isLoading,
}: RenewalDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <Icon icon="billing" />
            <span>Best offer rate:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Renewal concessions:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Times offer renewal sent:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
        </div>
      }
      value={
        value && (
          <div className="flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <Icon icon="billing" />
              <span>
                Best offer rate:{" "}
                {value.best_offer_rate
                  ? formatCurrency(value.best_offer_rate)
                  : "--"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span>
                Renewal concessions: {value.has_concessions ? "Yes" : "No"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span>
                Times offer renewal sent:{" "}
                {value.number_of_times_offer_sent ?? "--"}
              </span>
            </div>
          </div>
        )
      }
      className={className}
    />
  );
};

interface MoveOutDetailProps
  extends DetailProps<
    | (surface_core__api__entities__MoveOut & {
        move_out_date?: string | null;
        reason?: string | null;
        forwarding_address?: string | null;
      })
    | null
  > {}
const MoveOutDetail = ({
  label,
  value,
  className,
  isLoading,
}: MoveOutDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <Icon icon="clock" />
            <span>Move out:</span> <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Reason:</span> <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Forwarding address:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
        </div>
      }
      value={
        value && (
          <div className="flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <Icon icon="clock" />
              <span>
                Move out:{" "}
                {value.move_out_date ? formatDate(value.move_out_date) : "--"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span>Reason: {value.reason ?? "--"}</span>
            </div>
            <div className="flex items-center space-x-2">
              <span>
                Forwarding address: {value.forwarding_address ?? "--"}
              </span>
            </div>
          </div>
        )
      }
      className={className}
    />
  );
};

interface PaymentSummaryDetailProps
  extends DetailProps<{
    number_of_late_payments?: number | null;
    number_of_waived_late_fees?: number | null;
    total_amount_of_waived_late_fees?: number | null;
    number_of_evictions?: number | null;
    allowed_payment_types?: string[] | null;
  } | null> {}

const PaymentSummaryDetail = ({
  label,
  value,
  className,
  isLoading,
}: PaymentSummaryDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <span>Allowed payment types:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Late payments:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Late fees waived:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Times evicted:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
        </div>
      }
      value={
        value && (
          <div className="flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <span>
                Allowed payment types:{" "}
                {value.allowed_payment_types?.join(", ") ?? "--"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span>
                Late payments: {value.number_of_late_payments ?? "--"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span>
                Late fees waived: {value.number_of_waived_late_fees ?? "--"}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span>Times evicted: {value.number_of_evictions ?? "--"}</span>
            </div>
          </div>
        )
      }
      className={className}
    />
  );
};

interface MaintenanceSummaryDetailProps
  extends DetailProps<{
    open_work_order_count?: number | null;
    completed_work_order_count?: number | null;
  }> {}

const MaintenanceSummaryDetail = ({
  label,
  value,
  className,
  isLoading,
}: MaintenanceSummaryDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <Icon icon="maintenance" />
            <span>Open:</span>
            <Skeleton className="mt-[3px] h-1 w-8" />
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Completed:</span>
            <Skeleton className="mt-[3px] h-1 w-8" />
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
        </div>
      }
      value={
        value && (
          <div className="flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <span>Open: {value.open_work_order_count ?? "--"}</span>
            </div>
            <div className="flex items-center space-x-2">
              <span>Completed: {value.completed_work_order_count ?? "--"}</span>
            </div>
          </div>
        )
      }
      className={className}
    />
  );
};

interface AddressDetailProps extends DetailProps<Address | null> {}

const AddressDetail = ({
  label,
  value,
  className,
  isLoading,
}: AddressDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="location" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-6" />
        </div>
      }
      value={
        value && (
          <div className="flex items-center space-x-2">
            <Icon icon="location" />
            <span>
              {`${value?.address1}, ${value?.city}, ${value?.state_or_province} ${value?.country} ${value?.postal_code}`}
            </span>
          </div>
        )
      }
      className={className}
    />
  );
};

interface ResidentActivityDetailProps extends DetailProps<ResidentActivity[] | null> {}

const ResidentActivityDetail = ({
  label,
  value,
  className,
  isLoading,
}: ResidentActivityDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex items-center space-x-2">
          <Icon icon="document" />
          <Skeleton className="mt-[3px] h-1 w-8" />
          <Skeleton className="mt-[3px] h-1 w-10" />
        </div>
      }
      value={
        Array.isArray(value) && value.length > 0 && (
          <div className="flex-col space-y-4">
            {value.map((activity, idx) => (
              <div key={idx} className="flex items-center space-x-2">
                <Icon icon={getActivityIcon(activity)} />
                <span>
                  {activity.description}
                </span>
              </div>
            ))}
          </div>
        ) 
      }
      className={className}
    />
  );
};

interface ResidentStatusDetailProps extends DetailProps<ResidentAccount | null> {}

const ResidentStatusDetail = ({
  label,
  value,
  className,
  isLoading,
}: ResidentStatusDetailProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <span>Rent relief:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>In escrow:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Do not renew:</span>{" "}
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
        </div>
      }
      value={
        value && (
          <div className="flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <span>Rent relief: {value.isRentRelief ? "Yes" : "No"}</span> 
            </div>
            <div className="flex items-center space-x-2">
              <span>In escrow: {value.isInEscrow ? "Yes" : "No"}</span>
            </div>
            <div className="flex items-center space-x-2">
              {/* TODO This isn't a defined field, do we infer it? */}
              <span>Do not renew: {value.isWithCollectionAgency ? "Yes" : "No"}</span>
            </div>
          </div>
        )
      }
      className={className}
    />
  );
};

interface LeaseHistoryDetailsProps extends DetailProps<LeaseSummary[] | null> {}

const LeaseHistoryDetail = ({
  label,
  value,
  className,
  isLoading,
}: LeaseHistoryDetailsProps) => {
  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="flex-col space-y-4">
          <div className="text-muted-foreground flex items-center space-x-2">
            <Icon icon="document" />
            <Skeleton className="mt-[3px] h-1 w-8" />
            <Skeleton className="mt-[3px] h-1 w-10" />
          </div>
          <div className="text-muted-foreground flex items-center space-x-2">
            <Icon icon="clock" />
            <Skeleton className="mt-[3px] h-1 w-8" />
            <Skeleton className="mt-[3px] h-1 w-10" />
          </div>
        </div>
      }
      value={
        Array.isArray(value) && value.length > 0 && (
          <div className="flex-col space-y-4">
            {value.map((lease, idx) => {
              const leasePeriod = getTimePeriod(lease?.start_date, lease?.end_date);
              const residentPeriod = getTimePeriod(lease?.move_in_date, new Date().toISOString());
              const startDate = lease.start_date ? formatDate(lease.start_date) : "--"
              const endDate = lease.end_date ? formatDate(lease.end_date) : "--"
              const moveInDate = lease.move_in_date ? formatDate(lease.move_in_date) : "--"

              return (
                <div className="flex-col space-y-4" key={idx}>
                  <div className="flex items-center space-x-2">
                    <Icon icon="document" />
                    <span>
                      {isLoading ? (
                        <Skeleton className="mt-[3px] h-1 w-4" />
                      ) : (
                        <span>
                          {leasePeriod} lease: {startDate} - {endDate}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Icon icon="clock" />
                    <span>
                      {isLoading ? (
                        <Skeleton className="mt-[3px] h-1 w-4" />
                      ) : (
                        <div className="flex items-center space-x-2">
                          <div className="leading-5">
                            Move in: {moveInDate}
                          </div>
                          <span className="flex items-center space-x-2 text-muted-foreground">
                            <MiddleDot />
                            <span>Resident for {residentPeriod} </span>
                          </span>
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        )
      }
      className={className}
    />
  );
};

interface BalanceDetailProps extends DetailProps<{
  account_balance: number;
  last_payment_date?: string | null;
} | null> {}

const BalanceDetail = ({
  label,
  value,
  className,
  isLoading,
}: BalanceDetailProps) => {
  const lastPaymentDate = value?.last_payment_date ? formatDate(value?.last_payment_date, { month: 'long', day: 'numeric' }) : "--";
  const balanceSince = getTimePeriod(value?.last_payment_date, new Date().toISOString());

  return (
    <Detail
      label={label}
      isLoading={isLoading}
      loadingElement={
        <div className="text-muted-foreground flex-col space-y-4">
          <div className="flex items-center space-x-2">
            <Icon icon="maintenance" />
            <span>Open:</span>
            <Skeleton className="mt-[3px] h-1 w-8" />
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
          <div className="flex items-center space-x-2">
            <span>Completed:</span>
            <Skeleton className="mt-[3px] h-1 w-8" />
            <Skeleton className="mt-[3px] h-1 w-4" />
          </div>
        </div>
      }
      value={
        value && (
          <div className="flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <Icon icon="billing" />
              <span>
                {isLoading ? (
                  <Skeleton className="mt-[3px] h-1 w-4" />
                ) : (
                  <span>
                    Current balance: {formatCurrency(value.account_balance)}
                  </span>
                )}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Icon icon="clock" />
              <span>
                {isLoading ? (
                  <Skeleton className="mt-[3px] h-1 w-4" />
                ) : (
                  <div className="flex items-center space-x-2">
                    <div className="leading-5">
                      Balance since: {lastPaymentDate}
                    </div>
                    {balanceSince && (
                      <span className="flex items-center space-x-2 text-muted-foreground">
                        <MiddleDot />
                        <span>{balanceSince}</span>
                      </span>
                    )}
                  </div>
                )}
              </span>
            </div>
          </div>
        )
      }
      className={className}
    />
  );
};

interface WorkOrderHistoryProps extends DetailProps<WorkOrderSummary[]> {}

const WorkOrderHistoryDetail = ({
  label,
  isLoading,
  value
}: WorkOrderHistoryProps) => {
  const MAX_ITEMS = 3;
  const getValue = () => {
    if (isLoading) {
      return (
        <div className="text-muted-foreground flex items-center space-x-2">
          <Skeleton className="mt-[3px] h-1 w-6" />
          <Skeleton className="mt-[3px] h-1 w-4" />
          <Skeleton className="mt-[3px] h-1 w-6" />
          <Skeleton className="mt-[3px] h-1 w-4" />
        </div>
      );
    } else if (!value?.length) {
      return null;
    }
    const { openWorkOrders, completedWorkOrders } = value?.reduce(
      (acc, x) => {
        if (x.status === "Completed") {
          acc.completedWorkOrders.push(x);
        } else {
          acc.openWorkOrders.push(x);
        }
        return acc;
      },
      { openWorkOrders: [], completedWorkOrders: [] } as {
        openWorkOrders: WorkOrderSummary[];
        completedWorkOrders: WorkOrderSummary[];
      }
    );

    return (
      <div className="flex-col space-y-4 w-full">
        {openWorkOrders.length > 0 && (
          <div className="flex space-x-2">
            <div>
              <div className="bg-gray-600  p-1.5 rounded-sm mt-1.5" />
            </div>
            <div className="flex-col space-y-2 w-full">
              <div className="flex space-x-2 items-center">
                <span>Open work orders:</span>
              </div>
              {openWorkOrders
                .sort((a, b) => {
                  // Newest first
                  if (a.date_opened && b.date_opened) {
                    return (
                      new Date(b.date_opened).getTime() -
                      new Date(a.date_opened).getTime()
                    );
                  } else {
                    return 0;
                  }
                })
                .slice(0, MAX_ITEMS)
                .map((x, i) => {
                  return (
                    <div>
                      <div
                        key={i}
                        className="flex items-center justify-between"
                      >
                        <span># {x.id}</span>
                        <span>
                          Created:{" "}
                          {x.date_opened && formatDateTime(x.date_opened)}
                        </span>
                      </div>
                      <div>{x.category}</div>
                      <div>{x.issue}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {completedWorkOrders.length > 0 && (
          <div className="flex space-x-2">
            <div>
              <div className="bg-gray-600  p-1.5 rounded-sm mt-1.5" />
            </div>
            <div className="flex-col space-y-2 w-full">
              <div className="flex space-x-2 items-center">
                <span>Completed work orders:</span>
              </div>
              {completedWorkOrders
                .sort((a, b) => {
                  // Newest first
                  if (a.date_closed && b.date_closed) {
                    return (
                      new Date(b.date_closed).getTime() -
                      new Date(a.date_closed).getTime()
                    );
                  } else {
                    return 0;
                  }
                })
                .slice(0, MAX_ITEMS)
                .map((x, i) => {
                  return (
                    <div>
                      <div
                        key={i}
                        className="flex items-center justify-between"
                      >
                        <span># {x.id}</span>
                        <span>
                          Completed:{" "}
                          {x.date_closed && formatDateTime(x.date_closed)}
                        </span>
                      </div>
                      <div>
                        Created date:{" "}
                        {x.date_opened && formatDate(x.date_opened)}
                      </div>
                      <div>{x.category}</div>
                      <div>{x.issue}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      <div className="text-muted-foreground mb-4">
        {label || "Resident maintenance history"}
      </div>
      {getValue()}
    </div>
  );
};

export {
  Detail,
  AddressDetail,
  DateDetail,
  PersonsDetail,
  UnitDetail,
  PetsDetail,
  LeasedItemsDetail,
  VehiclesDetail,
  LeaseCompletionStatusDetail,
  ApplicationStatusDetail,
  ScreeningStatusDetail,
  OccupancyStatusDetail,
  MakeReadyStatusDetail,
  AvailabilityStatusDetail,
  AssigneeDetail,
  AvailabilitySummaryDetail,
  ApplicationProgressDetail,
  LeaseSummaryDetails,
  ChargesDetail,
  RenewalDetail,
  MoveOutDetail,
  PaymentSummaryDetail,
  MaintenanceSummaryDetail,
  YesNoDetail,
  ResidentActivityDetail,
  ResidentStatusDetail,
  LeaseHistoryDetail,
  BalanceDetail,
  WorkOrderHistoryDetail,
};
