import { Policy } from "api-client";
import { Detail } from "../ui/drawer-details";
import ReactMarkdown from "react-markdown";
import { API_ROOT } from "../../constants";
import { humanize } from "../../lib/utils";

interface PolicyDetailsProps {
  item: Policy;
  dialogueId?: string | null;
  sourceId?: string | null;
}

const PolicyDetails = ({ item, dialogueId, sourceId }: PolicyDetailsProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <Detail
        label="Source"
        value={
          <a
            href={`${API_ROOT}/api/ask/${dialogueId}/sources/${sourceId}`}
            className="text-primary"
            download={`${humanize(item.name)}.pdf`}
          >
            {`${humanize(item.name)}.pdf`}
          </a>
        }
      />
      <hr />
      <Detail
        label="Relevant excerpts"
        value={item.text && <ReactMarkdown>{item.text}</ReactMarkdown>}
      />
    </div>
  );
};

export { PolicyDetails };
