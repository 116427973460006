import { createColumnHelper } from "@tanstack/react-table";
import { Task, WorkOrder } from "api-client";
import { TableDef, optionalCell, optionalDateCell, unitCell } from "./utils";

const serviceRequestTaskColumnHelper = createColumnHelper<Task>();

const createServiceRequestTableDefs = (
  properties: { id: string; name: string }[]
): TableDef => {
  const propertyNamesById = properties.reduce(
    (acc, property) => {
      acc[property.id] = property.name;
      return acc;
    },
    {} as Record<string, string>
  );

  return {
    columns: [
      {
        id: "property_name",
        header: "Property",
        accessorFn: (row: Task) => {
          return propertyNamesById[row.property_id];
        },
        cell: optionalCell,
      },
      {
        id: "priority",
        header: "Priority",
        accessorFn: (row: Task) => {
          return (row.entity as WorkOrder).priority;
        },
        cell: optionalCell,
      },
      {
        id: "description",
        header: "Description",
        accessorFn: (row: Task) => {
          return (row.entity as WorkOrder).description;
        },
        cell: optionalCell,
      },
      {
        id: "date_opened",
        header: "Date Opened",
        accessorFn: (row: Task) => {
          return (row.entity as WorkOrder).created_date;
        },
        cell: optionalDateCell,
      },
      serviceRequestTaskColumnHelper.accessor("unit_summary.unit_number", {
        id: "unit_number",
        header: "Unit",
        cell: unitCell,
      }),
    ],
    staticColumns: [
      "property_name",
      "priority",
      "description",
      "date_opened",
      "unit_number",
    ],
    rowId: "id",
  };
};

export { createServiceRequestTableDefs };
