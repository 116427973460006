import { useAuth } from "@/lib/auth";
import { useFeatureFlag as useConfigCatFeatureFlag } from "configcat-react";

const useFeatureFlag = (flag: string, defaultValue: boolean) => {
  const { user } = useAuth();
  const configCatUser = user ? { identifier: user.id, email: user.email, custom : {
    saas_tenant_id: user.saas_tenant_id
  } } : undefined;

  const { value, loading } = useConfigCatFeatureFlag(
    flag,
    defaultValue,
    configCatUser
  );
  return { value, loading };
};

export { useFeatureFlag };