const UserOnboarding = () => {
  return (
    <div className="w-full flex">
      <div className="bg-primaryGradientBlueTeal h-screen flex-shrink-0 w-[calc(100%-45rem)] p-8">
        <h3 className="text-3xl font-medium text-white animate-fade-in-up">
          Some of the things you can do with Ask
        </h3>
        <ol className="text-white text-md flex flex-col gap-4 mt-4">
          <li>Instantly summarize resident information across systems</li>
          <li>Draft policy-compliant communications</li>
          <li>Generate insights</li>
          <li>Much more! Just Ask...</li>
        </ol>
      </div>
      <div className="w-[45rem] flex flex-col items-center justify-center shadow-lg">
        {/* NOTE: this is a placeholder for the extension which auto-opens  */}
      </div>
    </div>
  );
};

export { UserOnboarding };
