import { CellContext, ColumnDef } from "@tanstack/react-table";
import { formatDate } from "../../../lib/utils";
import Clock from "../../../assets/img/clock.svg";
import UnitIcon from "../../../assets/img/unit.svg";
import Person from "../../../assets/img/person.svg";
import clsx from "clsx";
import { StatusIcon } from "surface-components";

type TableDef = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a generic utility
  columns: ColumnDef<any, any>[];
  staticColumns: string[];
  rowId: string;
};

const optionalCell = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a generic utility
  cell: CellContext<any, any | null | undefined>
): unknown => {
  return cell.getValue() ?? "--";
};

const optionalDateCell = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a generic utility
  cell: CellContext<any, any | null | undefined>
): unknown => {
  const value = cell.getValue();
  return (
    <div className={clsx("flex space-x-1 items-center")}>
      <img src={Clock} />
      <span>{value ? formatDate(value) : "--"}</span>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a generic utility
const unitCell = (cell: CellContext<any, any | null | undefined>): unknown => {
  return (
    <div className="flex space-x-1 items-center">
      <span>
        <img src={UnitIcon} />
      </span>
      <span>{cell.getValue()}</span>
    </div>
  );
};

const personCell = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a generic utility
  cell: CellContext<any, any | null | undefined>
): unknown => {
  const getValue = () => {
    const value = cell.getValue();
    if (!value) {
      return "--";
    } else if (Array.isArray(value)) {
      const primary = value[0] || "";
      const additional = value.slice(1);
      return `${primary}${additional.length ? ` + ${additional.length}` : ""}`;
    } else {
      return value;
    }
  }
  return (
    <div className="flex space-x-1 items-center">
      <span>
        <img src={Person} />
      </span>
      <span>{getValue()}</span>
    </div>
  );
};

const applicationStatusCell = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a generic utility
  cell: CellContext<any, any | null | undefined>
): unknown => {
  return (
    <div className="flex space-x-1 items-center">
      <StatusIcon status={cell.getValue()} />
      <span>{cell.getValue()}</span>
    </div>
  );
};

const addressCell = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is a generic utility
  cell: CellContext<any, any | null | undefined>
): unknown => {
  const address = cell.getValue();
  if (!address) {
    return "-";
  }
  
  let addressString = `${address.address1}, ${address.city}`;
  if (address.state_or_province) {
    addressString += `, ${address.state_or_province}`;
  }
  if (address.country) {
    addressString += ` ${address.country}`;
  }
  if (address.postal_code) {
    addressString += ` ${address.postal_code}`;
  }
  return addressString;
}

export {
  optionalCell,
  optionalDateCell,
  unitCell,
  personCell,
  applicationStatusCell,
  addressCell,
};
export type { TableDef };
