import { Unit } from "api-client";
import {
  AvailabilityStatusDetail,
  DateDetail,
  Detail,
  MakeReadyStatusDetail,
  OccupancyStatusDetail,
  PetsDetail,
} from "../ui/drawer-details";

interface UnitDetailsProps {
  item: Unit;
}

const UnitDetails = ({ item }: UnitDetailsProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <Detail label="Property" value={item.property_name} />
      <hr />
      <Detail label="Building" value={item.building_name} />
      <hr />
      <Detail label="Floor Number" value={item.floor_number} />
      <hr />
      <Detail label="Floor Plan" value={item.floor_plan_name} />
      <hr />
      <Detail label="Bedrooms" value={item.number_of_bedrooms} />
      <hr />
      <Detail label="Bathrooms" value={item.number_of_bathrooms} />
      <hr />
      <Detail label="Square Footage" value={item.square_footage} />
      <hr />
      <Detail label="Furnished" value={item.is_furnished} />
      <hr />
      <Detail label="Amenities" value={item.amenities?.join(", ")} />
      <hr />
      <OccupancyStatusDetail
        label="Occupancy Status"
        value={item.occupancy_status}
      />
      <hr />
      <MakeReadyStatusDetail
        label="Make Ready Status"
        value={item.make_ready_status}
      />
      <hr />
      <AvailabilityStatusDetail
        label="Availability Status"
        value={item.availability_status}
      />
      <hr />
      <PetsDetail label="Pets" value={item.pets} />
      <hr />
      <DateDetail label="Available Date" value={item.available_date} />
      <hr />
      <DateDetail label="Move In Date" value={item.next_move_in_date} />
      <hr />
      <DateDetail label="Move Out Date" value={item.move_out_date} />
    </div>
  );
};

export { UnitDetails };
