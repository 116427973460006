import { TasksList } from "@/components/task-list";
import { useAuth } from "@/lib/auth";
import {
  getEntityName,
  TaskType,
  useSelectedTask,
  useTaskFacets,
  useTasks,
  useTaskTypeFilter,
} from "@/lib/hooks/use-tasks";
import { useUserProperties } from "@/lib/hooks/use-user-properties";

const Priorities = () => {
  const { user } = useAuth();
  const selectedType = useTaskTypeFilter();
  const {
    selectedTask,
    selectTask,
    clearSelectedTask,
    isLoading: isLoadingSelectedTask,
  } = useSelectedTask();
  const { selectProperties, selectedPropertyIds } = useUserProperties();
  const {
    groups,
    fetchPage,
    updateSort,
    isLoading: isLoadingTaskList,
    assignedToFilter,
    setAssignedToFilter,
    downloadUrl,
  } = useTasks();

  const { facets, isLoading: loadingFacets } = useTaskFacets();
  const facetsByType = facets?.reduce(
    (acc, facet) => {
      acc[facet.type] = facet;
      return acc;
    },
    {} as Record<
      string,
      {
        type: string;
        high: number;
        medium: number;
        low: number;
        total: number;
        completed: number;
        not_completed: number;
      }
    >
  );

  if (!user) {
    return null;
  }
  return (
    <TasksList
      user={user}
      title={selectedType ? getEntityName(selectedType) : "Top Priorities"}
      greeting={selectedType ? "" : `Hello, ${user.first_name}`}
      isLoadingList={isLoadingTaskList || loadingFacets}
      propertyIds={selectedPropertyIds}
      setPropertyIds={selectProperties}
      downloadUrl={downloadUrl}
      taskGroups={groups.map((group) => {
        return {
          key: group.key,
          title: group.title,
          filters: group.filters,
          loading: group.loading,
          type: group.filters.type as TaskType,
          page: group.page,
          page_size: group.page_size,
          total: group.total,
          tasks: group.tasks || [],
          facet: facetsByType?.[group.key],
        };
      })}
      isLoadingSelectedTask={isLoadingSelectedTask}
      selectedTask={selectedTask}
      assignedToFilter={assignedToFilter}
      selectTask={selectTask}
      clearSelectedTask={clearSelectedTask}
      setAssignedToFilter={setAssignedToFilter}
      fetchPage={fetchPage}
      updateSort={updateSort}
    />
  );
};

export { Priorities };
