import {
  QuerySuggestionCta,
  CardWithTitle,
  CardVariants,
} from "surface-components";
import { useAskAndPoll } from "@/lib/hooks/use-ask-and-poll";

type GreetingCardProps = {
  greeting?: string;
};

export const DEFAULT_ASKS = [
  {
    domain: "Applications",
    question: "What are the move in dates for all upcoming move ins?",
  },
  {
    domain: "Policies",
    question: "What is our policy for late fee forgiveness?",
  },
];

export const DEFAULT_DRAFTS = [
  {
    domain: "Maintenance",
    question:
      "Draft a note apologizing for a significant delay on a work order.",
  },
  {
    domain: "Policies",
    question:
      "Draft a note to a resident about third noise complaint received.",
  },
];

export const GreetingCard = ({ greeting }: GreetingCardProps) => {
  const { askQuestion } = useAskAndPoll();

  const handleQuerySuggestionCtaPressed = (query: string) => {
    askQuestion(query);
  };
  return (
    <CardWithTitle
      title={greeting || "Hello, what would you like to know?"}
      variant={CardVariants.system}
    >
      <div className="mb-4">
        <p className="text-zinc-900 text-base font-normal font-sans leading-normal">
          Ask questions about the property:
        </p>
        <div className="flex gap-2">
          {DEFAULT_ASKS.map(({ domain, question }, index) => (
            // NOTE: It's typically an anti-pattern to use index as a key, but in small lists without re-ordering it is acceptable
            <QuerySuggestionCta
              domain={domain}
              query={question}
              key={index}
              onQuerySuggestionClick={handleQuerySuggestionCtaPressed}
            />
          ))}
        </div>
      </div>
      <div>
        <p className="text-zinc-900 text-base font-normal font-sans leading-normal">
          Ask to draft:
        </p>
        <div className="flex gap-2">
          {DEFAULT_DRAFTS.map(({ domain, question }, index) => (
            // NOTE: It's typically an anti-pattern to use index as a key, but in small lists without re-ordering it is acceptable
            <QuerySuggestionCta
              domain={domain}
              query={question}
              key={index}
              onQuerySuggestionClick={handleQuerySuggestionCtaPressed}
            />
          ))}
        </div>
      </div>
    </CardWithTitle>
  );
};
