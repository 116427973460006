import {
  AnswerSource,
  Data,
  DefaultService,
  Dialogue,
  Resident360,
  Task,
} from "@/api/client";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { UpperNav } from "../components/ui/upper-nav";
import { MiddleDot } from "../components/ui/middle-dot";
import {
  Button,
  AnswerFormatter,
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from "surface-components";
import Download from "../assets/img/download.svg";
import Print from "../assets/img/print.svg";
import LogoIconBlue from "../assets/img/logo-icon-blue.svg";
import { API_ROOT } from "../constants";
import { timeAgo } from "../lib/utils";
import { useAuth } from "../lib/auth";
import { SourceTable } from "../components/source-table";
import { Drawer, DrawerContent, DrawerPortal } from "../components/ui/drawer";
import { EntityDetailsDrawer } from "../components/source-entity-details/drawer";
import { TaskDetailsDrawer } from "@/components/task-details/drawer";
import { ResidentDetailsDrawer } from "@/components/resident-details/drawer";
import { useResident } from "@/lib/hooks/use-resident";
import { useEffect, useState } from "react";

function isTask(item: Data["items"][0]): item is Task {
  return !!(item && item.type?.includes("Task"));
}

function isResident(item: Data["items"][0]): item is Resident360 {
  return !!(item && item.type === "Resident360");
}

const getSelectedDialogueSourceItem = (
  answerSources: AnswerSource[],
  sourceId: string | null,
  rowId: string | null
) => {
  const table = answerSources?.find((x) => x.id === sourceId);
  if (!table) {
    return null;
  }
  return table.data?.items?.find((x) => {
    if (isTask(x)) {
      return x.id === rowId;
    }
    switch (x.type) {
      case "Application":
        return x.application_id === rowId;
      case "Unit":
        return x.id === rowId;
      case "Resident360":
      case "Resident":
        return x.id === rowId;
      case "Policy":
        return x.name === rowId;
      case "FloorPlan":
        return x.id === rowId;
      case "Lease":
        return x.id === rowId;
      case "MoveIn":
        return x.unit_number === rowId;
      case "MoveOut":
        return x.unit_number === rowId;
      default:
        console.error("Unknown item type", x);
        return undefined;
    }
  });
};

const DialogueDetails = () => {
  const { dialogue } = useLoaderData() as { dialogue: Dialogue };

  const [answerSources, setAnswerSources] = useState<
    {
      isLoading: boolean;
      source: AnswerSource;
    }[]
  >(
    (dialogue.answer_sources || []).map((source) => {
      return {
        isLoading: true,
        source,
      };
    })
  );

  useEffect(() => {
    const fetchSources = async () => {
      Promise.all(
        answerSources?.map(({ source }) => {
          return DefaultService.askSourceItemsApiAskDialogueIdSourcesSourceIdItemsGet(
            dialogue.dialogue_id,
            source.id,
            null,
            null
          ).then((response) => {
            return {
              isLoading: false,
              source: {
                ...source,
                data: {
                  ...source.data,
                  items: response.items,
                },
              },
            };
          });
        }) || []
      ).then((newAnswerSources) => {
        setAnswerSources(newAnswerSources);
      });
    };
    fetchSources();
  }, []);

  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { resident, onResidentClick, isLoadingResident, clearResident } =
    useResident();
  const onClickRow = (source: string, id: string) => {
    setSearchParams((params) => {
      params.set("source", source);
      params.set("id", id);
      return params;
    });
  };
  const onClose = () => {
    clearResident();
    if (searchParams.get("source")) {
      setSearchParams(
        (params) => {
          params.delete("source");
          params.delete("id");
          return params;
        },
        { replace: true }
      );
    }
  };
  const selectedItem = getSelectedDialogueSourceItem(
    answerSources.map((x) => x.source),
    searchParams.get("source"),
    searchParams.get("id")
  );
  const getDrawer = () => {
    if (resident || (selectedItem && isResident(selectedItem))) {
      return (
        <ResidentDetailsDrawer
          onClickResident={onResidentClick}
          resident={resident || (selectedItem as Partial<Resident360>)}
          isLoading={isLoadingResident}
          onClose={onClose}
        />
      );
    } else if (!selectedItem) {
      return null;
    } else if (isTask(selectedItem)) {
      return <TaskDetailsDrawer task={selectedItem} onClose={onClose} />;
    } else {
      return (
        <EntityDetailsDrawer
          dialogueId={dialogue.dialogue_id}
          sourceId={searchParams.get("source")}
          item={selectedItem}
          onClose={onClose}
        />
      );
    }
  };

  const getQuestionPropertyName = (questionProperties: string[]) => {
    if (questionProperties.length === 1) {
      return user?.properties?.find((p) => p.id === questionProperties[0])
        ?.name;
    } else if (questionProperties.length === user?.properties?.length) {
      return "All Properties";
    } else {
      return `${questionProperties.length} Properties`;
    }
  };

  const propertyName = getQuestionPropertyName(dialogue.properties || []);
  return (
    <div className="dialogue-details h-screen overflow-hidden w-full bg-white">
      <UpperNav
        left={
          <div className="flex space-x-2">
            <span className="text-foreground">Ask</span>
            <MiddleDot />
            <span className="text-muted-foreground">Question</span>
          </div>
        }
        right={
          <div className="flex items-center space-x-2 text-muted-foreground">
            {propertyName && (
              <>
                <HoverCard>
                  <HoverCardTrigger>
                    <span className="text-left text-muted-foreground">
                      {propertyName}
                    </span>
                  </HoverCardTrigger>
                  <HoverCardContent side="top">
                    <ul className="text-left text-muted-foreground">
                      {dialogue?.properties?.map((id) => {
                        return (
                          <li key={id}>
                            {user?.properties?.find((p) => p.id === id)?.name}
                          </li>
                        );
                      })}
                    </ul>
                  </HoverCardContent>
                </HoverCard>
                <MiddleDot />
              </>
            )}
            {dialogue.created_at && (
              <span>Asked {timeAgo(new Date(dialogue.created_at))}</span>
            )}
            <Button
              className="text-foreground flex space-x-1"
              variant="outline"
            >
              <img src={Download} />
              <a
                href={`${API_ROOT}/api/ask/${dialogue.dialogue_id}/sources`}
                download={`ask-sources-${dialogue.dialogue_id}.zip`}
              >
                {" "}
                <span>Download</span>
              </a>
            </Button>
            <Button
              onClick={() => {
                window.print();
              }}
              className="text-foreground fle space-x-1"
              variant="outline"
            >
              <img src={Print} />
              <span>Print</span>
            </Button>
          </div>
        }
      />
      <div className="w-full container py-8 h-[calc(100%-32px)] overflow-scroll">
        <p className="text-xl text-foreground font-medium">
          {dialogue.user_prompt}
        </p>
        {dialogue.answer && (
          <div className="flex items-start space-x-2 mt-4">
            {user?.is_admin ? (
              <a
                href={`${API_ROOT}/api/admin/dialogues/${dialogue.dialogue_id}?saas_tenant_id=${user?.saas_tenant_id}`}
              >
                <img className="mt-0.5" src={LogoIconBlue} />
              </a>
            ) : (
              <img className="mt-0.5" src={LogoIconBlue} />
            )}
            <span className="text-foreground">
              <AnswerFormatter answerText={dialogue.answer} />
            </span>
          </div>
        )}
        {answerSources.map(({ source }) => {
          return (
            <div key={source.id} className="mt-8">
              <SourceTable
                answerSource={source}
                onClickRow={onClickRow}
                selectedSourceId={searchParams.get("source")}
                selectedRowId={searchParams.get("id")}
                properties={user?.properties}
              />
            </div>
          );
        })}
      </div>
      <Drawer
        direction="right"
        open={!!selectedItem}
        dismissible={false}
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
      >
        <DrawerPortal>
          <DrawerContent className="h-screen top-0 right-0 left-auto mt-0 w-[640px] overflow-y-scroll overflow-x-hidden focus-visible:outline-none">
            <div>{getDrawer()}</div>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    </div>
  );
};

export { DialogueDetails };
