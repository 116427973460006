import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  DefaultService,
  Dialogue as ApiDialogue,
  AnswerSource,
} from "@/api/client";
import { useAskAndPoll } from "@/lib/hooks/use-ask-and-poll";
import { useRefCallback } from "@/lib/hooks/use-ref-callback";
import { useGetHistory } from "@/lib/hooks/use-get-history";
import {
  continuationTokenState,
  dialogueHistoryState,
  greetingState,
  activeTabState,
  selectedSavedDialogueState,
  ActiveTabStateEnum,
} from "@/lib/recoil/state";

interface DialogueData {
  dialogue_id: string | null | undefined;
  question: string;
  loading: boolean;
  data: ApiDialogue;
  properties: string[];
  sources: AnswerSource[] | null | undefined;
}

const useAskContent = () => {
  const { dialogues, clearDialogueSession } = useAskAndPoll();
  useEffect(() => {
    return () => {
      clearDialogueSession();
    };
  }, []);
  const { refetch } = useGetHistory();
  const historyDialogues = useRecoilValue(dialogueHistoryState).filter(
    (dialogue) => dialogue.answer_status !== "cancelled"
  );
  const continuationToken = useRecoilValue(continuationTokenState);
  const [greeting, setGreeting] = useRecoilState(greetingState);
  const [newestCardRef, setNewestCardRef] = useRefCallback();
  const activeTab = useRecoilValue(activeTabState);
  const selectedSavedDialogue = useRecoilValue(selectedSavedDialogueState);
  const [savedDialogueData, setSavedDialogueData] = useState<DialogueData>();
  const [loadingSavedDialogues, setLoadingSavedDialogues] = useState(false);
  const [scrollToGreeting, setScrollToGreeting] = useState(true);
  const [scrollToNewHistory, setScrollToNewHistory] = useState(false);

  const greetingRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (greeting) return;
    DefaultService.greetingApiGreetingGet()
      .then((response) => setGreeting(response?.[0]?.greeting || ""))
      .catch((error) => {
        setGreeting("");
        console.error("Failed to fetch greeting:", error);
      });
  }, [greeting, setGreeting]);

  useEffect(() => {
    if (dialogues.length === 0) {
      setScrollToGreeting(true);
    } else {
      setScrollToGreeting(false);
    }
  }, [dialogues.length]);

  useLayoutEffect(() => {
    if (scrollToGreeting && greetingRef.current) {
      greetingRef.current.scrollIntoView({
        behavior: "instant",
        block: "end",
      });
    } else if (newestCardRef) {
      newestCardRef.scrollIntoView({ behavior: "instant", block: "end" });
    }
  }, [
    scrollToGreeting,
    newestCardRef,
    historyDialogues.length,
    activeTab,
    scrollToNewHistory,
  ]);

  useEffect(() => {
    if (activeTab === ActiveTabStateEnum.SavedAsks) {
      const fetchSavedDialogue = async () => {
        setLoadingSavedDialogues(true);
        try {
          const fetchedDialogue: DialogueData =
            await DefaultService.getDialogueApiAskDialogueIdGet(
              selectedSavedDialogue.dialogueId
            ).then((res) => ({
              dialogue_id: res.dialogue_id,
              question: res.user_prompt || selectedSavedDialogue.title,
              loading: false,
              data: res,
              properties: [], // Populate this with actual properties if available
              sources: res.answer_sources,
            }));
          // This call should be inside the async function
          setSavedDialogueData(fetchedDialogue);
        } catch (error) {
          console.error("Failed to fetch saved dialogues:", error);
        } finally {
          setLoadingSavedDialogues(false);
        }
      };
      fetchSavedDialogue();
    }
  }, [activeTab, selectedSavedDialogue]);

  const handleLoadMore = async () => {
    if (continuationToken) {
      await refetch();
    }
  };

  return {
    greetingRef,
    dialogues,
    historyDialogues,
    continuationToken,
    greeting,
    newestCardRef,
    setNewestCardRef,
    activeTab,
    savedDialogueData,
    loadingSavedDialogues,
    handleLoadMore,
    scrollToNewHistory,
    setScrollToNewHistory,
  };
};

export default useAskContent;
