import useSurfaceAskContent from "@/lib/hooks/use-surface-ask-content";
import SurfaceAskContentUI from "./surface-ask-content-ui";

const SurfaceAskContent = () => {
  const {
    greetingRef,
    dialogues,
    historyDialogues,
    continuationToken,
    greeting,
    newestCardRef,
    setNewestCardRef,
    activeTab,
    savedDialogueData,
    loadingSavedDialogues,
    handleLoadMore,
    scrollToNewHistory,
    setScrollToNewHistory,
  } = useSurfaceAskContent();

  return (
    <SurfaceAskContentUI
      greetingRef={greetingRef}
      dialogues={dialogues}
      historyDialogues={historyDialogues}
      continuationToken={continuationToken}
      greeting={greeting}
      newestCardRef={newestCardRef}
      setNewestCardRef={setNewestCardRef}
      activeTab={activeTab}
      savedDialogueData={savedDialogueData}
      loadingSavedDialogues={loadingSavedDialogues}
      handleLoadMore={handleLoadMore}
      scrollToNewHistory={scrollToNewHistory}
      setScrollToNewHistory={setScrollToNewHistory}
    />
  );
};

export default SurfaceAskContent;
