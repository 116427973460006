import { DefaultService, Dialogue } from "./client";

// Polling function to check for an answer
export async function pollQuestion(
  dialogueId: string,
    handlePollResponse?: (
    dialogueId: string,
    response: Dialogue,
  ) => void,
  interval: number = 1000,
  timeout: number = 20000,
  maxRetries: number = 5,
): Promise<Dialogue | null> {
  const startTime = Date.now();

  // Helper function to recursively poll for the answer
  const pollForAnswer = async (
    retries: number = 0,
    offset: number | null = null,
  ): Promise<Dialogue | null> => {
    if (Date.now() - startTime > timeout) {
      if (retries >= maxRetries) {
        // Max retries reached
        console.error("Polling max retries reached. Stopping polling.");
        return null;
      }
      console.log(`Timeout reached, retrying... Attempt ${retries + 1}`);
    }

    try {
      const pollResponse = await DefaultService.getDialogueApiAskDialogueIdGet(
        dialogueId,
        offset,
        1,
        3
      );

      if (pollResponse.answer_complete || pollResponse.answer_status === "cancelled") {
        return pollResponse;
      } else {
        if (
          pollResponse.answer_stream_chunk &&
          pollResponse.answer_stream_chunk.chunk_id !== offset &&
          handlePollResponse
        ) {
          handlePollResponse(
            pollResponse.dialogue_id!,
            pollResponse,
          );
        }
        // Wait for the next interval before polling again
        await new Promise((resolve) => setTimeout(resolve, interval));
        return pollForAnswer(
          retries,
          pollResponse.answer_stream_chunk?.next_chunk_id,
        );
      }
    } catch (error) {
      console.error("Error during polling:", error);
      // In case of an error, decide whether to retry or handle differently
      return pollForAnswer(retries + 1);
    }
  };

  // Initial call to poll for the answer
  return pollForAnswer();
}
