import { useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  dialogueSessionState,
  DialogueState,
  selectedPropertyIds as selectedPropertyIdsState,
} from "@/lib/recoil/state";
import { DefaultService, Dialogue } from "@/api/client";
import { pollQuestion } from "@/api/pollQuestion";
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga4";
import { useUserProperties } from "./use-user-properties";

export const postAsk = async (
  userInput: string,
  selectedPropertyIds: string[]
) => {

  let clientTimezone;

  try {
    clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    clientTimezone = 'UTC';
  }
  
  return DefaultService.askApiAskPost({
    user_prompt: userInput,
    timezone: clientTimezone,
    properties: selectedPropertyIds,
  });
};

export const pollQuestionMethod = async (
  dialogue_id: string,
  handlePollResponse?: (dialogueId: string, response: Dialogue) => void
) => {
  return pollQuestion(dialogue_id, handlePollResponse);
};

/**
 * Custom React hook for managing dialogues based on user inputs.
 *
 * This hook serves multiple purposes:
 * 1. It manages a list of dialogues, each containing a user's question,
 *    the API's response, loading state, and potential errors.
 * 2. It leverages Recoil state management to track and update the user's current input and
 *    the overall loading status of questions.
 * 3. It encapsulates the interaction with an external API to ask questions and poll responses,
 *    abstracting away the complexities of API communication and asynchronous state updates.
 */
export const useAskAndPoll = (api = { postAsk, pollQuestion }) => {
  const [dialogues, setDialogues] = useRecoilState(dialogueSessionState);
  const { properties: allProperties } = useUserProperties();
  const selectedPropertyIds = useRecoilValue(selectedPropertyIdsState);

  const loadingDialogue = useMemo(() => {
    return dialogues.find((dialogue) => dialogue.loading);
  }, [dialogues]);

  const addDialogue = (dialogue: DialogueState) => {
    setDialogues((dialogues) => [...dialogues, dialogue]);
  };

  const updateDialogueState = (
    dialogueId: string,
    state: Partial<DialogueState>
  ) => {
    setDialogues((dialogues) =>
      dialogues.map((dialogue) =>
        dialogue.id === dialogueId ? { ...dialogue, ...state } : dialogue
      )
    );
  };

  const handlePollResponse = (dialogueId: string, response: Dialogue) => {
    setDialogues((dialogues) => {
      return dialogues.map((dialogue) =>
        dialogue.dialogue_id === dialogueId
          ? {
              ...dialogue,
              sources: response.answer_sources ?? undefined,
              answerChunks:
                response.answer_stream_chunk &&
                dialogue.answerChunks?.[dialogue.answerChunks.length - 1]
                  ?.chunk_id !== response.answer_stream_chunk.chunk_id
                  ? [
                      ...(dialogue.answerChunks || []),
                      response.answer_stream_chunk,
                    ]
                  : dialogue.answerChunks,
            }
          : dialogue
      );
    });
  };

  const askQuestion = async (userInput: string) => {
    const properties = selectedPropertyIds.length
      ? selectedPropertyIds
      : allProperties.map((p) => p.id);

    const id = uuidv4();
    const startTime = Date.now();

    const newDialogue: DialogueState = {
      id,
      question: userInput,
      loading: false,
      properties,
      sources: [],
    };

    addDialogue(newDialogue);

    ReactGA.event({
      category: "Dialogue",
      action: "ask",
      label: "Ask Question",
      nonInteraction: true,
    });

    try {
      const response = await api.postAsk(userInput, properties);
      updateDialogueState(id, {
        dialogue_id: response.dialogue_id ?? undefined,
        question: userInput,
        sources: response.answer_sources ?? undefined,
        loading: true,
        properties,
      });

      const answer = await pollQuestionMethod(
        response.dialogue_id!,
        handlePollResponse
      );
      handleAnswer(answer, id, userInput, startTime);
    } catch (error) {
      handleError(error, id, userInput, startTime);
    }
  };

  const handleAnswer = (
    answer: Dialogue | null,
    id: string,
    userInput: string,
    startTime: number
  ) => {
    const endTime = Date.now();
    const duration = endTime - startTime;

    if (!answer) {
      updateDialogueState(id, {
        question: userInput,
        error:
          "It looks like our service is taking longer than usual to respond. Please try again later.",
        loading: false,
      });
      ReactGA.event(
        {
          category: "Dialogue",
          action: "ask.timeout",
          label: "Question Timeout",
          nonInteraction: true,
        },
        { duration }
      );
    } else {
      updateDialogueState(id, {
        question: userInput,
        data: answer,
        loading: false,
      });
      ReactGA.event(
        {
          category: "Dialogue",
          action: "ask.answer",
          label: "Answer Received",
          nonInteraction: true,
        },
        { duration }
      );
    }
  };

  const handleError = (
    error: unknown,
    id: string,
    userInput: string,
    startTime: number
  ) => {
    const endTime = Date.now();
    const duration = endTime - startTime;
    const errorMessage = error instanceof Error ? error.message : String(error);

    updateDialogueState(id, {
      question: userInput,
      error: errorMessage,
      loading: false,
    });
    ReactGA.event(
      {
        category: "Dialogue",
        action: "ask.error",
        label: "Error Occurred",
        nonInteraction: true,
      },
      { duration }
    );
  };

  const cancelQuestion = async () => {
    if (!loadingDialogue?.dialogue_id) return;
    await DefaultService.cancelAskApiAskDialogueIdCancelPut(
      loadingDialogue.dialogue_id
    );
    updateDialogueState(loadingDialogue.id, {
      question: loadingDialogue.question,
      loading: false,
      data: {
        ...loadingDialogue.data,
        answer_status: "cancelled",
      } as Dialogue,
    });

    ReactGA.event({
      category: "Dialogue",
      action: "ask.cancel",
      label: "Question Cancelled",
      nonInteraction: true,
    });
  };

  const clearDialogueSession = () => {
    setDialogues([]);
  };

  return {
    dialogues: dialogues.filter((x) => x.data?.answer_status !== "cancelled"),
    loadingDialogue,
    askQuestion,
    cancelQuestion,
    clearDialogueSession,
  };
};
