/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var UserRoleEnum;
(function (UserRoleEnum) {
    UserRoleEnum["ADMIN"] = "Admin";
    UserRoleEnum["MANAGER"] = "Manager";
    UserRoleEnum["ASSISTANT_MANAGER"] = "AssistantManager";
    UserRoleEnum["MAINTENANCE_SUPERVISOR"] = "MaintenanceSupervisor";
    UserRoleEnum["MAINTENANCE_TECH"] = "MaintenanceTech";
    UserRoleEnum["ONSITE_TEAM_MEMBER"] = "OnsiteTeamMember";
})(UserRoleEnum || (UserRoleEnum = {}));
