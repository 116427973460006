const API_ROOT = import.meta.env.DEV ? "http://localhost:8000" : "";
const LOGIN_MICROSOFT = `${API_ROOT}/api/login/microsoft`;
const LOGIN_GOOGLE = `${API_ROOT}/api/login/google`;

const NAVIGATION = {
  PRIORITIES: "priorities",
  APPLICATIONS: "applications",
  MOVE_INS: "move-ins",
  RENEWALS: "renewals",
  COLLECTIONS: "collections",
  MOVE_OUTS: "move-outs",
  SERVICE_REQUESTS: "service-requests",
  MAINTENANCE_REQUESTS: "maintenance-requests",
  MAKE_READIES: "make-readies",
  LEASE_AUDITS: "lease-audits",
};
export { API_ROOT, LOGIN_MICROSOFT, LOGIN_GOOGLE, NAVIGATION };
