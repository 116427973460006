interface ContentScriptReadyEvent {
  detail: {
    version?: string;
  };
}

/**
 * Interface for detecting the Surface extension.
 *
 * Handles initialization tracking of the extension content script.
 * These are required because the extension can initialize before
 * the web app is ready to receive the event and may need to retry
 * a few times before giving up.
 *
 * Custom events:
 * - content-script-ready
 *   - emitted by the extension content script after it has been initialized
 *   - will be retried a few times before giving up
 * - content-script-ready-received
 *   - web app acknowledges that the content script ready event has been received
 *   - signals to the extension that it can stop retrying
 */
class ExtensionManager {
  private static instance: ExtensionManager;

  private constructor() {
    window.surfaceExtension = {};
  }

  public static getInstance(): ExtensionManager {
    if (!ExtensionManager.instance) {
      ExtensionManager.instance = new ExtensionManager();
    }
    return ExtensionManager.instance;
  }

  public isInstalled(): boolean {
    return !!window.surfaceExtension?.version;
  }

  public getVersion(): string | undefined {
    return window.surfaceExtension?.version;
  }

  public setVersion(version?: string): void {
    window.surfaceExtension!.version = version;
  }

  public canInstall(): boolean {
    const userAgent = navigator.userAgent;
    return /Chrome|Chromium|Edg|Brave|OPR/.test(userAgent);
  }

  public async initialize(): Promise<void> {
    const waitForContentScriptReady = new Promise<string | undefined>(
      (resolve) => {
        const handleReady = (
          evt: CustomEvent<ContentScriptReadyEvent["detail"]>
        ) => {
          window.dispatchEvent(
            new CustomEvent("content-script-ready-received")
          );
          window.removeEventListener(
            "content-script-ready",
            handleReady as EventListener
          );
          resolve(evt.detail.version);
        };

        window.addEventListener(
          "content-script-ready",
          handleReady as EventListener
        );
      }
    );

    waitForContentScriptReady.then((version) => {
      console.log(
        `%cSurface extension detected: v${version}`,
        "color: rgb(85, 131, 255); font-weight: bold"
      );
      this.setVersion(version);
    });
  }
}

export const extensionManager = ExtensionManager.getInstance();
