import { createColumnHelper } from "@tanstack/react-table";
import { surface_core__ask__entitites__askentities__MoveIn as MoveIn } from "api-client";
import {
  TableDef,
  applicationStatusCell,
  optionalCell,
  optionalDateCell,
  personCell,
  unitCell,
} from "./utils";

const moveInColumnHelps = createColumnHelper<MoveIn>();

const moveInTableDefs: TableDef = {
  columns: [
    moveInColumnHelps.accessor("unit_number", {
      id: "unit_number",
      header: "Unit",
      cell: unitCell,
    }),
    moveInColumnHelps.accessor("move_in_date", {
      id: "move_in_date",
      header: "Move In Date",
      cell: optionalDateCell,
    }),
    moveInColumnHelps.accessor("lease_completion_status", {
      id: "lease_completion_status",
      header: "Lease Completion Status",
      cell: optionalCell,
    }),
    moveInColumnHelps.accessor("lease_start_date", {
      id: "lease_start_date",
      header: "Start Date",
      cell: optionalDateCell,
    }),
    moveInColumnHelps.accessor("resident_full_names", {
      id: "resident_full_names",
      header: "Residents",
      cell: personCell,
    }),
    moveInColumnHelps.accessor("application_id", {
      id: "application_id",
      header: "Application ID",
      cell: optionalCell,
    }),
    moveInColumnHelps.accessor("application_status", {
      id: "application_status",
      header: "Application Status",
      cell: applicationStatusCell,
    }),
    moveInColumnHelps.accessor("property_name", {
      id: "property_name",
      header: "Property",
      cell: optionalCell,
    }),
  ],
  staticColumns: ["unit_number", "move_in_date", "resident_full_names"],
  rowId: "unit_number",
};

export { moveInTableDefs };
