/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var OccupancyStatus;
(function (OccupancyStatus) {
    OccupancyStatus["VACANT"] = "Vacant";
    OccupancyStatus["OCCUPIED"] = "Occupied";
    OccupancyStatus["ON_NOTICE"] = "On Notice";
})(OccupancyStatus || (OccupancyStatus = {}));
