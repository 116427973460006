import { createColumnHelper } from "@tanstack/react-table";
import { Task } from "api-client";
import {
  TableDef,
  optionalCell,
  optionalDateCell,
  personCell,
  unitCell,
} from "./utils";

const renewalTaskColumnHelper = createColumnHelper<Task>();

const renewalTaskTableDefs: TableDef = {
  columns: [
    renewalTaskColumnHelper.accessor("unit_summary.unit_number", {
      id: "unit_number",
      header: "Unit",
      cell: unitCell,
    }),
    {
      id: "residents",
      header: "Residents",
      accessorFn: (row: Task) => {
        const primaryApplicant = row.residents?.[0];
        const other = row.residents?.slice(1);
        const primaryApplicantName = primaryApplicant?.full_name;
        if (!primaryApplicantName) {
          return "-";
        }
        const additionalApplicants =
          (other?.length || 0);
        return (
          primaryApplicantName +
          (additionalApplicants ? ` + ${additionalApplicants}` : "")
        );
      },
      cell: personCell,
    },
    renewalTaskColumnHelper.accessor("lease_summary.end_date", {
      id: "lease_end_date",
      header: "Lease End Date",
      cell: optionalDateCell
    }),
    renewalTaskColumnHelper.accessor("work_item_progress_summary", {
      id: "status",
      header: "Status",
      cell: optionalCell,
    }),
  ],
  staticColumns: ["unit_number", "residents", "lease_end_date", "status"],
  rowId: "id",
};

export { renewalTaskTableDefs };
