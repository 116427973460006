/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var LeaseCompletionStatus;
(function (LeaseCompletionStatus) {
    LeaseCompletionStatus["NOT_STARTED"] = "Not Started";
    LeaseCompletionStatus["STARTED"] = "Started";
    LeaseCompletionStatus["PARTIALLY_COMPLETED"] = "Partially Completed";
    LeaseCompletionStatus["COMPLETED"] = "Completed";
    LeaseCompletionStatus["CANCELLED"] = "Cancelled";
    LeaseCompletionStatus["APPROVED"] = "Approved";
    LeaseCompletionStatus["EDITED"] = "Edited";
    LeaseCompletionStatus["DECLINED"] = "Declined";
})(LeaseCompletionStatus || (LeaseCompletionStatus = {}));
