import { createColumnHelper } from "@tanstack/react-table";
import { Policy } from "api-client";
import { TableDef } from "./utils";
import { humanize } from "../../../lib/utils";

const PolicyColumnHelps = createColumnHelper<Policy>();

const policyTableDefs: TableDef = {
  columns: [
    PolicyColumnHelps.accessor("name", {
      id: "name",
      header: "Name",
      cell: (cell) => {
        const value: string = cell.getValue();
        return humanize(value);
      },
    }),
  ],
  staticColumns: ["name"],
  rowId: "name",
};

export { policyTableDefs };
