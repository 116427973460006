import React, { useState } from "react";
import clsx from "clsx";
import {
  flexRender,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { AnswerSource } from "api-client";
import { useTableDef } from "./use-table-def";
import { Button, Icon, Skeleton } from "surface-components";
import ReactGA from "react-ga4";

interface ISourceTableProps {
  answerSource: AnswerSource;
  onClickRow: (table: string, id: string) => void;
  isLoading?: boolean;
  selectedSourceId?: string | null;
  selectedRowId?: string | null;
  properties?: { id: string; name: string }[];
}

const paginationDescription = ({
  total,
  page,
  pageSize,
  itemsLength,
}: {
  total: number;
  page: number;
  pageSize: number;
  itemsLength: number;
}) => {
  /**
   * Getting the start value. Calculates the number of items before the current page
   * Examples:
   * Page 1: (1 - 1) * 10 + 1 = 1 (The first item on page 1 is item 1.)
   * Page 2: (2 - 1) * 10 + 1 = 11 (The first item on page 2 is item 11.)
   * Page 3: (3 - 1) * 10 + 1 = 21 (The first item on page 3 is item 21.)
   */
  const start = (page - 1) * pageSize + 1;

  /**
   *  End value represents the index of the last item being displayed on the current page.
   *  If start = 21 and tasksLength = 10, then end = 21 + 10 - 1 = 30. This means that the last item on the page is item 30.
   */
  const end = start + itemsLength - 1;

  // Don't overrun the total number of tasks
  const adjustedEnd = Math.min(end, total);

  return `Showing ${start}${adjustedEnd > start ? ` - ${adjustedEnd}` : ""} of ${total} items`;
};

const SourceTable: React.FC<ISourceTableProps> = ({
  answerSource,
  onClickRow,
  isLoading,
  selectedSourceId,
  selectedRowId,
  properties,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useTableDef(
    answerSource,
    onClickRow,
    sorting,
    setSorting,
    pagination,
    setPagination,
    properties || []
  );

  if (!table || !answerSource.data?.items?.length) {
    return null;
  }

  const getSortingIcon = (
    isSorted: false | "asc" | "desc",
    columnId: string
  ) => {
    // Don't show icons for the chevron column
    if (columnId === "actions") {
      return null;
    }

    if (isSorted === "asc") {
      return <Icon icon="sort-ascending" className="ml-2" />;
    } else if (isSorted === "desc") {
      return <Icon icon="sort-descending" className="ml-2" />;
    } else {
      return null;
    }
  };
  return (
    <div>
      <h4 className="font-foreground font-medium mb-4">{answerSource.name}</h4>
      <div>
        <table className="text-left border-separate border-spacing-0 w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={clsx(
                      "border-y p-4 text-muted-foreground font-md text-sm uppercase cursor-pointer select-none",
                      {
                        "text-left":
                          header.index < headerGroup.headers.length - 1 &&
                          header.index <= 2,
                        "text-right":
                          header.index === headerGroup.headers.length - 1 ||
                          header.index > 2,
                        "border-r":
                          header.index === headerGroup.headers.length - 1,
                        "border-l": header.index === 0,
                        "rounded-tl": header.index === 0,
                        "rounded-tr":
                          header.index === headerGroup.headers.length - 1,
                      }
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                    title={
                      header.column.getCanSort()
                        ? header.column.getIsSorted()
                          ? header.column.getIsSorted() === "asc"
                            ? "Sort descending"
                            : "Clear sort"
                          : "Sort ascending"
                        : undefined
                    }
                  >
                    {header.isPlaceholder ? null : (
                      <span
                        className={clsx("flex items-center group", {
                          "justify-end":
                            header.index === headerGroup.headers.length - 1 ||
                            header.index > 2,
                        })}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {getSortingIcon(
                          header.column.getIsSorted(),
                          header.column.id
                        )}
                      </span>
                    )}
                  </th>
                ))}
                <th />
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.original.id + row.original.property_id}
                onClick={() => {
                  ReactGA.event(
                    {
                      category: "SourceTable",
                      action: "click.sourceRow",
                      label: "Source Row",
                      nonInteraction: false,
                    },
                    {
                      rowType: row.original.type,
                      propertyId: row.original.property_id,
                      unitNumber: row.original.unit_number || "",
                      answerId: answerSource.id,
                    }
                  );
                  onClickRow(answerSource.id, row.id);
                }}
                className={clsx(
                  "cursor-pointer hover:bg-accent hover:text-accent-foreground",
                  {
                    "bg-accent":
                      selectedSourceId === answerSource.id &&
                      selectedRowId === row.id,
                  }
                )}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={clsx("border-b p-4", {
                        "text-left":
                          cell.column.id !== "actions" &&
                          cell.column.getIndex() <= 2,
                        "text-right":
                          cell.column.id === "actions" ||
                          cell.column.getIndex() > 2,
                        "border-l": cell.column.getIndex() === 0,
                        "border-r":
                          cell.column.getIndex() ===
                          table.getAllColumns().length - 1,
                        "rounded-bl": row.index === table.getRowCount() - 1,
                        "rounded-br":
                          row.index === table.getRowCount() - 1 &&
                          cell.column.getIndex() ===
                            table.getAllColumns().length - 1,
                      })}
                    >
                      <div
                        className={clsx("flex items-center", {
                          "justify-end":
                            cell.column.id === "actions" ||
                            cell.column.getIndex() > 2,
                        })}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex items-center justify-between border-x border-b rounded-bl rounded-br px-3 text-muted-foreground text-sm w-100 bg-accent h-10 mr-1">
          <div>
            {isLoading ? (
              <div className="flex items-center space-x-1">
                <Skeleton className="w-20 h-1" />
                <Skeleton className="w-12 h-1" />
              </div>
            ) : (
              paginationDescription({
                total: answerSource.data.items?.length || 0,
                page: table.getState().pagination.pageIndex + 1,
                pageSize: table.getState().pagination.pageSize,
                itemsLength: table.getRowModel().rows.length,
              })
            )}
          </div>
          <div className="flex space-x-1">
            <Button
              className="px-1"
              variant="ghost"
              aria-label="previous"
              disabled={
                isLoading || table.getState().pagination.pageIndex === 0
              }
              onClick={() => {
                setPagination({
                  ...table.getState().pagination,
                  pageIndex: table.getState().pagination.pageIndex - 1,
                });
              }}
            >
              <Icon className="rotate-180" icon="chevron-right" />
            </Button>
            <Button
              className="px-1"
              variant="ghost"
              aria-label="next"
              disabled={
                isLoading ||
                table.getState().pagination.pageIndex + 1 >
                  Math.floor(
                    answerSource.data.items?.length /
                      table.getState().pagination.pageSize
                  )
              }
              onClick={() => {
                setPagination({
                  ...table.getState().pagination,
                  pageIndex: table.getState().pagination.pageIndex + 1,
                });
              }}
            >
              <Icon icon="chevron-right" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SourceTable };
