import React, { useEffect, useRef } from "react";
import {
  AnswerSource,
  Dialogue as ApiDialogue,
  DialogueFeedback,
} from "api-client";
import { AnswerCardFooter } from "./answer-card-footer";
import {
  InlineSourceListUI,
  QuerySuggestionCta,
  CardWithTitle,
  AnswerFormatter,
  AnimatedAnswer,
} from "surface-components";
import { useAskAndPoll } from "@/lib/hooks/use-ask-and-poll";

export enum LoadStatus {
  Loading,
  Answered,
  Error,
}

type AnswerCardProps = {
  dialogueId?: string | null;
  title: string;
  answerText?: string;
  loadedSources: AnswerSource[];
  status: LoadStatus;
  questionProperties: string[];
  feedback?: DialogueFeedback | null;
  suggestions?: ApiDialogue["prompt_autosuggest"];
  answeredDate?: Date;
  wrapperClassName?: string;
  answerChunks?: ApiDialogue["answer_stream_chunk"][];
};

const AnswerCard = React.forwardRef<HTMLDivElement, AnswerCardProps>(
  (
    {
      title,
      answerText,
      loadedSources,
      status,
      questionProperties,
      dialogueId,
      feedback,
      suggestions,
      answeredDate,
      wrapperClassName,
      answerChunks,
    },
    ref
  ) => {
    const { askQuestion } = useAskAndPoll();
    const endOfContentRef = useRef<HTMLSpanElement>(null);

    // Scroll to bottom whenever new chunks or answerText are updated
    useEffect(() => {
      if (endOfContentRef.current) {
        endOfContentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [answerChunks, answerText]);

    const handleQuerySuggestionCtaPressed = (query: string) => {
      askQuestion(query);
    };

    const showAnsweredSources =
      status === LoadStatus.Answered && loadedSources?.length > 0;
    const shouldShowSuggestions = suggestions && suggestions.length > 0;
    const showAnswerTable = loadedSources?.length === 1 && dialogueId;

    const getAnimatedAnswerStatus = () => {
      if (status === LoadStatus.Loading && loadedSources?.length === 0)
        return "loading";
      if (
        status === LoadStatus.Loading &&
        answerChunks &&
        answerChunks.length > 0
      )
        return "answering";
      if (status === LoadStatus.Answered) return "complete";
      return "initial";
    };

    return (
      <div ref={ref} className={wrapperClassName}>
        <CardWithTitle title={title} isLoading={!answerText}>
          {answerText ? (
            <AnswerFormatter answerText={answerText} />
          ) : (
            <>
              <AnimatedAnswer
                answerChunks={answerChunks ?? []}
                status={getAnimatedAnswerStatus()}
              />
              <span
                ref={endOfContentRef}
                className="invisible h-0 scroll-mb-8"
              />
            </>
          )}

          {shouldShowSuggestions && (
            <>
              <p className="text-gray-900 text-base font-normal font-sans leading-normal">
                Here are some suggestions for you to consider:
              </p>
              <div>
                {suggestions.map(({ suggestion }, index) => (
                  // NOTE: It's typically an anti-pattern to use index as a key, but in small lists without re-ordering it is acceptable
                  <QuerySuggestionCta
                    key={index}
                    query={suggestion}
                    onQuerySuggestionClick={handleQuerySuggestionCtaPressed}
                  />
                ))}
              </div>
            </>
          )}

          {showAnswerTable && (
            <InlineSourceListUI
              isLoading={status === LoadStatus.Loading}
              source={loadedSources[0]}
              dialogueId={dialogueId}
              baseUrl=""
            />
          )}

          {showAnsweredSources && (
            <AnswerCardFooter
              dialogueId={dialogueId}
              questionProperties={questionProperties}
              feedback={feedback}
              answeredDate={answeredDate}
              title={title}
            />
          )}
          {/* This portal root is for the RateBar feedback form */}
          <div id={`portal-root-${dialogueId}`} />
        </CardWithTitle>
      </div>
    );
  }
);

AnswerCard.displayName = "AnswerCard"; // Manually assign a display name

export default AnswerCard;
