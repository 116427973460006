import { surface_core__ask__entitites__askentities__Application as Application } from "api-client";
import {
  DateDetail,
  PersonsDetail,
  Detail,
  UnitDetail,
  LeasedItemsDetail,
  PetsDetail,
  VehiclesDetail,
  LeaseCompletionStatusDetail,
  ApplicationStatusDetail,
  ScreeningStatusDetail,
} from "../ui/drawer-details";

interface ApplicationDetailsProps {
  item: Application;
}

const ApplicationDetails = ({ item }: ApplicationDetailsProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <ApplicationStatusDetail label="Status" value={item.application_status} />
      <hr />
      <UnitDetail
        label="Unit"
        value={{
          unit_number: item.unit_number || "",
          square_feet: item.square_footage,
          num_bedrooms: item.number_of_bedrooms,
          num_bathrooms: item.number_of_bathrooms,
          floor_plan: item.floor_plan_name,
        }}
      />
      <hr />
      <PersonsDetail
        label="Residents"
        value={[
          {
            name: item.applicant_full_name,
          },
          ...(item.co_applicants || [])
            .concat(item.other_occupants || [])
            .map((x) => {
              return {
                name: x.full_name,
                is_minor: x.is_minor,
              };
            }),
        ]}
      />
      <hr />
      <DateDetail label="Move-in date" value={item.target_move_in_date} />
      <hr />
      <PersonsDetail label="Leasing agent" value={item.leasing_agent_name} />
      <hr />
      <PetsDetail label="Pets" value={item.pets} />
      <hr />
      <VehiclesDetail label="Vehicles" value={item.vehicles} />
      <hr />
      <LeasedItemsDetail
        label="Additional leased items"
        value={item.additional_leased_items}
      />
      <hr />
      <LeaseCompletionStatusDetail
        label="Lease Completion status"
        value={item.lease_completion_status}
      />
      <hr />
      <DateDetail
        label="Application Completed Date"
        value={item.application_completed_date}
      />
      <hr />
      <ScreeningStatusDetail
        label="Screening"
        value={item.screening_details?.status}
      />
      <hr />
      <DateDetail
        label="Application Started Date"
        value={item.application_started_date}
      />
      <hr />
      <DateDetail
        label="Guest Card Created Date"
        value={item.guest_card_created_date}
      />
      <hr />
      <Detail label="Application ID" value={item.application_id} />
    </div>
  );
};

export { ApplicationDetails };
