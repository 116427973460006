/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ApplicationType;
(function (ApplicationType) {
    ApplicationType["APPLICATION"] = "Application";
    ApplicationType["LEASE_TRANSFER"] = "Lease Transfer";
    ApplicationType["RENEWAL"] = "Renewal";
})(ApplicationType || (ApplicationType = {}));
