import { Button, Icon } from "surface-components";
import { MiddleDot } from "../ui/middle-dot";
import { LeaseAudit, Renewal, Task, WorkOrder } from "api-client";
// Import the types
import {
  surface_core__api__entities__Application,
  surface_core__api__entities__MoveIn,
  surface_core__api__entities__MoveOut,
} from "api-client";
import { ApplicationDrawerContent } from "./application/application";
import { MoveInDrawerContent } from "./move-in";
import { MoveOutDrawerContent } from "./move-out";
import { RenwalDrawerContent } from "./renewal";
import { ServiceRequestDrawerContent } from "./service-request/service-reqeust";
import { LeaseAuditDrawerContent } from "./lease-audit/lease-audit";

const getDrawerContent = (
  task?: Partial<Task>,
  isLoading?: boolean,
  onClickResident?: (
    propertyId: string,
    residentId: string,
    name?: string | null
  ) => void
): React.ReactNode => {
  if (task?.type === "ApplicationTask") {
    return (
      <ApplicationDrawerContent
        task={task}
        item={task?.entity as surface_core__api__entities__Application}
        isLoading={isLoading}
        onClickResident={onClickResident}
      />
    );
  } else if (task?.type === "MoveInTask") {
    return (
      <MoveInDrawerContent
        task={task}
        item={task?.entity as surface_core__api__entities__MoveIn}
        isLoading={isLoading}
        onClickResident={onClickResident}
      />
    );
  } else if (task?.type === "MoveOutTask") {
    return (
      <MoveOutDrawerContent
        task={task}
        item={task?.entity as surface_core__api__entities__MoveOut}
        isLoading={isLoading}
        onClickResident={onClickResident}
      />
    );
  } else if (task?.type === "RenewalTask") {
    return (
      <RenwalDrawerContent
        task={task}
        item={task?.entity as Renewal}
        isLoading={isLoading}
        onClickResident={onClickResident}
      />
    );
  } else if (task?.type === "ServiceRequestTask") {
    return (
      <ServiceRequestDrawerContent
        task={task}
        item={task?.entity as WorkOrder}
        isLoading={isLoading}
        onClickResident={onClickResident}
      />
    );
  } else if (task?.type === "LeaseAuditTask") {
    return (
      <LeaseAuditDrawerContent
        task={task}
        item={task?.entity as LeaseAudit}
        isLoading={isLoading}
      />
    );
  }
  return null;
};

const getDrawerTitle = (item?: Partial<Task>) => {
  if (item) {
    return item.type
      ?.replace("Task", "")
      .split(/(?=[A-Z])/)
      .join(" ");
  }
  return "";
};
const getEntityName = (task?: Partial<Task>) => {
  return task?.unit_summary?.unit_number || task?.id || "";
};

interface TaskDetailsDrawerProps {
  task?: Partial<Task>;
  isLoading?: boolean;
  onClose: () => void;
  onClickResident?: (
    propertyId: string,
    residentId: string,
    name?: string | null
  ) => void;
}

export const TaskDetailsDrawer = ({
  onClose,
  task,
  isLoading,
  onClickResident,
}: TaskDetailsDrawerProps) => {
  const itemName = getEntityName(task);
  return (
    <div className="bg-white flex-1 h-full">
      <div className="text-xl flex justify-between items-center text-muted-foreground px-6 h-[63px] border-b">
        <div className="flex items-center space-x-2">
          <Button variant="outline" className="p-1 h-auto" onClick={onClose}>
            <Icon icon="close" title="close" className="p-1" />
          </Button>
          <span className="text-foreground font-medium">
            {getDrawerTitle(task)}
          </span>
          {itemName && (
            <>
              <MiddleDot />
              <span>{itemName}</span>
            </>
          )}
        </div>
        <div>
          <Button
            onClick={() => {
              window.print();
            }}
            className="flex space-x-1"
            variant="outline"
          >
            <Icon icon="print" />
            <p>Print</p>
          </Button>
        </div>
      </div>
      {getDrawerContent(task, isLoading, onClickResident)}
    </div>
  );
};
