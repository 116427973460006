import { surface_core__ask__entitites__askentities__MoveOut as MoveOut } from "api-client";
import { DateDetail, PersonsDetail, Detail } from "../ui/drawer-details";

interface MoveOutDetailsProps {
  item: MoveOut;
}

const MoveOutDetails = ({ item }: MoveOutDetailsProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <Detail label="Property" value={item.property_name} />
      <hr />
      <DateDetail label="Move-Out Date" value={item.move_out_date} />
      <hr />
      <Detail label="Move-Out Reason" value={item.move_out_reason} />
      <hr />
      <PersonsDetail
        label="Residents"
        value={item.resident_full_names?.map((name) => ({
          name,
        }))}
      />
      <hr />
      <DateDetail label="Lease Start Date" value={item.lease_start_date} />
      <hr />
      <DateDetail label="Lease End Date" value={item.lease_end_date} />
    </div>
  );
};

export { MoveOutDetails };
