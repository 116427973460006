import { Outlet, useSearchParams } from "react-router-dom";
import { useFeatureFlag } from "@/lib/hooks/use-feature-flag";
import { useAuth } from "../lib/auth";
import { SavedAsks } from "@/components/ask/saved-asks/saved-asks";
import { StatefulNavButton } from "@/components/ui/nav-button";
import { useManageSavedDialogues } from "@/lib/hooks/use-manage-saved-dialogues";
import { SaasTenantSelect } from "@/components/ui/saas-tenant-select";
import { AskAnythingIcon } from "@/components/ui/icons/ask-anything";
import { PrioritiesIcon } from "@/components/ui/icons/priorities";
import { ApplicationsIcon } from "@/components/ui/icons/applications";
import { MoveInsIcon } from "@/components/ui/icons/move-ins";
import { TableIcon } from "@/components/ui/icons/table";
// import { CollectionsIcon } from "@/components/ui/icons/collections";
import { RenewalsIcon } from "@/components/ui/icons/renewals";
import { MoveOutsIcon } from "@/components/ui/icons/move-outs";
import { LeaseAuditsIcon } from "@/components/ui/icons/lease-audits";
import { Icon } from "surface-components";
import { NAVIGATION } from "@/constants";

const AppPage = () => {
  const { value: leaseAuditsEnabled } = useFeatureFlag(
    "leaseAuditsEnabled",
    false
  );
  const { setNoActiveDialogueState } = useManageSavedDialogues();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();

  return (
    <div className="flex w-full bg-muted">
      <div className="w-[300px] border-r">
        <div className="p-4 border-b text-xl text-foreground h-[64px] flex items-center">
          <div className="flex gap-2 items-center ml-4">
            <Icon icon="logo-icon-blue" className="w-6" />
            {user && <SaasTenantSelect user={user} />}
          </div>
        </div>
        <div className="flex flex-col p-4 space-y-1">
          <StatefulNavButton
            shimmer
            onClick={setNoActiveDialogueState}
            to="/ask"
            end
            search={`?${searchParams
              .getAll("property_id")
              .map((id) => `property_id=${id}`)
              .join("&")}`}
            Icon={AskAnythingIcon}
          >
            Ask Anything
          </StatefulNavButton>
          <StatefulNavButton
            to={`/${NAVIGATION.PRIORITIES}`}
            end
            Icon={PrioritiesIcon}
            search={`?${searchParams.toString()}`}
          >
            Priorities
          </StatefulNavButton>
          <StatefulNavButton
            to={`/${NAVIGATION.PRIORITIES}/${NAVIGATION.APPLICATIONS}`}
            search={`?${searchParams.toString()}`}
            Icon={ApplicationsIcon}
          >
            Applications
          </StatefulNavButton>
          <StatefulNavButton
            to={`/${NAVIGATION.PRIORITIES}/${NAVIGATION.MOVE_INS}`}
            Icon={MoveInsIcon}
            search={`?${searchParams.toString()}`}
          >
            Move ins
          </StatefulNavButton>
          <StatefulNavButton
            to={`/${NAVIGATION.PRIORITIES}/${NAVIGATION.RENEWALS}`}
            Icon={RenewalsIcon}
            search={`?${searchParams.toString()}`}
          >
            Renewals
          </StatefulNavButton>
          {/* <StatefulNavButton
              to={`/${NAVIGATION.PRIORITIES}/${NAVIGATION.COLLECTIONS}`}
              Icon={CollectionsIcon}
              search={`?${searchParams.toString()}`}
            >
              Collections
            </StatefulNavButton> */}
          <StatefulNavButton
            to={`/${NAVIGATION.PRIORITIES}/${NAVIGATION.MOVE_OUTS}`}
            Icon={MoveOutsIcon}
            search={`?${searchParams.toString()}`}
          >
            Move outs
          </StatefulNavButton>
          <StatefulNavButton
            to={`/${NAVIGATION.PRIORITIES}/${NAVIGATION.SERVICE_REQUESTS}`}
            Icon={TableIcon}
            search={`?${searchParams.toString()}`}
          >
            Service requests
          </StatefulNavButton>
          {leaseAuditsEnabled && (
            <StatefulNavButton
              to={`/${NAVIGATION.PRIORITIES}/${NAVIGATION.LEASE_AUDITS}`}
              Icon={LeaseAuditsIcon}
              search={`?${searchParams.toString()}`}
            >
              Lease Audits
            </StatefulNavButton>
          )}
        </div>
        <div className="bg-slate-200 h-[1px] ml-2" />
        <div className="flex flex-col py-4 px-2">
          <SavedAsks />
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export { AppPage };
