const dimension = (size: string) => (size === "large" ? "40" : "24");

type CircleSurfaceIconProps = {
  size: string;
  color: string;
};

export const CircleSurfaceIcon = ({
  size,
  color,
}: CircleSurfaceIconProps): JSX.Element => {
  return (
    <div
      className={`relative w-[${dimension(size)}px] h-[${dimension(size)}px]`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={dimension(size)}
        height={dimension(size)}
        viewBox="0 0 40 40"
        fill={color}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.78648 20C5.78648 14.3767 10.3072 9.81818 15.8838 9.81818C21.4603 9.81818 25.981 14.3767 25.981 20C25.981 25.6233 21.4603 30.1818 15.8838 30.1818C10.3072 30.1818 5.78648 25.6233 5.78648 20ZM15.8838 6C8.21597 6 2 12.268 2 20C2 27.732 8.21597 34 15.8838 34C23.5516 34 29.7675 27.732 29.7675 20C29.7675 12.268 23.5516 6 15.8838 6ZM33.7644 20.0002C33.7644 23.7781 32.6121 27.2847 30.6426 30.182H35.0592C36.6502 27.1416 37.5509 23.6769 37.5509 20.0002C37.5509 16.3234 36.6502 12.8588 35.0592 9.81836H30.6426C32.6121 12.7157 33.7644 16.2222 33.7644 20.0002Z"
          fill="#F0F2F4"
        />
      </svg>
    </div>
  );
};
