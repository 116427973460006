import { LeaseAudit, Task } from "api-client";
import { LeaseAuditDocuments } from "./lease-audit-documents";
import { LeaseAuditSummary } from "./lease-audit-summary";
import { LeaseAuditCharges } from "./lease-audit-charges";
import { UnitDetail, PersonsDetail } from "../../ui/drawer-details";

export const LeaseAuditDrawerContent = ({
  item,
  task,
  isLoading,
}: {
  task?: Partial<Task>;
  item?: LeaseAudit;
  isLoading?: boolean;
}) => {
  return (
    <div className="flex flex-col">
      <LeaseAuditSummary item={item} />
      <div className="flex flex-col space-y-4 py-4 px-6">
        <LeaseAuditDocuments
          className="mb-4"
          label="Checked Against"
          value={item?.audit_checks}
          isLoading={isLoading}
        />
        <LeaseAuditCharges checks={item?.audit_checks} />
        <hr />
        <UnitDetail
          label="Unit"
          value={task?.unit_summary}
          isLoading={isLoading}
        />
        <hr />
        <PersonsDetail
          label="Residents"
          isLoading={isLoading}
          value={task?.residents?.map((x) => {
            return {
              name: x.full_name,
              phone_number: x.phone_number,
              email_address: x.email_address,
            };
          })}
        />
      </div>
    </div>
  );
};
