/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var TaskSortParam;
(function (TaskSortParam) {
    TaskSortParam["PRIORITY"] = "priority";
    TaskSortParam["_PRIORITY"] = "-priority";
    TaskSortParam["COMPLETED_DATE"] = "completed_date";
    TaskSortParam["_COMPLETED_DATE"] = "-completed_date";
    TaskSortParam["TASK_TYPE"] = "task_type";
    TaskSortParam["_TASK_TYPE"] = "-task_type";
    TaskSortParam["PROPERTY_NAME"] = "property_name";
    TaskSortParam["_PROPERTY_NAME"] = "-property_name";
})(TaskSortParam || (TaskSortParam = {}));
